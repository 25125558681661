import React from "react";
import { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';




import uai1_1 from "../../resources/IngenieriaBio.jpeg"

import { useTranslation } from "react-i18next";
import i18next from "i18next";

    

const Minors = () =>{
    
    const [t, i18n]= useTranslation("global");
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(true);
   
  





    
    return(
        
        <>

        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className="col-6 offset-3">        
                    <div className="card position-relative ms-4 me-4">
                        <img src={uai1_1}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("pregrado.minors.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
    
            <div className="p-3"/>
            <div className="col-6 offset-3">
                <p className="text-start fs-6">{t("pregrado.minors.t2")}</p>
                <p className="text-start fs-6">{t("pregrado.minors.t3")}</p>
            </div>

            <div className="d-none d-xl-block container"> 
                <div className="p-4 position-relative text-center row">
                   
                    <Button
                        onClick={() => {
                            setIsOpen2(false);
                            setIsOpen3(false);
                            setIsOpen4(false);
                            setIsOpen(!isOpen);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t4")}
                    
                        
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            setIsOpen3(false);
                            setIsOpen4(false);
                            setIsOpen2(!isOpen2);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen2}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t5")}
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            setIsOpen2(false);
                            setIsOpen4(false);
                            setIsOpen3(!isOpen3);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen3}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t6")}
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            setIsOpen2(false);
                            setIsOpen3(false);
                            setIsOpen4(!isOpen4);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen4}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t7")}
                    </Button>


                </div>
            </div>



            <Collapse in={isOpen}>
                <div className="col-8 offset-2">
                    <p ><strong>{t("pregrado.minors.t8")}</strong></p>
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("pregrado.minors.t9")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <th scope="row">{t("pregrado.minors.minor1")}</th>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion5")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion6")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion7")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion8")}</td>

                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Bioingeniería2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor3")}</th>
                                <td>{t("pregrado.minors.Energía2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Energía2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor4")}</th>
                                <td>{t("pregrado.minors.Minería2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Minería2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Obras_Civiles2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor6")}</th>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion6")}</td>

                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Ciencia_de_Datos2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Gerencia_TI2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor9")}</th>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Matemáticas2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor10")}</th>
                                <td>{t("pregrado.minors.Física2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Física2019.opcion6")}</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen2}>
                <div className="col-8 offset-2">
                    <p ><strong>{t("pregrado.minors.t11")}</strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("pregrado.minors.t9")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <th scope="row">{t("pregrado.minors.minor1")}</th>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion5")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion6")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion7")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor2")}</th>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Bioingeniería2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor4")}</th>
                            <td>{t("pregrado.minors.Minería2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Minería2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor5")}</th>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Obras_Civiles2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor6")}</th>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor7")}</th>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Ciencias_de_Datos2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor8")}</th>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Gerencia_TI2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor3")}</th>
                            <td>{t("pregrado.minors.Energía2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Energía2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor10")}</th>
                            <td>{t("pregrado.minors.Física2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Física2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor9")}</th>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion4")}</td>
                            <td colspan="3">{t("pregrado.minors.Matemáticas2020.opcion5")}</td>
                        </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen3}>
                <div className="col-8 offset-2">
                    <p ><strong><p><strong>{t("pregrado.minors.t11")}</strong></p></strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                                <th scope="col">{t("pregrado.minors.t9")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor12")}</th>
                                <td>{t("pregrado.minors.Diseño2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Bioingeniería2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor3")}</th>
                                <td>{t("pregrado.minors.Energía2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Energía2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor1")}</th>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Medioambiente2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor4")}</th>
                                <td>{t("pregrado.minors.Minería2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Minería2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Obras_Civiles2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor11")}</th>
                                <td>{t("pregrado.minors.Mecánica2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Mecánica2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor9")}</th>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Matemáticas2023.opcion6")}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen4}>
                <div className="col-8 offset-2">
                    <p ><strong><p><strong>{t("pregrado.minors.t11")}</strong></p></strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                        <tr>
                                <th scope="col">{t("pregrado.minors.t9")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion5")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion6")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion5")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion6")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Bioingeniería2023Viña.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Obras_Civiles2023Viña.opcion6")}</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            
                


            

        </div>
        
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
                <div className="">        
                            <div className="card position-relative">
                                <img src={uai1_1}className="card-img"/>
                                <div className="card-img-overlay position-absolute custom-co-image">
                                    <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("pregrado.minors.t1")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                                </div>
                            </div>
                    </div>
                    <div className="p-3"/>
                <div className="col-md-6 offset-md-3">
                    <p className="text-start fs-6">{t("pregrado.minors.t2")}</p>
                    <p className="text-start fs-6">{t("pregrado.minors.t3")}</p>
                </div>
                <div className="d-none d-md-block container"> 
                    <div className="p-4 position-relative text-center row">
                    <Button
                        onClick={() => {
                            setIsOpen2(false);
                            setIsOpen3(false);
                            setIsOpen4(false);
                            setIsOpen(!isOpen);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t4")}
                    
                        
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            setIsOpen3(false);
                            setIsOpen4(false);
                            setIsOpen2(!isOpen2);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen2}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t5")}
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            setIsOpen2(false);
                            setIsOpen4(false);
                            setIsOpen3(!isOpen3);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen3}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t6")}
                    </Button>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            setIsOpen2(false);
                            setIsOpen3(false);
                            setIsOpen4(!isOpen4);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={isOpen4}
                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t7")}
                    </Button>

                        
                    </div>
                    <div>
                        
           
            <Collapse in={isOpen}>
                <div className="col-8 offset-2 table-responsive-xl">
                    <p ><strong>{t("pregrado.minors.t8")}</strong></p>
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("pregrado.minors.t9")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <th scope="row">{t("pregrado.minors.minor1")}</th>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion5")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion6")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion7")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion8")}</td>

                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Bioingeniería2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor3")}</th>
                                <td>{t("pregrado.minors.Energía2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Energía2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor4")}</th>
                                <td>{t("pregrado.minors.Minería2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Minería2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Obras_Civiles2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor6")}</th>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion6")}</td>

                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Ciencia_de_Datos2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Gerencia_TI2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor9")}</th>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Matemáticas2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor10")}</th>
                                <td>{t("pregrado.minors.Física2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Física2019.opcion6")}</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen2}>
                <div className="col-8 offset-2 table-responsive-xl">
                    <p ><strong>{t("pregrado.minors.t11")}</strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("pregrado.minors.t9")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <th scope="row">{t("pregrado.minors.minor1")}</th>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion5")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion6")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion7")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor2")}</th>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Bioingeniería2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor4")}</th>
                            <td>{t("pregrado.minors.Minería2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Minería2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor5")}</th>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Obras_Civiles2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor6")}</th>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor7")}</th>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Ciencias_de_Datos2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor8")}</th>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Gerencia_TI2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor3")}</th>
                            <td>{t("pregrado.minors.Energía2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Energía2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor10")}</th>
                            <td>{t("pregrado.minors.Física2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Física2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor9")}</th>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion4")}</td>
                            <td colspan="3">{t("pregrado.minors.Matemáticas2020.opcion5")}</td>
                        </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen3}>
                <div className="col-8 offset-2 table-responsive-xl">
                    <p ><strong><p><strong>{t("pregrado.minors.t11")}</strong></p></strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                                <th scope="col">{t("pregrado.minors.t9")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor12")}</th>
                                <td>{t("pregrado.minors.Diseño2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Bioingeniería2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor3")}</th>
                                <td>{t("pregrado.minors.Energía2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Energía2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor1")}</th>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Medioambiente2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor4")}</th>
                                <td>{t("pregrado.minors.Minería2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Minería2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Obras_Civiles2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor11")}</th>
                                <td>{t("pregrado.minors.Mecánica2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Mecánica2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor9")}</th>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Matemáticas2023.opcion6")}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen4}>
                <div className="col-8 offset-2 table-responsive-xl" >
                    <p ><strong><p><strong>{t("pregrado.minors.t11")}</strong></p></strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                        <tr>
                                <th scope="col">{t("pregrado.minors.t9")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion5")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion6")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion5")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion6")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Bioingeniería2023Viña.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Obras_Civiles2023Viña.opcion6")}</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            
            
                    </div>
                </div>
                    
                
            </div>
                {/*}Renderizado desde pantallas md{*/}



    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={uai1_1} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("pregrado.minors.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("pregrado.minors.t2")}</p>
                                <p className="text-start fs-6">{t("pregrado.minors.t3")}</p>
                            </div>
                            <div className="bg-grey">
                    
                            <div className="p-4 container"/>
                                <div className="col-md-6 offset-md-3 row">
                                    <Button
                                        onClick={() => {
                                            setIsOpen2(false);
                                            setIsOpen3(false);
                                            setIsOpen4(false);
                                            setIsOpen(!isOpen);
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={isOpen}
                                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t4")}
                                    
                                        
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setIsOpen(false);
                                            setIsOpen3(false);
                                            setIsOpen4(false);
                                            setIsOpen2(!isOpen2);
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={isOpen2}
                                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t5")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setIsOpen(false);
                                            setIsOpen2(false);
                                            setIsOpen4(false);
                                            setIsOpen3(!isOpen3);
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={isOpen3}
                                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t6")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setIsOpen(false);
                                            setIsOpen2(false);
                                            setIsOpen3(false);
                                            setIsOpen4(!isOpen4);
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={isOpen4}
                                        className="text-light bg-uai col-sm mx-4 border border-uai" >{t("pregrado.minors.t7")}
                                    </Button>




                                </div>
                            </div>
                            <div className="">
                            <Collapse in={isOpen}>
                <div className="col-8 offset-2 table-responsive-xl">
                    <p ><strong>{t("pregrado.minors.t8")}</strong></p>
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("pregrado.minors.t9")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <th scope="row">{t("pregrado.minors.minor1")}</th>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion5")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion6")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion7")}</td>
                                <td>{t("pregrado.minors.Medioambiente2019.opcion8")}</td>

                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Bioingeniería2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor3")}</th>
                                <td>{t("pregrado.minors.Energía2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Energía2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Energía2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor4")}</th>
                                <td>{t("pregrado.minors.Minería2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Minería2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Minería2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Obras_Civiles2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor6")}</th>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2019.opcion6")}</td>

                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Ciencia_de_Datos2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Gerencia_TI2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor9")}</th>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Matemáticas2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Matemáticas2019.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor10")}</th>
                                <td>{t("pregrado.minors.Física2019.opcion1")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion2")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion3")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion4")}</td>
                                <td>{t("pregrado.minors.Física2019.opcion5")}</td>
                                <td colspan="3">{t("pregrado.minors.Física2019.opcion6")}</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen2}>
                <div className="col-8 offset-2 table-responsive-xl">
                    <p ><strong>{t("pregrado.minors.t11")}</strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("pregrado.minors.t9")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>
                            <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <th scope="row">{t("pregrado.minors.minor1")}</th>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion5")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion6")}</td>
                            <td>{t("pregrado.minors.Medioambiente2020.opcion7")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor2")}</th>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Bioingeniería2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Bioingeniería2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor4")}</th>
                            <td>{t("pregrado.minors.Minería2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Minería2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Minería2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor5")}</th>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Obras_Civiles2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Obras_Civiles2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor6")}</th>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Diseño_Mecánico_y_Manufactura2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor7")}</th>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Ciencias_de_Datos2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Ciencias_de_Datos2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor8")}</th>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Gerencia_TI2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Gerencia_TI2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor3")}</th>
                            <td>{t("pregrado.minors.Energía2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Energía2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Energía2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor10")}</th>
                            <td>{t("pregrado.minors.Física2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion4")}</td>
                            <td>{t("pregrado.minors.Física2020.opcion5")}</td>
                            <td colspan="2">{t("pregrado.minors.Física2020.opcion6")}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("pregrado.minors.minor9")}</th>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion1")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion2")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion3")}</td>
                            <td>{t("pregrado.minors.Matemáticas2020.opcion4")}</td>
                            <td colspan="3">{t("pregrado.minors.Matemáticas2020.opcion5")}</td>
                        </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen3}>
                <div className="col-8 offset-2 table-responsive-xl">
                    <p ><strong><p><strong>{t("pregrado.minors.t11")}</strong></p></strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                                <th scope="col">{t("pregrado.minors.t9")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor12")}</th>
                                <td>{t("pregrado.minors.Diseño2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Diseño2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion5")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion6")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Bioingeniería2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor3")}</th>
                                <td>{t("pregrado.minors.Energía2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Energía2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Energía2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor1")}</th>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Medioambiente2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Medioambiente2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor4")}</th>
                                <td>{t("pregrado.minors.Minería2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Minería2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Minería2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Obras_Civiles2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor11")}</th>
                                <td>{t("pregrado.minors.Mecánica2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Mecánica2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Mecánica2023.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor9")}</th>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion1")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion2")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion3")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion4")}</td>
                                <td>{t("pregrado.minors.Matemáticas2023.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Matemáticas2023.opcion6")}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </Collapse>
            <Collapse in={isOpen4}>
                <div className="col-8 offset-2 table-responsive-xl" >
                    <p ><strong><p><strong>{t("pregrado.minors.t11")}</strong></p></strong></p>

                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                        <tr>
                                <th scope="col">{t("pregrado.minors.t9")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>
                                <th scope="col">{t("pregrado.minors.t10")}</th>

                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor8")}</th>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion5")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion6")}</td>
                                <td>{t("pregrado.minors.Gerencia_TI2023Viña.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor7")}</th>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion5")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion6")}</td>
                                <td>{t("pregrado.minors.Ciencia_de_Datos2023Viña.opcion7")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor2")}</th>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Bioingeniería2023Viña.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Bioingeniería2023Viña.opcion6")}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("pregrado.minors.minor5")}</th>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion1")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion2")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion3")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion4")}</td>
                                <td>{t("pregrado.minors.Obras_Civiles2023Viña.opcion5")}</td>
                                <td colspan="2">{t("pregrado.minors.Obras_Civiles2023Viña.opcion6")}</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </Collapse>
            
                            </div>
                            
                            
                        </div>
                        
    
                    </div>
                </div>
            </div>
        </div>
        </>
        )

}

export default Minors