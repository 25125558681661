import React, {useState} from "react";
import clases from "../../resources/MagisterInternacional.jpg"
import Formulario from "../../Word/FormulariodePostulación.doc"
import PlanArt from "../../Word/Plandearticulación.docx"
import Presentacion from "../../pdf/Magister/Presentacion.pdf"

import { useTranslation } from "react-i18next";


const MagisterTitulacion = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>
    
    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="col-md-6 offset-md-3">
            <div className="card position-relative ms-4 me-4">
                        <img src={clases}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.magister_in.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
            </div>
        </div>

        <div className="col-md-6 offset-md-3">  
                            <div className="position-relative">     
                                <div className="p-3"/>
                                <h1 className="text-center fs-3">{t("titulacion.magister_in.t2")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t3")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t4")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t5")}</p>
                                <ul>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://www.hec.edu/Masters-programs/Master-s-Programs/Grande-Ecole/Master-in-Management/Key-Features">{t("titulacion.magister_in.t6")}</a> {t("titulacion.magister_in.t7")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="https://www.leeds.ac.uk/">{t("titulacion.magister_in.t8")}</a>{t("titulacion.magister_in.t9")}<a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201332/masters">{t("titulacion.magister_in.t10")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201322/masters">{t("titulacion.magister_in.t11")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201312/masters">{t("titulacion.magister_in.t12")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201342/masters">{t("titulacion.magister_in.t13")}</a>{t("titulacion.magister_in.t14")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://engineering.nyu.edu/admissions/graduate/masters/">{t("titulacion.magister_in.t15")}</a>{t("titulacion.magister_in.t16")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://www.sengpp.ust.hk/programs/list/en/index.html">{t("titulacion.magister_in.t17")}</a>{t("titulacion.magister_in.t18")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="https://didattica.polito.it/portal/pls/portal/sviluppo.offerta_formativa.lauree?p_tipo_cds=Z&p_elenco=T&p_a_acc=2017&p_lang=EN">{t("titulacion.magister_in.t19")}</a>{t("titulacion.magister_in.t20")}</li>
                                </ul>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t21")}<a className="text-decoration-none color-uai" href="mailto:internacional.fic@uai.cl">{t("titulacion.magister_in.t22")}</a> {t("titulacion.magister_in.t23")} <a className="text-decoration-none color-uai" href={Formulario} download="FormularioPostulacion.doc">{t("titulacion.magister_in.t24")} </a>{t("titulacion.magister_in.t25")}  <a className="text-decoration-none color-uai" href={PlanArt} download="Plan de articulacion.docx">{t("titulacion.magister_in.t26")}</a> {t("titulacion.magister_in.t27")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t28")}<a className="text-decoration-none color-uai" href={Presentacion} download="Presentacion Master Internacional.pdf">{t("titulacion.magister_in.t29")}</a></p>
                                <div className="p-2"/>
                                <div class="ratio ratio-16x9 shadow-lg">
                                    <iframe src="https://e.issuu.com/embed.html?identifier=2t0iwoyeap6d&embedType=script&u=facultadingenieriacienciasuai&d=articulaci_n-master-extranjero-2018_0dc86e700ccf9e&p=1" allowfullscreen></iframe>
                                </div>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t30")}<a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=32511">{t("titulacion.magister_in.t31")}</a>.</p>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            </div>
                            <div className="p-3"/>
        </div>
    </div>
    <div className="d-xl-none">
        <div className="d-none d-md-block">
        <div className="card position-relative ms-4 me-4">
                    <img src={clases}className="card-img"/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.magister_in.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
        </div>

        <div className="col-md-6 offset-md-3">  
                            <div className="position-relative">     
                                <div className="p-3"/>
                                <h1 className="text-center fs-3">{t("titulacion.magister_in.t2")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t3")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t4")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t5")}</p>
                                <ul>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://www.hec.edu/Masters-programs/Master-s-Programs/Grande-Ecole/Master-in-Management/Key-Features">{t("titulacion.magister_in.t6")}</a> {t("titulacion.magister_in.t7")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="https://www.leeds.ac.uk/">{t("titulacion.magister_in.t8")}</a>{t("titulacion.magister_in.t9")}<a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201332/masters">{t("titulacion.magister_in.t10")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201322/masters">{t("titulacion.magister_in.t11")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201312/masters">{t("titulacion.magister_in.t12")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201342/masters">{t("titulacion.magister_in.t13")}</a>{t("titulacion.magister_in.t14")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://engineering.nyu.edu/admissions/graduate/masters/">{t("titulacion.magister_in.t15")}</a>{t("titulacion.magister_in.t16")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://www.sengpp.ust.hk/programs/list/en/index.html">{t("titulacion.magister_in.t17")}</a>{t("titulacion.magister_in.t18")}</li>
                                    <li className="fs-6"><a className="text-decoration-none color-uai" href="https://didattica.polito.it/portal/pls/portal/sviluppo.offerta_formativa.lauree?p_tipo_cds=Z&p_elenco=T&p_a_acc=2017&p_lang=EN">{t("titulacion.magister_in.t19")}</a>{t("titulacion.magister_in.t20")}</li>
                                </ul>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t21")}<a className="text-decoration-none color-uai" href="mailto:internacional.fic@uai.cl">{t("titulacion.magister_in.t22")}</a> {t("titulacion.magister_in.t23")} <a className="text-decoration-none color-uai" href={Formulario} download="FormularioPostulacion.doc">{t("titulacion.magister_in.t24")} </a>{t("titulacion.magister_in.t25")}  <a className="text-decoration-none color-uai" href={PlanArt} download="Plan de articulacion.docx">{t("titulacion.magister_in.t26")}</a> {t("titulacion.magister_in.t27")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t28")}<a className="text-decoration-none color-uai" href={Presentacion} download="Presentacion Master Internacional.pdf">{t("titulacion.magister_in.t29")}</a></p>
                                <div className="p-2"/>
                                <div class="ratio ratio-16x9 shadow-lg">
                                    <iframe src="https://e.issuu.com/embed.html?identifier=2t0iwoyeap6d&embedType=script&u=facultadingenieriacienciasuai&d=articulaci_n-master-extranjero-2018_0dc86e700ccf9e&p=1" allowfullscreen></iframe>
                                </div>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t30")}<a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=32511">{t("titulacion.magister_in.t31")}</a>.</p>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            </div>
                            <div className="p-3"/>
        </div>
        </div>
    </div>



    <div className="d-md-none">
        <div className="card border-0">
            <div className="row g-0">
                        <div className="col-md-6">
                            <img src={clases} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("titulacion.magister_in.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("titulacion.magister_in.t3")}</p>
                                    <div className="p-2"/>
                                    <p className="text-start fs-6">{t("titulacion.magister_in.t4")}</p>
                                    <div className="p-2"/>
                                    <p className="text-start fs-6">{t("titulacion.magister_in.t5")}</p>
                                    <ul>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="http://www.hec.edu/Masters-programs/Master-s-Programs/Grande-Ecole/Master-in-Management/Key-Features">{t("titulacion.magister_in.t6")}</a> {t("titulacion.magister_in.t7")}</li>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="https://www.leeds.ac.uk/">{t("titulacion.magister_in.t8")}</a>{t("titulacion.magister_in.t9")}<a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201332/masters">{t("titulacion.magister_in.t10")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201322/masters">{t("titulacion.magister_in.t11")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201312/masters">{t("titulacion.magister_in.t12")}</a>; <a className="text-decoration-none color-uai" href="https://engineering.leeds.ac.uk/info/201342/masters">{t("titulacion.magister_in.t13")}</a>{t("titulacion.magister_in.t14")}</li>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="http://engineering.nyu.edu/admissions/graduate/masters/">{t("titulacion.magister_in.t15")}</a>{t("titulacion.magister_in.t16")}</li>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="http://www.sengpp.ust.hk/programs/list/en/index.html">{t("titulacion.magister_in.t17")}</a>{t("titulacion.magister_in.t18")}</li>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="https://didattica.polito.it/portal/pls/portal/sviluppo.offerta_formativa.lauree?p_tipo_cds=Z&p_elenco=T&p_a_acc=2017&p_lang=EN">{t("titulacion.magister_in.t19")}</a>{t("titulacion.magister_in.t20")}</li>
                                    </ul>
                                    <div className="p-2"/>
                                    <p className="text-start fs-6">{t("titulacion.magister_in.t21")}<a className="text-decoration-none color-uai" href="mailto:internacional.fic@uai.cl">{t("titulacion.magister_in.t22")}</a> {t("titulacion.magister_in.t23")} <a className="text-decoration-none color-uai" href={Formulario} download="FormularioPostulacion.doc">{t("titulacion.magister_in.t24")} </a>{t("titulacion.magister_in.t25")}  <a className="text-decoration-none color-uai" href={PlanArt} download="Plan de articulacion.docx">{t("titulacion.magister_in.t26")}</a> {t("titulacion.magister_in.t27")}</p>
                                    <div className="p-2"/>
                                    <p className="text-start fs-6">{t("titulacion.magister_in.t28")}<a className="text-decoration-none color-uai" href={Presentacion} download="Presentacion Master Internacional.pdf">{t("titulacion.magister_in.t29")}</a></p>
                                    <div className="p-2"/>
                                    <div class="ratio ratio-16x9 shadow-lg">
                                        <iframe src="https://e.issuu.com/embed.html?identifier=2t0iwoyeap6d&embedType=script&u=facultadingenieriacienciasuai&d=articulaci_n-master-extranjero-2018_0dc86e700ccf9e&p=1" allowfullscreen></iframe>
                                    </div>
                                    <div className="p-3"/>
                                    <p className="text-start fs-6">{t("titulacion.magister_in.t30")}<a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=32511">{t("titulacion.magister_in.t31")}</a>.</p>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                               
                            </div>


                        </div>
            </div>

            
        </div>
    </div>
    </>
    )
}



export default MagisterTitulacion;