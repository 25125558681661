import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import "./styles/App.scss";
import {BrowserRouter as Router} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18next from 'i18next';

import global_es from "./context/es/global.json"
import global_en from "./context/en/global.json"

import ReactGA from "react-ga";
ReactGA.initialize("G-QZH4XVW799");

ReactGA.send({
  hitType:"pageview",
  page: window.location.pathname,
});
i18next.init({
  interpolation: {escapeValue: false},
  lng: "es",
  resources: {
    es:{
      global: global_es
    },
    en:{
      global: global_en
    }
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18next}>
      <App />
      </I18nextProvider>
    </Router>
  </React.StrictMode>
);

/*
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App/>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
