import React from "react";

import inter from "../../resources/inter.jpeg"
import { useTranslation } from "react-i18next";

const CoordinacionInternacionalizacion = () =>{
    const [t, i18n]= useTranslation("global");
    return(
        <>
        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className="col-md-6 offset-md-3">        
                    <div className="card position-relative ms-4 me-4">
                        <img src={inter}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("internacionalizacion.coordinacion.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
    
            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <p className="text-start fs-6">{t("internacionalizacion.coordinacion.t2")} <strong>{t("internacionalizacion.coordinacion.t3")}</strong></p>
                <div className="p-2"/>
                <p className="text-start fs-6"><strong>{t("internacionalizacion.coordinacion.t4")}</strong> {t("internacionalizacion.coordinacion.t5")}</p>
                <div className="p-2"/>
            </div>
            <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t6")} <strong>{t("internacionalizacion.coordinacion.t7")}</strong> {t("internacionalizacion.coordinacion.t8")} (<a className="text-decoration-none color-uai" href="mailto:francisco.ferrer@uai.cl">{t("internacionalizacion.coordinacion.t9")}</a>)</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t10")} (<a className="text-decoration-none color-uai" href="mailto:rrii@uai.cl">{t("internacionalizacion.coordinacion.t11")}</a>)</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t12")} <strong>{t("internacionalizacion.coordinacion.t13")}</strong> {t("internacionalizacion.coordinacion.t14")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t15")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=32511">{t("internacionalizacion.coordinacion.t16")}</a></li>
                            <div className="p-1"/>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
            </div>
        
        </div>
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
            <div className="">        
                        <div className="card position-relative">
                            <img src={inter}className="card-img"/>
                            <div className="card-img-overlay position-absolute custom-co-image">
                                <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("internacionalizacion.coordinacion.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                            </div>
                        </div>
                </div>
                <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
            <p className="text-start fs-6">{t("internacionalizacion.coordinacion.t1")} <strong>{t("internacionalizacion.coordinacion.t3")}</strong></p>
                <div className="p-2"/>
                <p className="text-start fs-6"><strong>{t("internacionalizacion.coordinacion.t4")}</strong> {t("internacionalizacion.coordinacion.t5")}</p>
                <div className="p-2"/>
            </div>
                <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t6")} <strong>{t("internacionalizacion.coordinacion.t7")}</strong> {t("internacionalizacion.coordinacion.t8")} (<a className="text-decoration-none color-uai" href="mailto:francisco.ferrer@uai.cl">{t("internacionalizacion.coordinacion.t9")}</a>)</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t10")} (<a className="text-decoration-none color-uai" href="mailto:rrii@uai.cl">{t("internacionalizacion.coordinacion.t11")}</a>)</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t12")} <strong>{t("internacionalizacion.coordinacion.t13")}</strong> {t("internacionalizacion.coordinacion.t14")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t15")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=32511">{t("internacionalizacion.coordinacion.t16")}</a></li>
                            <div className="p-1"/>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
            </div>
                
                
            </div>
    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={inter} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("internacionalizacion.coordinacion.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("internacionalizacion.coordinacion.t1")} <strong>{t("internacionalizacion.coordinacion.t3")}</strong></p>
                                <div className="p-2"/>
                                <p className="text-start fs-6"><strong>{t("internacionalizacion.coordinacion.t4")}</strong> {t("internacionalizacion.coordinacion.t5")}</p>
                                <div className="p-2"/>  
                            </div>
                            <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t6")} <strong>{t("internacionalizacion.coordinacion.t7")}</strong> {t("internacionalizacion.coordinacion.t8")} (<a className="text-decoration-none color-uai" href="mailto:francisco.ferrer@uai.cl">{t("internacionalizacion.coordinacion.t9")}</a>)</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t10")} (<a className="text-decoration-none color-uai" href="mailto:rrii@uai.cl">{t("internacionalizacion.coordinacion.t11")}</a>)</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t12")} <strong>{t("internacionalizacion.coordinacion.t13")}</strong> {t("internacionalizacion.coordinacion.t14")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("internacionalizacion.coordinacion.t15")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=32511">{t("internacionalizacion.coordinacion.t16")}</a></li>
                            <div className="p-1"/>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
                </div>
                            
                            
            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </>
        )

}

export default CoordinacionInternacionalizacion