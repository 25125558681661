import React from "react";
import { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';




import uai2 from "../../resources/IngenieriaBio.jpeg"

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Tabla from "../Automatizacion/Minor_Mencion_tabla";

    

const Minors = () =>{
    
    const [t, i18n]= useTranslation("global");
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(true);
   
  





    
    return(
        
        <>

        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className="col-6 offset-3">        
                    <div className="card position-relative ms-4 me-4">
                        <img src={uai2}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("quinto.mencion.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
    
            <div className="p-3"/>
            <div className="col-6 offset-3 ">
                <p className="text-start fs-6">{t("quinto.mencion.t2")}</p>
            </div>
            <div className="p-3"/>





                <div className="col-8 offset-2">
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("quinto.mencion.t4")}</th>
                            <th scope="col">{t("quinto.mencion.t5")}</th>


                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                
                                <Tabla carrera="Tec_Inf_Telec" cantidad_opciones={42}/>
                            </tr>
                            <tr>
                                
                                <Tabla carrera="Eym" cantidad_opciones={31}/>
  
                            </tr>
                            <tr>
                                <Tabla carrera="Min" cantidad_opciones={9}/>
 
                            </tr>
                            <tr>
                                <Tabla carrera="Mec" cantidad_opciones={27}/>

                            </tr>
                            <tr>
                                <Tabla carrera="Mec" cantidad_opciones={18}/>

   
                            </tr>
                            <tr>

                                <Tabla carrera="Ind" cantidad_opciones={25}/>

   

                            </tr>
                            <tr>

                                <Tabla carrera="Bio" cantidad_opciones={12}/>


                            </tr>
 
                        </tbody>


                    </table>
                </div>
            
                


            

        </div>
        
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
                <div className="">        
                            <div className="card position-relative">
                                <img src={uai2}className="card-img"/>
                                <div className="card-img-overlay position-absolute custom-co-image">
                                    <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("quinto.mencion.t1")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                                </div>
                            </div>
                    </div>
                    <div className="p-3"/>
                <div className="col-md-6 offset-md-3">
                    <p className="text-start fs-6">{t("quinto.mencion.t2")}</p>
                </div>
                <div className="d-none d-md-block container"> 

                <div>
                        
           
     
                <div className="col-8 offset-2 table-responsive-xl">
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("quinto.mencion.t4")}</th>
                            <th scope="col">{t("quinto.mencion.t5")}</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                
                                <Tabla carrera="Tec_Inf_Telec" cantidad_opciones={42}/>
                            </tr>
                            <tr>
                                
                                <Tabla carrera="Eym" cantidad_opciones={31}/>
  
                            </tr>
                            <tr>
                                <Tabla carrera="Min" cantidad_opciones={9}/>
 
                            </tr>
                            <tr>
                                <Tabla carrera="Mec" cantidad_opciones={27}/>

                            </tr>
                            <tr>
                                <Tabla carrera="Mec" cantidad_opciones={18}/>

   
                            </tr>
                            <tr>

                                <Tabla carrera="Ind" cantidad_opciones={25}/>

   

                            </tr>
                            <tr>

                                <Tabla carrera="Bio" cantidad_opciones={12}/>


                            </tr>
 
                        </tbody>


                    </table>
                </div>

          
            
                    </div>
                </div>
                    
                
            </div>
                {/*}Renderizado desde pantallas md{*/}



    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={uai2} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("quinto.mencion.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("quinto.mencion.t2")}</p>
                            </div>
                            <div className="bg-grey">
                    
                            <div className="p-4 container"/>
                                
                            </div>
                            <div className="">
                <div className="col-8 offset-2 table-responsive-xl">
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("quinto.mencion.t4")}</th>
                            <th scope="col">{t("quinto.mencion.t5")}</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                
                                <Tabla carrera="Tec_Inf_Telec" cantidad_opciones={42}/>
                            </tr>
                            <tr>
                                
                                <Tabla carrera="Eym" cantidad_opciones={31}/>
  
                            </tr>
                            <tr>
                                <Tabla carrera="Min" cantidad_opciones={9}/>
 
                            </tr>
                            <tr>
                                <Tabla carrera="Mec" cantidad_opciones={27}/>

                            </tr>
                            <tr>
                                <Tabla carrera="Mec" cantidad_opciones={18}/>

   
                            </tr>
                            <tr>

                                <Tabla carrera="Ind" cantidad_opciones={25}/>

   

                            </tr>
                            <tr>

                                <Tabla carrera="Bio" cantidad_opciones={12}/>


                            </tr>
                        </tbody>


                    </table>
                </div>
          
            
                            </div>
                            
                            
                        </div>
                        
    
                    </div>
                </div>
            </div>
        </div>
        </>
        )

}

export default Minors