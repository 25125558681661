import React from "react";
import { Fade } from "react-awesome-reveal";
import Style from "../../styles/malla.css"
import Energia from "../../resources/IngenieriaEnergia.jpeg"
import Informatica from "../../resources/IngenieriaInformatica.jpeg"
import Mineria from "../../resources/IngenieriaMinas.jpeg"
import Bio from "../../resources/IngenieriaBio.jpeg"
import Industrial from "../../resources/IngenieriaIndustrial.jpeg"
import Mecanica from "../../resources/IngenieriaMecania.jpeg"
import Obras from "../../resources/IngenieriaObrasCiviles.jpeg"
import Obraspdf from "../../pdf/Obras/CIV2018.pdf"
import Bioingenieriapdf from "../../pdf/Bioingieneria/BIO2018.pdf"
import Energiapdf from "../../pdf/Energia/EYM2018.pdf"
import Industrialpdf from "../../pdf/Industrial/IND2018.pdf"
import Informaticapdf from "../../pdf/Informatica/INF2018.pdf"
import Mecanicapdf from "../../pdf/Mecanica/MEC2018.pdf"
import Mineriapdf from "../../pdf/Mineria/MIN2018.pdf"
import MostrarMallas from "../Automatizacion/MostrarMallas";
import AñosPr from "../Automatizacion/AñosPr";
import { useTranslation } from "react-i18next";
const Malla2018Pr = () =>{
    const [t, i18n]= useTranslation("global");
    const año = 2018;
    const Carreras = [
        { Carrera: t("mallas.t11") },
        { Carrera: t("mallas.t3")},
        { Carrera: t("mallas.t4")},
        { Carrera: t("mallas.t5")},
        { Carrera: t("mallas.t6") },
        { Carrera: t("mallas.t7") },
        { Carrera: t("mallas.t8") }
    ];
    return(
    <>
        {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block" >
        <div className="">        
            <div className="position-relative ms-4 me-4">
                    <div className="d-flex justify-content-center">
                       <h1>{t("mallas.t1")}</h1>
                    </div>
            </div>
    
        </div>
    </div>
        
    <AñosPr
        año = {año}
        />
    
    
        <MostrarMallas año={año} linkEnergia = {Energiapdf} linkInfo={Informaticapdf} linkMin={Mineriapdf} linkBio={Bioingenieriapdf} linkIndustrial={Industrialpdf} linkMeca={Mecanicapdf} linkObras={Obraspdf} carrera={Carreras} />
        </>)
}



export default Malla2018Pr;