import React, {useState} from "react";

import {Button, Collapse} from "react-bootstrap";
import about from "../../resources/about.jpeg"
import { useTranslation } from "react-i18next";

const PreguntasInternacionalizacion = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    
    return(
    <>
    <div className="d-none d-xl-block">
        <div className="">        
                    <div className="card position-relative ms-xl-4 me-xl-4">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("internacionalizacion.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
        <div className="d-flex">
            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t3")} <a className="text-decoration-none color-uai" href="https://www.uai.cl/assets/uploads/2020/04/universidades-socias-proceso-1sem-2021-v31.pdf">{t("internacionalizacion.preguntas.t4")}</a>.</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t5")}</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t6")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t7")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t8")} <a className="text-decoration-none color-uai" href="https://www.uai.cl/rrii/alumnos-uai/programas-de-intercambio-semestral/">{t("internacionalizacion.preguntas.t9")}</a>.</p>
                            <div className="p-1"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t10")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t11")} <a className="text-decoration-none color-uai" href="https://ingenieria.uai.cl/internacionalizacion/intercambios-postgrado/">{t("internacionalizacion.preguntas.t12")}</a>.</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t13")} (<a className="text-decoration-none color-uai" href="mailto:internacional.fic@uai.cl">{t("internacionalizacion.preguntas.t14")}</a>) {t("internacionalizacion.preguntas.t15")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t16")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t17")}</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t18")}</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t19")} <a className="text-decoration-none color-uai" href="mailto:internacional.fic@uai.cl">{t("internacionalizacion.preguntas.t20")}</a></p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t21")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t22")} <a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/internacionalizacion/becas-en-el-extranjero/">{t("internacionalizacion.preguntas.t23")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    
                    
                    </div>
                </div>
            </div>
        </div>

{/*}-----------------------------------------------------------------{*/}

        <div className="d-xl-none">
            <div className="p-3 mt-2"/>
            <div className="">        
                    <div className="card position-relative">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("internacionalizacion.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
            <div className="d-flex">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t3")} <a className="text-decoration-none color-uai" href="https://www.uai.cl/assets/uploads/2020/04/universidades-socias-proceso-1sem-2021-v31.pdf">{t("internacionalizacion.preguntas.t4")}</a>.</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t5")}</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t6")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t7")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t8")} <a className="text-decoration-none color-uai" href="https://www.uai.cl/rrii/alumnos-uai/programas-de-intercambio-semestral/">{t("internacionalizacion.preguntas.t9")}</a>.</p>
                            <div className="p-1"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t10")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t11")} <a className="text-decoration-none color-uai" href="https://ingenieria.uai.cl/internacionalizacion/intercambios-postgrado/">{t("internacionalizacion.preguntas.t12")}</a>.</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t13")} (<a className="text-decoration-none color-uai" href="mailto:internacional.fic@uai.cl">{t("internacionalizacion.preguntas.t14")}</a>) {t("internacionalizacion.preguntas.t15")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t16")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t17")}</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t18")}</p>
                            <div className="p-1"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t19")} <a className="text-decoration-none color-uai" href="mailto:internacional.fic@uai.cl">{t("internacionalizacion.preguntas.t20")}</a></p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("internacionalizacion.preguntas.t21")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="text-start fs-6 m-2">{t("internacionalizacion.preguntas.t22")} <a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/internacionalizacion/becas-en-el-extranjero/">{t("internacionalizacion.preguntas.t23")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    
                    
                    </div>  
                </div>
            </div>  
        
        </div>
        
    </>
    )
}



export default PreguntasInternacionalizacion;