import React from "react";
import Obraspdf from "../../pdf/Obras/CIV2021.pdf"
import Bioingenieriapdf from "../../pdf/Bioingieneria/BIO2021.pdf"
import Energiapdf from "../../pdf/Energia/EYM2021.pdf"
import Industrialpdf from "../../pdf/Industrial/IND2021.pdf"
import Informaticapdf from "../../pdf/Informatica/INF2021.pdf"
import Mecanicapdf from "../../pdf/Mecanica/MEC2021.pdf"
import Mineriapdf from "../../pdf/Mineria/MIN2021.pdf"
import { useTranslation } from "react-i18next";
import MostrarMallas from "../Automatizacion/MostrarMallas";
import AñosPr from "../Automatizacion/AñosPr";

const Malla2021Pr = () =>{
    const [t, i18n]= useTranslation("global");
    const año = 2021;
    const Carreras = [
        { Carrera: t("mallas.t2") },
        { Carrera: t("mallas.t3")},
        { Carrera: t("mallas.t4")},
        { Carrera: t("mallas.t5")},
        { Carrera: t("mallas.t6") },
        { Carrera: t("mallas.t7") },
        { Carrera: t("mallas.t8") }
    ];
    return(
    <>
        {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block" >
        <div className="">        
            <div className="position-relative ms-4 me-4">
                    <div className="d-flex justify-content-center">
                       <h1>{t("mallas.t1")}</h1>
                    </div>
            </div>
    
        </div>
    </div>

    <AñosPr
        año = {año}
        />
    
    
        <MostrarMallas año={año} linkEnergia = {Energiapdf} linkInfo={Informaticapdf} linkMin={Mineriapdf} linkBio={Bioingenieriapdf} linkIndustrial={Industrialpdf} linkMeca={Mecanicapdf} linkObras={Obraspdf} carrera={Carreras}/>

    </>
    )
}



export default Malla2021Pr;