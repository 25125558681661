import React from "react";
import Obraspdf from "../../pdf/Obras/QCIV2020.pdf"
import Energiapdf from "../../pdf/Energia/QEYM2020.pdf"
import Mecanicapdf from "../../pdf/Mecanica/QMEC2020.pdf"
import Mineriapdf from "../../pdf/Mineria/QMIN2020_2021.pdf"
import { useTranslation } from "react-i18next";
import MostrarMallas from "../Automatizacion/MostrarMallas";
import AñosQ from "../Automatizacion/AñosQ";

const Malla2020Q = () =>{
    const [t, i18n]= useTranslation("global");
    const año = 2020;
    const Carreras = [
        { Carrera: t("mallas.t11") },
        { Carrera: t("mallas.t3")},
        { Carrera: t("mallas.t4")},
        { Carrera: t("mallas.t5")},
        { Carrera: t("mallas.t6") },
        { Carrera: t("mallas.t7") },
        { Carrera: t("mallas.t8") }
    ];

    //* Informatica, Industrial y Bioingenería no tienen Link por mientras

    
    return(
    <>
        {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block" >
        <div className="">        
            <div className="position-relative ms-4 me-4">
                    <div className="d-flex justify-content-center">
                       <h1>{t("mallas.t10")}</h1>
                    </div>
            </div>
    
        </div>
    </div>
    <AñosQ
        año = {año}
        />
    
        
        <MostrarMallas año={año} linkEnergia = {Energiapdf} linkInfo={""} linkMin={Mineriapdf} linkBio={""} linkIndustrial={""} linkMeca={Mecanicapdf} linkObras={Obraspdf} carrera={Carreras}/>
    </>
    )
}



export default Malla2020Q;