import React from "react";
import { Fade } from "react-awesome-reveal";
import rutaEnergia from "../../resources/IngenieriaEnergia.jpeg"
import rutaInformatica from "../../resources/IngenieriaInformatica.jpeg"
import rutaMineria from "../../resources/IngenieriaMinas.jpeg"
import rutaBioingenieria from "../../resources/IngenieriaBio2.jpeg"
import rutaIndustrial from "../../resources/IngenieriaIndustrial.jpeg"
import rutaMecanica from "../../resources/IngenieriaMecania.jpeg"
import rutaObraCivil from "../../resources/IngenieriaObrasCiviles.jpeg"

import { useTranslation } from "react-i18next";




//Aca encontraras la funcion que detalla las mallas de Primer Ciclo y Segundo Ciclo, en el caso de que se agregue una nueva malla simplemente podras agregara un nuevo parametro a LasMallas

function MostrarMallas({año,linkEnergia, linkInfo, linkMin, linkBio, linkIndustrial, linkMeca, linkObras, carrera }){

  
    const [t, i18n]= useTranslation("global");
    
    const LasMallas = [
          {  SRC: rutaEnergia, LINK: linkEnergia },
          {  SRC: rutaInformatica, LINK: linkInfo },
          {  SRC: rutaMineria, LINK: linkMin },
          {  SRC: rutaBioingenieria, LINK: linkBio },
          {  SRC: rutaIndustrial, LINK: linkIndustrial },
          {  SRC: rutaMecanica, LINK: linkMeca },
          {  SRC: rutaObraCivil, LINK: linkObras }
        ];


    
    
  return (
    /**
     * Para tener un mejor entendimiento de esta funcion, index es la fila y i es la columna, por lo que se esta creando una fila de 3 columnas, se va recorriendo cada carrera y se va creando una tarjeta con la imagen de la carrera y el nombre de la carrera, en el caso de que se quiera agregar una nueva carrera simplemente se debe agregar un nuevo objeto a LasMallas y un nuevo objeto a carrera
     */
    <div>
        {Object.keys(LasMallas).map((malla, index) => (
  index % 3 === 0 && (
    <React.Fragment key={`group-${index / 3}`}>
      <Fade key={`fade-${index / 3}`} delay={index * 200}>
        <div className="d-flex justify-content-center mt-4">
          {Object.keys(LasMallas)
            .slice(index, index + 3)
            .map((malla, i) => {
              const currentMalla = LasMallas[malla];
              return (
                <div key={i} className="card hover-effect" style={{ margin: "0 10px", position: "relative", maxWidth: "calc(33.33% - 20px)", border: "none", height: "200px" }}>
                  <a href={currentMalla.LINK} target="_blank" rel="noreferrer"  style={{height: "100%", width: "100%", textDecoration:"none", display: "block", position: "relative"}}>

                    <img alt= ""src={currentMalla.SRC} className="card-img" style={{ width: "100%", height: "100%", objectFit: "contain" }} />

                    <div  className="card-overlay d-flex flex-column justify-content-center align-items-center position-absolute top-0 start-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)",height: "100%", width: "100%", textAlign: "center" }}>
                        <h4 style={{  color: "white", margin: 0 }}> 
                            {carrera[i+index].Carrera}
                        </h4>
                    </div>

                  </a>
                </div>
              );
            })}
        </div>
      </Fade>
    </React.Fragment>
  )))}
    </div>
    

    
  );
}

export default MostrarMallas;