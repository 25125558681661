import React, {useState} from "react";

import {Button, Collapse} from "react-bootstrap";
import uai from "../../resources/uai.jpg"
import about from "../../resources/about.jpeg"
import { useTranslation } from "react-i18next";

const PreguntasAsuntos = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    return(
    <>
    <div className="d-none d-xl-block">
        <div className="">        
                    <div className="card position-relative ms-xl-4 me-xl-4">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("asuntos.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
        <div className="d-flex">
            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t3")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t4")}</p>
                            <p className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://alumno.uai.cl/santiago/asuntos-estudiantiles/dae/">{t("asuntos.t5")}</a> {t("asuntos.t6")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t7")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=22#tabs-tree-start">{t("asuntos.t8")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t9")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t10")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t11")} <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.stgo@uai.cl">{t("asuntos.t12")}</a></li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t13")} <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.vina@uai.cl">{t("asuntos.t14")}</a></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t15")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t16")} <a className="text-decoration-none color-uai" href="https://www.facebook.com/daeuaisantiago/?ref=pages_you_manage">{t("asuntos.t17")}</a>{t("asuntos.t18")} <a className="text-decoration-none color-uai" href="https://www.instagram.com/daeuaisantiago/">{t("asuntos.t19")}</a>{t("asuntos.t20")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t21")} <a className="text-decoration-none color-uai" href="https://www.facebook.com/daeuaivina">{t("asuntos.t22")}</a>{t("asuntos.t23")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t24")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <a className="col-md-8 offset-md-2 fs-6 text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=20#tabs-tree-start">{t("asuntos.t25")}</a>
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t26")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t27")} (<a className="text-decoration-none color-uai" href="mailto:pbeas@alumnos.uai.cl">{t("asuntos.t28")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t29")} (<a className="text-decoration-none color-uai" href="mailto:danielarodriguez@alumnos.uai.cl">{t("asuntos.t30")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t31")} (<a className="text-decoration-none color-uai" href="mailto:msein@alumnos.uai.cl">{t("asuntos.t32")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t33")} (<a className="text-decoration-none color-uai" href="mailto:szilleruelo@alumnos.uai.cl">{t("asuntos.t34")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t35")} (<a className="text-decoration-none color-uai" href="mailto:fenicolas@alumnos.uai.cl">{t("asuntos.t36")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t37")} (<a className="text-decoration-none color-uai" href="mailto:dalara@alumnos.uai.cl">{t("asuntos.t38")}</a>)</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t39")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t40")} <a className="text-decoration-none color-uai" href="http://www.uai.cl/alumnos-de-pregrado/santiago/organizaciones-estudiantiles">{t("asuntos.t41")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t42")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t43")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=22#tabs-tree-start">{t("asuntos.t44")}</a> {t("asuntos.t45")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t46")} <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.stgo@uai.cl">{t("asuntos.t47")}</a> / <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.vina@uai.cl">{t("asuntos.t48")}</a></p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen6(!open6)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open6}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t49")}</p>
                    </Button>
                    <Collapse in={open6}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t50")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t51")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t52")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=10#tabs-tree-start">{t("asuntos.t53")}</a> {t("asuntos.t54")} <a className="text-decoration-none color-uai" href="mailto:cade.stgo@uai.cl">{t("asuntos.t55")}</a></p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen7(!open7)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open7}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t56")}</p>
                    </Button>
                    <Collapse in={open7}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t57")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t58")} <a className="text-decoration-none color-uai" href="mailto:carla.reyes@uai.cl">{t("asuntos.t59")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t60")} <a className="text-decoration-none color-uai" href="mailto:vespinoz@uai.cl">{t("asuntos.t61")}</a> {t("asuntos.t62")} <a className="text-decoration-none color-uai" href="http://ttps//webcursos.uai.cl/course/view.php?id=38683&section=7#tabs-tree-start">{t("asuntos.t63")}</a> {t("asuntos.t64")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen8(!open8)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open8}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t65")}</p>
                    </Button>
                    <Collapse in={open8}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t66")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t67")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=31#tabs-tree-start">{t("asuntos.t68")}</a> {t("asuntos.t69")} <a className="text-decoration-none color-uai" href="mailto:tne@uai.cl">{t("asuntos.t70")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen9(!open9)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open9}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t71")}</p>
                    </Button>
                    <Collapse in={open9}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t72")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t73")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t74")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t75")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t76")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t77")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t78")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t79")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t80")}</li>
                            </ul>
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t81")} <a className="text-decoration-none color-uai" href="https://bibliotecas.uai.cl/">{t("asuntos.t82")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen10(!open10)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open10}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t83")}</p>
                    </Button>
                    <Collapse in={open10}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t84")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t85")} <a className="text-decoration-none color-uai" href="http://www.uai.cl/alumnos-de-pregrado/santiago/como-llegar-al-campus">{t("asuntos.t86")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t87")} <a className="text-decoration-none color-uai" href="http://www.uai.cl/alumnos-de-pregrado/vina-del-mar/como-llegar-al-campus">{t("asuntos.t88")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t89")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t90")} <a className="text-decoration-none color-uai" href="http://uai.a-dedo.cl/">{t("asuntos.t91")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen11(!open11)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open11}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t92")}</p>
                    </Button>
                    <Collapse in={open11}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t93")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t94")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t95")} <a className="text-decoration-none color-uai" href="https://alumno.uai.cl/santiago/deportes/">{t("asuntos.t96")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen12(!open12)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open12}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t97")}</p>
                    </Button>
                    <Collapse in={open12}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t98")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t99")} <a className="text-decoration-none color-uai" href="https://www.uai.cl/rrii/">{t("asuntos.t100")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen13(!open13)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open13}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t101")}</p>
                    </Button>
                    <Collapse in={open13}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t102")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=12#tabs-tree-start">{t("asuntos.t103")}</a> {t("asuntos.t104")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t105")} <a className="text-decoration-none color-uai" href="mailto:bienestar.dae@uai.cl">{t("asuntos.t106")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    </div>
                </div>
            </div>
        </div>
{/*}------------------------------------------------------------------------------------------------{*/}
        <div className="d-xl-none">
            <div className="p-3 mt-2"/>
            <div className="">        
                    <div className="card position-relative">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">84</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
            <div className="d-flex">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t3")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t4")}</p>
                            <p className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://alumno.uai.cl/santiago/asuntos-estudiantiles/dae/">{t("asuntos.t5")}</a> {t("asuntos.t6")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t7")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=22#tabs-tree-start">{t("asuntos.t8")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t9")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t10")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t11")} <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.stgo@uai.cl">{t("asuntos.t12")}</a></li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t13")} <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.vina@uai.cl">{t("asuntos.t14")}</a></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t15")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t16")} <a className="text-decoration-none color-uai" href="https://www.facebook.com/daeuaisantiago/?ref=pages_you_manage">{t("asuntos.t17")}</a>{t("asuntos.t18")} <a className="text-decoration-none color-uai" href="https://www.instagram.com/daeuaisantiago/">{t("asuntos.t19")}</a>{t("asuntos.t20")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t21")} <a className="text-decoration-none color-uai" href="https://www.facebook.com/daeuaivina">{t("asuntos.t22")}</a>{t("asuntos.t23")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t24")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <a className="col-md-8 offset-md-2 fs-6 text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=20#tabs-tree-start">{t("asuntos.t25")}</a>
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t26")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t27")} (<a className="text-decoration-none color-uai" href="mailto:pbeas@alumnos.uai.cl">{t("asuntos.t28")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t29")} (<a className="text-decoration-none color-uai" href="mailto:danielarodriguez@alumnos.uai.cl">{t("asuntos.t30")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t31")} (<a className="text-decoration-none color-uai" href="mailto:msein@alumnos.uai.cl">{t("asuntos.t31")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t33")} (<a className="text-decoration-none color-uai" href="mailto:szilleruelo@alumnos.uai.cl">{t("asuntos.t34")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t35")} (<a className="text-decoration-none color-uai" href="mailto:fenicolas@alumnos.uai.cl">{t("asuntos.t36")}</a>)</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t37")} (<a className="text-decoration-none color-uai" href="mailto:dalara@alumnos.uai.cl">{t("asuntos.t38")}</a>)</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t39")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t40")} <a className="text-decoration-none color-uai" href="http://www.uai.cl/alumnos-de-pregrado/santiago/organizaciones-estudiantiles">{t("asuntos.t41")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t42")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t43")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=22#tabs-tree-start">{t("asuntos.t44")}</a> {t("asuntos.t45")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t46")} <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.stgo@uai.cl">{t("asuntos.t47")}</a> / <a className="text-decoration-none color-uai" href="mailto:asuntos.estudiantiles.vina@uai.cl">{t("asuntos.t48")}</a></p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen6(!open6)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open6}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t49")}</p>
                    </Button>
                    <Collapse in={open6}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t50")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t51")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t52")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=10#tabs-tree-start">{t("asuntos.t53")}</a> {t("asuntos.t54")} <a className="text-decoration-none color-uai" href="mailto:cade.stgo@uai.cl">{t("asuntos.t55")}</a></p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen7(!open7)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open7}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t56")}</p>
                    </Button>
                    <Collapse in={open7}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t57")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t58")} <a className="text-decoration-none color-uai" href="mailto:carla.reyes@uai.cl">{t("asuntos.t59")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t60")} <a className="text-decoration-none color-uai" href="mailto:vespinoz@uai.cl">{t("asuntos.t61")}</a> {t("asuntos.t62")} <a className="text-decoration-none color-uai" href="http://ttps//webcursos.uai.cl/course/view.php?id=38683&section=7#tabs-tree-start">{t("asuntos.t63")}</a> {t("asuntos.t64")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen8(!open8)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open8}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t65")}</p>
                    </Button>
                    <Collapse in={open8}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t66")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t67")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=31#tabs-tree-start">{t("asuntos.t68")}</a> {t("asuntos.t69")} <a className="text-decoration-none color-uai" href="mailto:tne@uai.cl">{t("asuntos.t70")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen9(!open9)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open9}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t71")}</p>
                    </Button>
                    <Collapse in={open9}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t72")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t73")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t74")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t75")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t76")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t77")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t78")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t79")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("asuntos.t80")}</li>
                            </ul>
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t81")} <a className="text-decoration-none color-uai" href="https://bibliotecas.uai.cl/">{t("asuntos.t82")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen10(!open10)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open10}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t83")}</p>
                    </Button>
                    <Collapse in={open10}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t84")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t85")} <a className="text-decoration-none color-uai" href="http://www.uai.cl/alumnos-de-pregrado/santiago/como-llegar-al-campus">{t("asuntos.t86")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t87")} <a className="text-decoration-none color-uai" href="http://www.uai.cl/alumnos-de-pregrado/vina-del-mar/como-llegar-al-campus">{t("asuntos.t88")}</a>.</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t89")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t90")} <a className="text-decoration-none color-uai" href="http://uai.a-dedo.cl/">{t("asuntos.t91")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen11(!open11)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open11}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t92")}</p>
                    </Button>
                    <Collapse in={open11}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t93")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t94")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t95")} <a className="text-decoration-none color-uai" href="https://alumno.uai.cl/santiago/deportes/">{t("asuntos.t96")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen12(!open12)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open12}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t97")}</p>
                    </Button>
                    <Collapse in={open12}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t98")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t99")} <a className="text-decoration-none color-uai" href="https://www.uai.cl/rrii/">{t("asuntos.t100")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen13(!open13)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open13}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("asuntos.t101")}</p>
                    </Button>
                    <Collapse in={open13}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t102")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=38683&section=12#tabs-tree-start">{t("asuntos.t103")}</a> {t("asuntos.t104")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("asuntos.t105")} <a className="text-decoration-none color-uai" href="mailto:bienestar.dae@uai.cl">{t("asuntos.t106")}</a>.</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    </div>  
                </div>
            </div>  
        
        </div>
        
    </>
    )
}



export default PreguntasAsuntos;