import { useTranslation } from "react-i18next";

function Tabla(props) {
    const { carrera, cantidad_opciones } = props; // Accede a las propiedades carrera y cantidad_opciones desde el objeto props
    const [t, i18n] = useTranslation("global");

    return (
        <>
            <th scope="row">{t(`quinto.mencion.${carrera}.carrera`)}</th>
            <table class = "table table-hover table-bordered m-0">
                <tbody>
                    {Array.from({ length: cantidad_opciones }).map((_, i) => (
                                <tr key={i}>
                                    <td scope="row">{t(`quinto.mencion.${carrera}.opcion${i+1}`)}</td>
                                </tr>))}
                </tbody>
            </table>
        </>
    );
}

export default Tabla;
