//SideBar para seccion dedicada a pregrado
import React from "react";
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
  } from 'cdbreact';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const SideBarPregrado = () =>{
    const [t, i18n]= useTranslation("global");
    return(
        
        <div style={{ display: 'flex', height: 'auto'}}>
        <CDBSidebar textColor="#fff" backgroundColor="#00a4d3">
            <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large "></i>}>
            {/*}<CDBSidebarHeader>{*/}
            <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
                {t("pregrado.sidebar.t1")}
            </a>
            </CDBSidebarHeader>

            <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
                <NavLink exact to="/ReglamentoPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">{t("pregrado.sidebar.t2")}</CDBSidebarMenuItem>
                </NavLink>
                
                <NavLink exact to="/NormativasPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">{t("pregrado.sidebar.t3")}</CDBSidebarMenuItem>
                </NavLink>
                
                <NavLink exact to="/CalendarioPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="calendar">{t("pregrado.sidebar.t4")}</CDBSidebarMenuItem>
                </NavLink>
                
                <NavLink exact to="/CalendarioPEPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="calendar">{t("pregrado.sidebar.t5")}</CDBSidebarMenuItem>
                </NavLink>
                
                <NavLink exact to="/MinorPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="school">{t("pregrado.sidebar.t6")}</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/TallerPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="school">{t("pregrado.sidebar.t7")}</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/CoordinacionPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user">{t("pregrado.sidebar.t8")}</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/CharlasPregrado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="video">{t("pregrado.sidebar.t9")}</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/PreguntasPregado" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="question">{t("pregrado.sidebar.t10")}</CDBSidebarMenuItem>
                </NavLink>
            </CDBSidebarMenu>
            </CDBSidebarContent>

            <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
                style={{
                padding: '20px 5px',
                }}
            >
            </div>
            </CDBSidebarFooter>
        </CDBSidebar>
        </div>

    )
}

export default SideBarPregrado;