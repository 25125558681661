import React from "react";
import { Fade } from "react-awesome-reveal";

import { Tooltip } from "react-tooltip";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { NavDropdown, Nav, Navbar, Button, Container, Row, Col, Card } from "react-bootstrap";//importacion biblioteca react-bootstrap
import { NavLink } from "react-router-dom";//importacion de navLink para rutas

const Energia2018S9 = () =>{
    return(
    <>
        {/*}Renderizado hasta pantallas xl{*/}
<div class="container text-center">
  <div class="row justify-content-end">
    <div class="col-md-4">
      <h1>Syllabus año 2018</h1>
    </div>
    <div class="col-md-4">
      <h7>Descargar Malla:</h7>
      <div class="card hover-effect d-flex flex-column mx-auto" style={{ backgroundColor: "whitesmoke", width: '150px' }}>
        <div class="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          <BsArrowRight className="text-primary" style={{ fontSize: '2rem' }} />
          <BsFileEarmarkPdf className="text-danger" style={{ fontSize: '2rem' }} />
        </div>
      </div>
    </div>
  </div>
</div>

<div className="d-flex justify-content-center align-items-center">
                    <NavDropdown
                          className="fw-bolder"
                          id="nav-dropdown-3 "
                          title="semestre 9-10"
                          menuVariant="light"
                          style={{ marginTop: '20px', marginBottom: '20px'}} // Agrega el margen superior en línea

                        >{/*}Propiedades de submenu{*/}
                          <NavLink to="/Energia2018" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.0">1-2</NavDropdown.Item>{/*}ruta "/Malla2023" para "Mallas"{*/}
                          </NavLink>
                          <NavLink to="/Energia2018S3" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.0">3-4</NavDropdown.Item>{/*}ruta "/Malla2023" para "Mallas"{*/}
                          </NavLink>
                          <NavLink to="/Energia2018S5" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.1">5-6</NavDropdown.Item>{/*}ruta "/Malla2022" para "Mallas"{*/}
                          </NavLink>
                          <NavLink to="/Energia2018S7" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.2">7-8</NavDropdown.Item>{/*}ruta "/Malla2021" para "Mallas"{*/}
                          </NavLink>
                          <NavLink to="/Energia2018S9" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.3">9-10</NavDropdown.Item>{/*}ruta "/Malla2020" para "Mallas"{*/}
                          </NavLink>
                    </NavDropdown>
</div>
<div className="container text-center mt-3">
  <div className="row">
    <div className="col"></div>
    <div className="col-12 col-md-12 col-lg-7" style={{ backgroundColor: "#E6F7FF", padding: "20px" }}>
      <div className="d-flex justify-content-center mt-4">
        <div className="col-1 d-flex align-items-center justify-content-center">
          <h1>S9</h1>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip2"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip3"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>GYM 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Deporte I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>DEPORTE</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div className="col-1 d-flex align-items-center justify-content-center">
          <h1></h1>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip2"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip3"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>GYM 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Deporte I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>DEPORTE</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div className="col"></div>
  </div>
</div>
<div className="container text-center mt-3">
  <div className="row">
    <div className="col"></div>
    <div className="col-12 col-md-12 col-lg-7" style={{ backgroundColor: "#FFFF99", padding: "20px" }}>
      <div className="d-flex justify-content-center mt-4">
        <div className="col-1 d-flex align-items-center justify-content-center">
          <h1>S10</h1>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip2"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip3"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>GYM 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Deporte I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>DEPORTE</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div className="col-1 d-flex align-items-center justify-content-center">
          <h1></h1>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip2"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>CORE 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Core: Civilización Contemporánea I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>CORE</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-3 mb-4"> {/* Establecer el ancho igual para todas las columnas */}
          <a
            data-tooltip-id="my-tooltip3"
            data-tooltip-place="bottom"
          >
            <div className="card hover-effect d-flex flex-column h-100" style={{ backgroundColor: "whitesmoke", marginLeft: "8px", padding: "10px" }}>
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <h6>GYM 101</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>Deporte I</h6>
              </div>
              <div style={{ marginBottom: "5px", textAlign: "center" }}>
                <h6>DEPORTE</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div className="col"></div>
  </div>
</div>


<Tooltip id="my-tooltip">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip2">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip3">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip4">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip5">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip6">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip7">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip8">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip9">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip10">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip11">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
<Tooltip id="my-tooltip12">
  <div>
    <h4>Core: Civilización Contemporánea I</h4>
    <ul>
      <p>Horas académicas: 150</p>
      <p>Sigla: Core 103</p>
      <p>Créditos: 4</p>
      <p>Asignaturas: Core: Civilización Contemporánea I</p>
    </ul>
  </div>
</Tooltip>
    </>
    )
}



export default Energia2018S9;