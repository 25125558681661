import React from "react";
import uai from "../../resources/uai.jpg"
import uai1 from "../../resources/uai3.jpg"

import { useTranslation } from "react-i18next";

const Coordinacion_SC = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>
    
    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="col-md-6 offset-md-3">        
                <div className="card position-relative ms-4 me-4">
                    <img src={uai}className="card-img"/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("quinto.coordinacion.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
                </div>
        </div>

        <div className="p-3"/>
        <div className="col-md-6 offset-md-3">
            <p className="text-start fs-6">{t("quinto.coordinacion.t2")}</p>
            <div className="p-2"/>
            
    
            <div className="position-relative">     


            
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("quinto.coordinacion.t3")}</li> 
                    <li className="fs-6">{t("quinto.coordinacion.t4")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t5")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t6")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t7")}</li>
                </ul>

            </div>
            <div className="p-3"/>
            <div className="position-relative">
                <h1 className="text-center fs-3">{t("quinto.coordinacion.t8")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
            
                <div className="p-3"/>
                <p className="text-start fs-6">{t("quinto.coordinacion.t9")}</p>
                
            </div>
            <div className="p-3"/>
            

            <div className="position-relative">
                <h1 className="text-center fs-3">{t("quinto.coordinacion.t10")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
            
                <div className="p-3"/>
                <p className="text-start fs-6">{t("quinto.coordinacion.t11")}</p>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("quinto.coordinacion.t12")}</li> 
                    <li className="fs-6">{t("quinto.coordinacion.t13")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t14")}</li>

                </ul>
                <div className="p-3"/>
                <ul>
                    <p className="text-start fs-6"><strong>{t("quinto.coordinacion.t15")}</strong>{t("quinto.coordinacion.t16")}</p>
                    <p className="text-start fs-6"><strong>{t("quinto.coordinacion.t17")}</strong>{t("quinto.coordinacion.t18")}</p>
                    

                </ul>
                <p className="text-start fs-6">{t("quinto.coordinacion.t19")}</p>


                
            </div>
            <div className="p-3"/>
            
        </div>
    
    </div>
    
    {/*}Renderizado desde pantallas lg{*/}
    <div className="d-xl-none">
        <div className="d-none d-md-block">
        <div className="">        
                    <div className="card position-relative">
                        <img src={uai}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("quinto.coordinacion.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>

            <div className="p-3"/>
            <div className="col-xl-6 offset-xl-3 ms-2">
                <p className="text-start fs-6">{t("quinto.coordinacion.t2")}</p>
                <div className="p-2"/>


                <div className="position-relative">     
                
                    <div className="p-3"/>
                    <ul>
                    <li className="fs-6">{t("quinto.coordinacion.t3")}</li> 
                    <li className="fs-6">{t("quinto.coordinacion.t4")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t5")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t6")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t7")}</li>
                </ul>

                </div>
                <div className="p-3"/>
                <div className="position-relative">
                    <h1 className="text-center fs-3">{t("quinto.coordinacion.t8")}</h1>
                    
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-3"/>
                    <p className="text-start fs-6">{t("quinto.coordinacion.t9")}</p>
        


                </div>
                <div className="p-3"/>
                <div className="position-relative">
                <h1 className="text-center fs-3">{t("quinto.coordinacion.t10")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
            
                <div className="p-3"/>
                <p className="text-start fs-6">{t("quinto.coordinacion.t11")}</p>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("quinto.coordinacion.t12")}</li> 
                    <li className="fs-6">{t("quinto.coordinacion.t13")}</li>
                    <li className="fs-6">{t("quinto.coordinacion.t14")}</li>

                </ul>
                <div className="p-3"/>
                <ul>
                    <p className="text-start fs-6"><strong>{t("quinto.coordinacion.t15")}</strong>{t("quinto.coordinacion.t16")}</p>
                    <p className="text-start fs-6"><strong>{t("quinto.coordinacion.t17")}</strong>{t("quinto.coordinacion.t18")}</p>
                    

                </ul>
                <p className="text-start fs-6">{t("quinto.coordinacion.t19")}</p>


                
            </div>
            <div className="p-3"/>

            </div>
        </div>





        

        <div className="d-md-none">
            <div className="card border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={uai1} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("pregrado.coordinacion.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("pregrado.coordinacion.t2")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("pregrado.coordinacion.t3")}</p>
                <div className="p-2"/>
                <div className="position-relative">     
                    <h1 className="text-center fs-3">{t("pregrado.coordinacion.t4")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                
                    <div className="p-3"/>
                    <ul>
                        <li className="fs-6">{t("pregrado.coordinacion.t5")}</li> 
                        <li className="fs-6">{t("pregrado.coordinacion.t6")}</li>
                        <li className="fs-6">{t("pregrado.coordinacion.t7")}</li>
                        <li className="fs-6">{t("pregrado.coordinacion.t8")}</li>
                    </ul>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                </div>
                <div className="p-3"/>
                <div className="position-relative">
                    <h1 className="text-center fs-3">{t("pregrado.coordinacion.t9")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                
                    <div className="p-3"/>
                    <ul>
                        <li className="fs-6">{t("pregrado.coordinacion.t10")}</li>
                        <li className="fs-6">{t("pregrado.coordinacion.t11")}</li>
                        <li className="fs-6">{t("pregrado.coordinacion.t12")}</li>
                    </ul>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                </div>
                <div className="p-3"/>
                <p className="text-start fs-6">{t("pregrado.coordinacion.t13")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6 fw-bold">{t("pregrado.coordinacion.t14")}</p>
            </div>
        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}



export default Coordinacion_SC;