import React from "react";

// Imagenes

import reglamentopdf from "../../pdf/QuintoAño/ReglamentoQuintoAño.pdf";
import { useTranslation } from "react-i18next";
const ReglamentoQuintoAño = () => {
  const [t, i18n]= useTranslation("global");
  return (
    <>
    
      {/* Renderizado hasta pantallas lg */}
      <div className="d-none d-md-block">
        <div className="card ms-4 me-4 border-0">
          <div className="row g-0 ">
            <div className="col-md-5">
                      <div className="card-body position-relative">
                        {/*}Textos{*/}
                        <h1 className="card-title text-center fs-2">{t("pregrado.reglamento.t1")}</h1>
                        <div className="bg-uai position-absolute start-50 translate-middle ms-3 custom-line-l-size"/>
                        <div className="p-3"/>
                        <p className="card-text text-start fs-6">{t("pregrado.reglamento.t2")}</p>
                        <ul>
                            <li className="card-text text-start">{t("pregrado.reglamento.t3")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t4")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t5")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t6")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t7")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t8")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t9")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t10")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t11")}</li>
                        </ul>
                    </div>
                    <div className=" w-50">
                                {/*}Hipervinculos{*/}
                                <div className="d-flex">
                                    <div className="p-1"/>
                                    <a className="btn text-light bg-uai" href="https://alumno.uai.cl/assets/uploads/2022/01/reglamento_2022.pdf">2022</a>
                                    <div className="p-2"/>
                                    <div className="p-2"/>
                                    <a className="btn text-light bg-uai" href="https://alumno.uai.cl/assets/uploads/sites/2/2021/03/reglamento_2021.pdf">2021</a>
                                    <div className="p-2"/>
                                </div>
                            </div>
                    

            </div>
            <div className="col-md-1 vertical-line"></div>
            <div className="col-md-5">
              
              <div className="card-body position-relative">
                {/* Textos */}
                
                <h1 className="card-title text-center fs-2">{t("quinto.reglamento.t1")}</h1>
                <div className="bg-uai position-absolute start-50 translate-middle ms-3 custom-line-l-size" />
                <div className="p-3" />
                <p className="card-text text-start fs-6">
                {t("quinto.reglamento.t2")}
                </p>
                <ul>
                    <li>{t("quinto.reglamento.t3")}</li>
                    <li>{t("quinto.reglamento.t4")}</li>
                    <li>{t("quinto.reglamento.t5")}</li>
                    <li>{t("quinto.reglamento.t6")}</li>
                    <li>{t("quinto.reglamento.t7")}</li>
                    <li>{t("quinto.reglamento.t8")}</li>
                    <li>{t("quinto.reglamento.t9")}</li>
                    <li>{t("quinto.reglamento.t10")}</li>
                    <li>{t("quinto.reglamento.t11")}</li>
                    <li>{t("quinto.reglamento.t12")}</li>
                    <li>{t("quinto.reglamento.t13")}</li>
                    <li>{t("quinto.reglamento.t14")}</li>
                    <li>{t("quinto.reglamento.t15")}</li>
                    <li>{t("quinto.reglamento.t16")}</li>
                    
                </ul>
                <div className="text-center p-3">
                    <a className="btn text-light bg-uai p-2" href={reglamentopdf} download="Reglamento-Quinto-año.pdf">{t("quinto.reglamento.t17")}</a>
                </div>
                

              </div>
            </div>
          </div>
          <div className="p-3" />
        </div>
      </div>
    </>
  );
};

export default ReglamentoQuintoAño;