
//Bibliotecas y componentes
import React from "react";
import Header from "./Shared/Header";
import { Route, Routes } from "react-router-dom";

import Home from "./Home/Home";//importacion componente Home
import Top from "./Shared/Top";//importacion componente Top
import Footer from "./Shared/Footer";//importacion componente Footer
import ReglamentoPregrado from "./Pregrado/ReglamentoPregrado";//importacion componente ReglamentoPregrado
import Normativa from "./Reglamentos/Normativa";//importacion componente Normativa
import CalendarioPregrado from "./Pregrado/CalendarioPregrado";//importacion componente CalendarioPregrado

import Calendario from "./Ambos/Calendario";//importacion componente Calendario
import ReglamentoGeneral from "./Reglamentos/ReglamentoGeneral";//importacion componente ReglamentoQuintoAño
import Condiciones from "./QuintoAño/Condiciones_egreso_titulacion";//importacion componente Condiciones
import Coordinacion_SC from "./QuintoAño/Coordinacion_SC";//importacion componente Coordinacion_SC
import Mencion from "./QuintoAño/Mencion"; // Importacion componente Mención

import MinorPregrado from "./Pregrado/MinorPregrado";//importacion componente MinorPregrado
import TallerPregrado from "./Pregrado/TallerPregrado";//importacion componente TallerPregrado
import CoordinacionPregrado from "./Pregrado/CoordinacionPregrado";//importacion componente CoordinacionPregrado
import CharlasPregrado from "./Pregrado/CharlasPregrado";//importacion componente CharlasPregrado
import PreguntasPregrado from "./Pregrado/PreguntasPregrado";//importacion componente PreguntasPregrado
import Minors from "./Pregrado/Minors"; // importación componente Minors


import ProcedimientosPracticas from "./Practicas/ProcedimientosPracticas";//importancion componente ProcedimientosPracticas
import ReglamentoPracticas from "./Reglamentos/ReglamentoPracticas";//importancion componente ReglamentoPracticas
import PreguntasPracticas from "./Practicas/PreguntasPracticas";//importancion componente PreguntasPracticas 
import ComisionAcreditaciones from "./Acreditaciones/ComisionAcreditaciones";//importancion componente ComisionAcreditaciones
import AcreditacionAcreditaciones from "./Acreditaciones/AcreditacionAcreditaciones";//importancion componente AcreditacionAcreditaciones
import ColegioAcreditaciones from "./Acreditaciones/ColegioAcreditaciones";//importancion componente ColegioAcreditaciones
import CoordinacionInternacionalizacion from "./Internacionalizacion/CoordinacionInternacionalizacion";//importancion componente CoordinacionInternacionalizacion
import PreguntasInternacionalizacion from "./Internacionalizacion/PreguntasInternacionalizacion";//importancion componente PreguntasInternacionalizacion
import PreguntasAsuntos from "./Asuntos/PreguntasAsuntos";//importancion componente PreguntasAsuntos
import InformacionTitulacion from "./Mecanismo_de_Titulacion/InformacionTitulacion";//importancion componente InformacionTitulacion
import EmprendimientoTitulacion from "./Mecanismo_de_Titulacion/EmprendimientoTitulacion";//importancion componente EmprendimientoTitulacion
import MagisterTitulacion from "./Mecanismo_de_Titulacion/MagisterTitulacion";//importancion componente MagisterTitulacion
import MagisterTitulacionInternacional from "./Mecanismo_de_Titulacion/MagisterTitulacionInternacional";//importancion componente MagisterTitulacionInternacional
import PasantiaTitulacion from "./Mecanismo_de_Titulacion/PasantiaTitulacion";//importancion componente PasantiaTitulacion

import PreguntasTitulacion from "./Mecanismo_de_Titulacion/PreguntasTitulacion";//importancion componente PreguntasTitulacion


import Malla2024Q from "./Mallas/Malla2024Q";//importancion componente Malla2024
import Malla2023Q from "./Mallas/Malla2023Q";//importancion componente Malla2023
import Malla2022Q from "./Mallas/Malla2022Q";//importancion componente Malla2022
import Malla2021Q from "./Mallas/Malla2021Q";//importancion componente Malla2021
import Malla2020Q from "./Mallas/Malla2020Q";//importancion componente Malla2020

import Malla2023Pr from "./Mallas/Malla2023Pr";//importancion componente Malla2023
import Malla2022Pr from "./Mallas/Malla2022Pr";//importancion componente Malla2022
import Malla2021Pr from "./Mallas/Malla2021Pr";//importancion componente Malla2021
import Malla2020Pr from "./Mallas/Malla2020Pr";//importancion componente Malla2020
import Malla2019Pr from "./Mallas/Malla2019Pr";//importancion componente Malla2019
import Malla2018Pr from "./Mallas/Malla2018Pr";//importancion componente Malla2018
import Malla2017Pr from "./Mallas/Malla2017Pr";//importancion componente Malla2017
import Malla2016Pr from "./Mallas/Malla2016Pr";//importancion componente Malla2016

import Energia2016 from "./Syllabus/A2016/Energia/Energia2016";//importancion componente Energia2016
import Energia2016S3 from "./Syllabus/A2016/Energia/Energia2016S3";//importancion componente Energia2016
import Energia2016S5 from "./Syllabus/A2016/Energia/Energia2016S5";//importancion componente Energia2016
import Energia2016S7 from "./Syllabus/A2016/Energia/Energia2016S7";//importancion componente Energia2016
import Energia2016S9 from "./Syllabus/A2016/Energia/Energia2016S9";//importancion componente Energia2016

import Bio2016 from "./Syllabus/A2016/Bio/Bio2016";//importancion componente Bio2016
import Bio2016S3 from "./Syllabus/A2016/Bio/Bio2016S3";//importancion componente Bio2016
import Bio2016S5 from "./Syllabus/A2016/Bio/Bio2016S5";//importancion componente Bio2016
import Bio2016S7 from "./Syllabus/A2016/Bio/Bio2016S7";//importancion componente Bio2016
import Bio2016S9 from "./Syllabus/A2016/Bio/Bio2016S9";//importancion componente Bio2016

import Meca2016 from "./Syllabus/A2016/Meca/Meca2016";//importancion componente Meca2016
import Meca2016S3 from "./Syllabus/A2016/Meca/Meca2016S3";//importancion componente Meca2016
import Meca2016S5 from "./Syllabus/A2016/Meca/Meca2016S5";//importancion componente Meca2016
import Meca2016S7 from "./Syllabus/A2016/Meca/Meca2016S7";//importancion componente Meca2016
import Meca2016S9 from "./Syllabus/A2016/Meca/Meca2016S9";//importancion componente Meca2016

import Info2016 from "./Syllabus/A2016/Info/Info2016";//importancion componente Info2016
import Info2016S3 from "./Syllabus/A2016/Info/Info2016S3";//importancion componente Info2016
import Info2016S5 from "./Syllabus/A2016/Info/Info2016S5";//importancion componente Info2016
import Info2016S7 from "./Syllabus/A2016/Info/Info2016S7";//importancion componente Info2016
import Info2016S9 from "./Syllabus/A2016/Info/Info2016S9";//importancion componente Info2016

import Min2016 from "./Syllabus/A2016/Min/Min2016";//importancion componente Min2016
import Min2016S3 from "./Syllabus/A2016/Min/Min2016S3";//importancion componente Min2016
import Min2016S5 from "./Syllabus/A2016/Min/Min2016S5";//importancion componente Min2016
import Min2016S7 from "./Syllabus/A2016/Min/Min2016S7";//importancion componente Min2016
import Min2016S9 from "./Syllabus/A2016/Min/Min2016S9";//importancion componente Min2016

import Obras2016 from "./Syllabus/A2016/Obras/Obras2016";//importancion componente Obras2016
import Obras2016S3 from "./Syllabus/A2016/Obras/Obras2016S3";//importancion componente Obras2016
import Obras2016S5 from "./Syllabus/A2016/Obras/Obras2016S5";//importancion componente Obras2016
import Obras2016S7 from "./Syllabus/A2016/Obras/Obras2016S7";//importancion componente Obras2016
import Obras2016S9 from "./Syllabus/A2016/Obras/Obras2016S9";//importancion componente Obras2016

import Industrial2016 from "./Syllabus/A2016/Industrial/Industrial2016";//importancion componente Industrial2016
import Industrial2016S3 from "./Syllabus/A2016/Industrial/Industrial2016S3";//importancion componente Industrial2016
import Industrial2016S5 from "./Syllabus/A2016/Industrial/Industrial2016S5";//importancion componente Industrial2016
import Industrial2016S7 from "./Syllabus/A2016/Industrial/Industrial2016S7";//importancion componente Industrial2016
import Industrial2016S9 from "./Syllabus/A2016/Industrial/Industrial2016S9";//importancion componente Industrial2016

import Energia2017 from "./Syllabus/A2017/Energia/Energia2017";//importancion componente Energia2017
import Energia2017S3 from "./Syllabus/A2017/Energia/Energia2017S3";//importancion componente Energia2017
import Energia2017S5 from "./Syllabus/A2017/Energia/Energia2017S5";//importancion componente Energia2017
import Energia2017S7 from "./Syllabus/A2017/Energia/Energia2017S7";//importancion componente Energia2017
import Energia2017S9 from "./Syllabus/A2017/Energia/Energia2017S9";//importancion componente Energia2017

import Bio2017 from "./Syllabus/A2017/Bio/Bio2017";//importancion componente Bio2017
import Bio2017S3 from "./Syllabus/A2017/Bio/Bio2017S3";//importancion componente Bio2017
import Bio2017S5 from "./Syllabus/A2017/Bio/Bio2017S5";//importancion componente Bio2017
import Bio2017S7 from "./Syllabus/A2017/Bio/Bio2017S7";//importancion componente Bio2017
import Bio2017S9 from "./Syllabus/A2017/Bio/Bio2017S9";//importancion componente Bio2017

import Meca2017 from "./Syllabus/A2017/Meca/Meca2017";//importancion componente Meca2017
import Meca2017S3 from "./Syllabus/A2017/Meca/Meca2017S3";//importancion componente Meca2017
import Meca2017S5 from "./Syllabus/A2017/Meca/Meca2017S5";//importancion componente Meca2017
import Meca2017S7 from "./Syllabus/A2017/Meca/Meca2017S7";//importancion componente Meca2017
import Meca2017S9 from "./Syllabus/A2017/Meca/Meca2017S9";//importancion componente Meca2017

import Info2017 from "./Syllabus/A2017/Info/Info2017";//importancion componente Info2017
import Info2017S3 from "./Syllabus/A2017/Info/Info2017S3";//importancion componente Info2017
import Info2017S5 from "./Syllabus/A2017/Info/Info2017S5";//importancion componente Info2017
import Info2017S7 from "./Syllabus/A2017/Info/Info2017S7";//importancion componente Info2017
import Info2017S9 from "./Syllabus/A2017/Info/Info2017S9";//importancion componente Info2017

import Min2017 from "./Syllabus/A2017/Min/Min2017";//importancion componente Min2017
import Min2017S3 from "./Syllabus/A2017/Min/Min2017S3";//importancion componente Min2017
import Min2017S5 from "./Syllabus/A2017/Min/Min2017S5";//importancion componente Min2017
import Min2017S7 from "./Syllabus/A2017/Min/Min2017S7";//importancion componente Min2017
import Min2017S9 from "./Syllabus/A2017/Min/Min2017S9";//importancion componente Min2017

import Obras2017 from "./Syllabus/A2017/Obras/Obras2017";//importancion componente Obras2017
import Obras2017S3 from "./Syllabus/A2017/Obras/Obras2017S3";//importancion componente Obras2017
import Obras2017S5 from "./Syllabus/A2017/Obras/Obras2017S5";//importancion componente Obras2017
import Obras2017S7 from "./Syllabus/A2017/Obras/Obras2017S7";//importancion componente Obras2017
import Obras2017S9 from "./Syllabus/A2017/Obras/Obras2017S9";//importancion componente Obras2017

import Industrial2017 from "./Syllabus/A2017/Industrial/Industrial2017";//importancion componente Industrial2017
import Industrial2017S3 from "./Syllabus/A2017/Industrial/Industrial2017S3";//importancion componente Industrial2017
import Industrial2017S5 from "./Syllabus/A2017/Industrial/Industrial2017S5";//importancion componente Industrial2017
import Industrial2017S7 from "./Syllabus/A2017/Industrial/Industrial2017S7";//importancion componente Industrial2017
import Industrial2017S9 from "./Syllabus/A2017/Industrial/Industrial2017S9";//importancion componente Industrial2017

import Energia2018 from "./Syllabus/A2018/Energia/Energia2018";//importancion componente Energia2018
import Energia2018S3 from "./Syllabus/A2018/Energia/Energia2018S3";//importancion componente Energia2018
import Energia2018S5 from "./Syllabus/A2018/Energia/Energia2018S5";//importancion componente Energia2018
import Energia2018S7 from "./Syllabus/A2018/Energia/Energia2018S7";//importancion componente Energia2018
import Energia2018S9 from "./Syllabus/A2018/Energia/Energia2018S9";//importancion componente Energia2018

import Bio2018 from "./Syllabus/A2018/Bio/Bio2018";//importancion componente Bio2018
import Bio2018S3 from "./Syllabus/A2018/Bio/Bio2018S3";//importancion componente Bio2018
import Bio2018S5 from "./Syllabus/A2018/Bio/Bio2018S5";//importancion componente Bio2018
import Bio2018S7 from "./Syllabus/A2018/Bio/Bio2018S7";//importancion componente Bio2018
import Bio2018S9 from "./Syllabus/A2018/Bio/Bio2018S9";//importancion componente Bio2018

import Meca2018 from "./Syllabus/A2018/Meca/Meca2018";//importancion componente Meca2018
import Meca2018S3 from "./Syllabus/A2018/Meca/Meca2018S3";//importancion componente Meca2018
import Meca2018S5 from "./Syllabus/A2018/Meca/Meca2018S5";//importancion componente Meca2018
import Meca2018S7 from "./Syllabus/A2018/Meca/Meca2018S7";//importancion componente Meca2018
import Meca2018S9 from "./Syllabus/A2018/Meca/Meca2018S9";//importancion componente Meca2018

import Info2018 from "./Syllabus/A2018/Info/Info2018";//importancion componente Info2018
import Info2018S3 from "./Syllabus/A2018/Info/Info2018S3";//importancion componente Info2018
import Info2018S5 from "./Syllabus/A2018/Info/Info2018S5";//importancion componente Info2018
import Info2018S7 from "./Syllabus/A2018/Info/Info2018S7";//importancion componente Info2018
import Info2018S9 from "./Syllabus/A2018/Info/Info2018S9";//importancion componente Info2018

import Min2018 from "./Syllabus/A2018/Min/Min2018";//importancion componente Min2018
import Min2018S3 from "./Syllabus/A2018/Min/Min2018S3";//importancion componente Min2018
import Min2018S5 from "./Syllabus/A2018/Min/Min2018S5";//importancion componente Min2018
import Min2018S7 from "./Syllabus/A2018/Min/Min2018S7";//importancion componente Min2018
import Min2018S9 from "./Syllabus/A2018/Min/Min2018S9";//importancion componente Min2018

import Obras2018 from "./Syllabus/A2018/Obras/Obras2018";//importancion componente Obras2018
import Obras2018S3 from "./Syllabus/A2018/Obras/Obras2018S3";//importancion componente Obras2018
import Obras2018S5 from "./Syllabus/A2018/Obras/Obras2018S5";//importancion componente Obras2018
import Obras2018S7 from "./Syllabus/A2018/Obras/Obras2018S7";//importancion componente Obras2018
import Obras2018S9 from "./Syllabus/A2018/Obras/Obras2018S9";//importancion componente Obras2018

import Industrial2018 from "./Syllabus/A2018/Industrial/Industrial2018";//importancion componente Industrial2018
import Industrial2018S3 from "./Syllabus/A2018/Industrial/Industrial2018S3";//importancion componente Industrial2018
import Industrial2018S5 from "./Syllabus/A2018/Industrial/Industrial2018S5";//importancion componente Industrial2018
import Industrial2018S7 from "./Syllabus/A2018/Industrial/Industrial2018S7";//importancion componente Industrial2018
import Industrial2018S9 from "./Syllabus/A2018/Industrial/Industrial2018S9";//importancion componente Industrial2018

import Energia2019 from "./Syllabus/A2019/Energia/Energia2019";//importancion componente Energia2019
import Energia2019S3 from "./Syllabus/A2019/Energia/Energia2019S3";//importancion componente Energia2019
import Energia2019S5 from "./Syllabus/A2019/Energia/Energia2019S5";//importancion componente Energia2019
import Energia2019S7 from "./Syllabus/A2019/Energia/Energia2019S7";//importancion componente Energia2019
import Energia2019S9 from "./Syllabus/A2019/Energia/Energia2019S9";//importancion componente Energia2019

import Bio2019 from "./Syllabus/A2019/Bio/Bio2019";//importancion componente Bio2019
import Bio2019S3 from "./Syllabus/A2019/Bio/Bio2019S3";//importancion componente Bio2019
import Bio2019S5 from "./Syllabus/A2019/Bio/Bio2019S5";//importancion componente Bio2019
import Bio2019S7 from "./Syllabus/A2019/Bio/Bio2019S7";//importancion componente Bio2019
import Bio2019S9 from "./Syllabus/A2019/Bio/Bio2019S9";//importancion componente Bio2019

import Meca2019 from "./Syllabus/A2019/Meca/Meca2019";//importancion componente Meca2019
import Meca2019S3 from "./Syllabus/A2019/Meca/Meca2019S3";//importancion componente Meca2019
import Meca2019S5 from "./Syllabus/A2019/Meca/Meca2019S5";//importancion componente Meca2019
import Meca2019S7 from "./Syllabus/A2019/Meca/Meca2019S7";//importancion componente Meca2019
import Meca2019S9 from "./Syllabus/A2019/Meca/Meca2019S9";//importancion componente Meca2019

import Info2019 from "./Syllabus/A2019/Info/Info2019";//importancion componente Info2019
import Info2019S3 from "./Syllabus/A2019/Info/Info2019S3";//importancion componente Info2019
import Info2019S5 from "./Syllabus/A2019/Info/Info2019S5";//importancion componente Info2019
import Info2019S7 from "./Syllabus/A2019/Info/Info2019S7";//importancion componente Info2019
import Info2019S9 from "./Syllabus/A2019/Info/Info2019S9";//importancion componente Info2019

import Min2019 from "./Syllabus/A2019/Min/Min2019";//importancion componente Min2019
import Min2019S3 from "./Syllabus/A2019/Min/Min2019S3";//importancion componente Min2019
import Min2019S5 from "./Syllabus/A2019/Min/Min2019S5";//importancion componente Min2019
import Min2019S7 from "./Syllabus/A2019/Min/Min2019S7";//importancion componente Min2019
import Min2019S9 from "./Syllabus/A2019/Min/Min2019S9";//importancion componente Min2019

import Obras2019 from "./Syllabus/A2019/Obras/Obras2019";//importancion componente Obras2019
import Obras2019S3 from "./Syllabus/A2019/Obras/Obras2019S3";//importancion componente Obras2019
import Obras2019S5 from "./Syllabus/A2019/Obras/Obras2019S5";//importancion componente Obras2019
import Obras2019S7 from "./Syllabus/A2019/Obras/Obras2019S7";//importancion componente Obras2019
import Obras2019S9 from "./Syllabus/A2019/Obras/Obras2019S9";//importancion componente Obras2019

import Industrial2019 from "./Syllabus/A2019/Industrial/Industrial2019";//importancion componente Industrial2019
import Industrial2019S3 from "./Syllabus/A2019/Industrial/Industrial2019S3";//importancion componente Industrial2019
import Industrial2019S5 from "./Syllabus/A2019/Industrial/Industrial2019S5";//importancion componente Industrial2019
import Industrial2019S7 from "./Syllabus/A2019/Industrial/Industrial2019S7";//importancion componente Industrial2019
import Industrial2019S9 from "./Syllabus/A2019/Industrial/Industrial2019S9";//importancion componente Industrial2019

import Energia2020 from "./Syllabus/A2020/Energia/Energia2020";//importancion componente Energia2020
import Energia2020S3 from "./Syllabus/A2020/Energia/Energia2020S3";//importancion componente Energia2020
import Energia2020S5 from "./Syllabus/A2020/Energia/Energia2020S5";//importancion componente Energia2020
import Energia2020S7 from "./Syllabus/A2020/Energia/Energia2020S7";//importancion componente Energia2020
import Energia2020S9 from "./Syllabus/A2020/Energia/Energia2020S9";//importancion componente Energia2020

import Bio2020 from "./Syllabus/A2020/Bio/Bio2020";//importancion componente Bio2020
import Bio2020S3 from "./Syllabus/A2020/Bio/Bio2020S3";//importancion componente Bio2020
import Bio2020S5 from "./Syllabus/A2020/Bio/Bio2020S5";//importancion componente Bio2020
import Bio2020S7 from "./Syllabus/A2020/Bio/Bio2020S7";//importancion componente Bio2020
import Bio2020S9 from "./Syllabus/A2020/Bio/Bio2020S9";//importancion componente Bio2020

import Meca2020 from "./Syllabus/A2020/Meca/Meca2020";//importancion componente Meca2020
import Meca2020S3 from "./Syllabus/A2020/Meca/Meca2020S3";//importancion componente Meca2020
import Meca2020S5 from "./Syllabus/A2020/Meca/Meca2020S5";//importancion componente Meca2020
import Meca2020S7 from "./Syllabus/A2020/Meca/Meca2020S7";//importancion componente Meca2020
import Meca2020S9 from "./Syllabus/A2020/Meca/Meca2020S9";//importancion componente Meca2020

import Info2020 from "./Syllabus/A2020/Info/Info2020";//importancion componente Info2020
import Info2020S3 from "./Syllabus/A2020/Info/Info2020S3";//importancion componente Info2020
import Info2020S5 from "./Syllabus/A2020/Info/Info2020S5";//importancion componente Info2020
import Info2020S7 from "./Syllabus/A2020/Info/Info2020S7";//importancion componente Info2020
import Info2020S9 from "./Syllabus/A2020/Info/Info2020S9";//importancion componente Info2020

import Min2020 from "./Syllabus/A2020/Min/Min2020";//importancion componente Min2020
import Min2020S3 from "./Syllabus/A2020/Min/Min2020S3";//importancion componente Min2020
import Min2020S5 from "./Syllabus/A2020/Min/Min2020S5";//importancion componente Min2020
import Min2020S7 from "./Syllabus/A2020/Min/Min2020S7";//importancion componente Min2020
import Min2020S9 from "./Syllabus/A2020/Min/Min2020S9";//importancion componente Min2020

import Obras2020 from "./Syllabus/A2020/Obras/Obras2020";//importancion componente Obras2020
import Obras2020S3 from "./Syllabus/A2020/Obras/Obras2020S3";//importancion componente Obras2020
import Obras2020S5 from "./Syllabus/A2020/Obras/Obras2020S5";//importancion componente Obras2020
import Obras2020S7 from "./Syllabus/A2020/Obras/Obras2020S7";//importancion componente Obras2020
import Obras2020S9 from "./Syllabus/A2020/Obras/Obras2020S9";//importancion componente Obras2020

import Industrial2020 from "./Syllabus/A2020/Industrial/Industrial2020";//importancion componente Industrial2020
import Industrial2020S3 from "./Syllabus/A2020/Industrial/Industrial2020S3";//importancion componente Industrial2020
import Industrial2020S5 from "./Syllabus/A2020/Industrial/Industrial2020S5";//importancion componente Industrial2020
import Industrial2020S7 from "./Syllabus/A2020/Industrial/Industrial2020S7";//importancion componente Industrial2020
import Industrial2020S9 from "./Syllabus/A2020/Industrial/Industrial2020S9";//importancion componente Industrial2020

import Energia2021 from "./Syllabus/A2021/Energia/Energia2021";//importancion componente Energia2021
import Energia2021S3 from "./Syllabus/A2021/Energia/Energia2021S3";//importancion componente Energia2021
import Energia2021S5 from "./Syllabus/A2021/Energia/Energia2021S5";//importancion componente Energia2021
import Energia2021S7 from "./Syllabus/A2021/Energia/Energia2021S7";//importancion componente Energia2021
import Energia2021S9 from "./Syllabus/A2021/Energia/Energia2021S9";//importancion componente Energia2021

import Bio2021 from "./Syllabus/A2021/Bio/Bio2021";//importancion componente Bio2021
import Bio2021S3 from "./Syllabus/A2021/Bio/Bio2021S3";//importancion componente Bio2021
import Bio2021S5 from "./Syllabus/A2021/Bio/Bio2021S5";//importancion componente Bio2021
import Bio2021S7 from "./Syllabus/A2021/Bio/Bio2021S7";//importancion componente Bio2021
import Bio2021S9 from "./Syllabus/A2021/Bio/Bio2021S9";//importancion componente Bio2021

import Meca2021 from "./Syllabus/A2021/Meca/Meca2021";//importancion componente Meca2021
import Meca2021S3 from "./Syllabus/A2021/Meca/Meca2021S3";//importancion componente Meca2021
import Meca2021S5 from "./Syllabus/A2021/Meca/Meca2021S5";//importancion componente Meca2021
import Meca2021S7 from "./Syllabus/A2021/Meca/Meca2021S7";//importancion componente Meca2021
import Meca2021S9 from "./Syllabus/A2021/Meca/Meca2021S9";//importancion componente Meca2021

import Info2021 from "./Syllabus/A2021/Info/Info2021";//importancion componente Info2021
import Info2021S3 from "./Syllabus/A2021/Info/Info2021S3";//importancion componente Info2021
import Info2021S5 from "./Syllabus/A2021/Info/Info2021S5";//importancion componente Info2021
import Info2021S7 from "./Syllabus/A2021/Info/Info2021S7";//importancion componente Info2021
import Info2021S9 from "./Syllabus/A2021/Info/Info2021S9";//importancion componente Info2021

import Min2021 from "./Syllabus/A2021/Min/Min2021";//importancion componente Min2021
import Min2021S3 from "./Syllabus/A2021/Min/Min2021S3";//importancion componente Min2021
import Min2021S5 from "./Syllabus/A2021/Min/Min2021S5";//importancion componente Min2021
import Min2021S7 from "./Syllabus/A2021/Min/Min2021S7";//importancion componente Min2021
import Min2021S9 from "./Syllabus/A2021/Min/Min2021S9";//importancion componente Min2021

import Obras2021 from "./Syllabus/A2021/Obras/Obras2021";//importancion componente Obras2021
import Obras2021S3 from "./Syllabus/A2021/Obras/Obras2021S3";//importancion componente Obras2021
import Obras2021S5 from "./Syllabus/A2021/Obras/Obras2021S5";//importancion componente Obras2021
import Obras2021S7 from "./Syllabus/A2021/Obras/Obras2021S7";//importancion componente Obras2021
import Obras2021S9 from "./Syllabus/A2021/Obras/Obras2021S9";//importancion componente Obras2021

import Industrial2021 from "./Syllabus/A2021/Industrial/Industrial2021";//importancion componente Industrial2021
import Industrial2021S3 from "./Syllabus/A2021/Industrial/Industrial2021S3";//importancion componente Industrial2021
import Industrial2021S5 from "./Syllabus/A2021/Industrial/Industrial2021S5";//importancion componente Industrial2021
import Industrial2021S7 from "./Syllabus/A2021/Industrial/Industrial2021S7";//importancion componente Industrial2021
import Industrial2021S9 from "./Syllabus/A2021/Industrial/Industrial2021S9";//importancion componente Industrial2021

import Energia2022 from "./Syllabus/A2022/Energia/Energia2022";//importancion componente Energia2022
import Energia2022S3 from "./Syllabus/A2022/Energia/Energia2022S3";//importancion componente Energia2022
import Energia2022S5 from "./Syllabus/A2022/Energia/Energia2022S5";//importancion componente Energia2022
import Energia2022S7 from "./Syllabus/A2022/Energia/Energia2022S7";//importancion componente Energia2022
import Energia2022S9 from "./Syllabus/A2022/Energia/Energia2022S9";//importancion componente Energia2022

import Bio2022 from "./Syllabus/A2022/Bio/Bio2022";//importancion componente Bio2022
import Bio2022S3 from "./Syllabus/A2022/Bio/Bio2022S3";//importancion componente Bio2022
import Bio2022S5 from "./Syllabus/A2022/Bio/Bio2022S5";//importancion componente Bio2022
import Bio2022S7 from "./Syllabus/A2022/Bio/Bio2022S7";//importancion componente Bio2022
import Bio2022S9 from "./Syllabus/A2022/Bio/Bio2022S9";//importancion componente Bio2022

import Meca2022 from "./Syllabus/A2022/Meca/Meca2022";//importancion componente Meca2022
import Meca2022S3 from "./Syllabus/A2022/Meca/Meca2022S3";//importancion componente Meca2022
import Meca2022S5 from "./Syllabus/A2022/Meca/Meca2022S5";//importancion componente Meca2022
import Meca2022S7 from "./Syllabus/A2022/Meca/Meca2022S7";//importancion componente Meca2022
import Meca2022S9 from "./Syllabus/A2022/Meca/Meca2022S9";//importancion componente Meca2022

import Info2022 from "./Syllabus/A2022/Info/Info2022";//importancion componente Info2022
import Info2022S3 from "./Syllabus/A2022/Info/Info2022S3";//importancion componente Info2022
import Info2022S5 from "./Syllabus/A2022/Info/Info2022S5";//importancion componente Info2022
import Info2022S7 from "./Syllabus/A2022/Info/Info2022S7";//importancion componente Info2022
import Info2022S9 from "./Syllabus/A2022/Info/Info2022S9";//importancion componente Info2022

import Min2022 from "./Syllabus/A2022/Min/Min2022";//importancion componente Min2022
import Min2022S3 from "./Syllabus/A2022/Min/Min2022S3";//importancion componente Min2022
import Min2022S5 from "./Syllabus/A2022/Min/Min2022S5";//importancion componente Min2022
import Min2022S7 from "./Syllabus/A2022/Min/Min2022S7";//importancion componente Min2022
import Min2022S9 from "./Syllabus/A2022/Min/Min2022S9";//importancion componente Min2022

import Obras2022 from "./Syllabus/A2022/Obras/Obras2022";//importancion componente Obras2022
import Obras2022S3 from "./Syllabus/A2022/Obras/Obras2022S3";//importancion componente Obras2022
import Obras2022S5 from "./Syllabus/A2022/Obras/Obras2022S5";//importancion componente Obras2022
import Obras2022S7 from "./Syllabus/A2022/Obras/Obras2022S7";//importancion componente Obras2022
import Obras2022S9 from "./Syllabus/A2022/Obras/Obras2022S9";//importancion componente Obras2022

import Industrial2022 from "./Syllabus/A2022/Industrial/Industrial2022";//importancion componente Industrial2022
import Industrial2022S3 from "./Syllabus/A2022/Industrial/Industrial2022S3";//importancion componente Industrial2022
import Industrial2022S5 from "./Syllabus/A2022/Industrial/Industrial2022S5";//importancion componente Industrial2022
import Industrial2022S7 from "./Syllabus/A2022/Industrial/Industrial2022S7";//importancion componente Industrial2022
import Industrial2022S9 from "./Syllabus/A2022/Industrial/Industrial2022S9";//importancion componente Industrial2022

import Energia2023 from "./Syllabus/A2023/Energia/Energia2023";//importancion componente Energia2023
import Energia2023S3 from "./Syllabus/A2023/Energia/Energia2023S3";//importancion componente Energia2023
import Energia2023S5 from "./Syllabus/A2023/Energia/Energia2023S5";//importancion componente Energia2023
import Energia2023S7 from "./Syllabus/A2023/Energia/Energia2023S7";//importancion componente Energia2023
import Energia2023S9 from "./Syllabus/A2023/Energia/Energia2023S9";//importancion componente Energia2023

import Bio2023 from "./Syllabus/A2023/Bio/Bio2023";//importancion componente Bio2023
import Bio2023S3 from "./Syllabus/A2023/Bio/Bio2023S3";//importancion componente Bio2023
import Bio2023S5 from "./Syllabus/A2023/Bio/Bio2023S5";//importancion componente Bio2023
import Bio2023S7 from "./Syllabus/A2023/Bio/Bio2023S7";//importancion componente Bio2023
import Bio2023S9 from "./Syllabus/A2023/Bio/Bio2023S9";//importancion componente Bio2023

import Meca2023 from "./Syllabus/A2023/Meca/Meca2023";//importancion componente Meca2023
import Meca2023S3 from "./Syllabus/A2023/Meca/Meca2023S3";//importancion componente Meca2023
import Meca2023S5 from "./Syllabus/A2023/Meca/Meca2023S5";//importancion componente Meca2023
import Meca2023S7 from "./Syllabus/A2023/Meca/Meca2023S7";//importancion componente Meca2023
import Meca2023S9 from "./Syllabus/A2023/Meca/Meca2023S9";//importancion componente Meca2023

import Info2023 from "./Syllabus/A2023/Info/Info2023";//importancion componente Info2023
import Info2023S3 from "./Syllabus/A2023/Info/Info2023S3";//importancion componente Info2023
import Info2023S5 from "./Syllabus/A2023/Info/Info2023S5";//importancion componente Info2023
import Info2023S7 from "./Syllabus/A2023/Info/Info2023S7";//importancion componente Info2023
import Info2023S9 from "./Syllabus/A2023/Info/Info2023S9";//importancion componente Info2023

import Min2023 from "./Syllabus/A2023/Min/Min2023";//importancion componente Min2023
import Min2023S3 from "./Syllabus/A2023/Min/Min2023S3";//importancion componente Min2023
import Min2023S5 from "./Syllabus/A2023/Min/Min2023S5";//importancion componente Min2023
import Min2023S7 from "./Syllabus/A2023/Min/Min2023S7";//importancion componente Min2023
import Min2023S9 from "./Syllabus/A2023/Min/Min2023S9";//importancion componente Min2023

import Obras2023 from "./Syllabus/A2023/Obras/Obras2023";//importancion componente Obras2023
import Obras2023S3 from "./Syllabus/A2023/Obras/Obras2023S3";//importancion componente Obras2023
import Obras2023S5 from "./Syllabus/A2023/Obras/Obras2023S5";//importancion componente Obras2023
import Obras2023S7 from "./Syllabus/A2023/Obras/Obras2023S7";//importancion componente Obras2023
import Obras2023S9 from "./Syllabus/A2023/Obras/Obras2023S9";//importancion componente Obras2023

import Industrial2023 from "./Syllabus/A2023/Industrial/Industrial2023";//importancion componente Industrial2023
import Industrial2023S3 from "./Syllabus/A2023/Industrial/Industrial2023S3";//importancion componente Industrial2023
import Industrial2023S5 from "./Syllabus/A2023/Industrial/Industrial2023S5";//importancion componente Industrial2023
import Industrial2023S7 from "./Syllabus/A2023/Industrial/Industrial2023S7";//importancion componente Industrial2023
import Industrial2023S9 from "./Syllabus/A2023/Industrial/Industrial2023S9";//importancion componente Industrial2023


const App = () => {
  return (
    <div
      className="App">
        {/*}Renderizado componente Top{*/}
        <Header/>{/*}Renderizado componente Header{*/}
      <Routes>{/*}Rutas{*/}
        <Route path="/" element={<Home />}/>
        <Route path="/Energia2016" element={<Energia2016/>}/>{/*}Ruta para /Energia2016{*/}
        <Route path="/Energia2016S3" element={<Energia2016S3/>}/>{/*}Ruta para /Energia2016{*/}
        <Route path="/Energia2016S5" element={<Energia2016S5/>}/>{/*}Ruta para /Energia2016{*/}
        <Route path="/Energia2016S7" element={<Energia2016S7/>}/>{/*}Ruta para /Energia2016{*/}
        <Route path="/Energia2016S9" element={<Energia2016S9/>}/>{/*}Ruta para /Energia2016{*/}

        <Route path="/Bio2016" element={<Bio2016/>}/>{/*}Ruta para /Bio2016{*/}
        <Route path="/Bio2016S3" element={<Bio2016S3/>}/>{/*}Ruta para /Bio2016{*/}
        <Route path="/Bio2016S5" element={<Bio2016S5/>}/>{/*}Ruta para /Bio2016{*/}
        <Route path="/Bio2016S7" element={<Bio2016S7/>}/>{/*}Ruta para /Bio2016{*/}
        <Route path="/Bio2016S9" element={<Bio2016S9/>}/>{/*}Ruta para /Bio2016{*/}

        <Route path="/Meca2016" element={<Meca2016/>}/>{/*}Ruta para /Meca2016{*/}
        <Route path="/Meca2016S3" element={<Meca2016S3/>}/>{/*}Ruta para /Meca2016{*/}
        <Route path="/Meca2016S5" element={<Meca2016S5/>}/>{/*}Ruta para /Meca2016{*/}
        <Route path="/Meca2016S7" element={<Meca2016S7/>}/>{/*}Ruta para /Meca2016{*/}
        <Route path="/Meca2016S9" element={<Meca2016S9/>}/>{/*}Ruta para /Meca2016{*/}

        <Route path="/Obras2016" element={<Obras2016/>}/>{/*}Ruta para /Obras2016{*/}
        <Route path="/Obras2016S3" element={<Obras2016S3/>}/>{/*}Ruta para /Obras2016{*/}
        <Route path="/Obras2016S5" element={<Obras2016S5/>}/>{/*}Ruta para /Obras2016{*/}
        <Route path="/Obras2016S7" element={<Obras2016S7/>}/>{/*}Ruta para /Obras2016{*/}
        <Route path="/Obras2016S9" element={<Obras2016S9/>}/>{/*}Ruta para /Obras2016{*/}

        <Route path="/Info2016" element={<Info2016/>}/>{/*}Ruta para /Info2016{*/}
        <Route path="/Info2016S3" element={<Info2016S3/>}/>{/*}Ruta para /Info2016{*/}
        <Route path="/Info2016S5" element={<Info2016S5/>}/>{/*}Ruta para /Info2016{*/}
        <Route path="/Info2016S7" element={<Info2016S7/>}/>{/*}Ruta para /Info2016{*/}
        <Route path="/Info2016S9" element={<Info2016S9/>}/>{/*}Ruta para /Info2016{*/}

        <Route path="/Min2016" element={<Min2016/>}/>{/*}Ruta para /Min2016{*/}
        <Route path="/Min2016S3" element={<Min2016S3/>}/>{/*}Ruta para /Min2016{*/}
        <Route path="/Min2016S5" element={<Min2016S5/>}/>{/*}Ruta para /Min2016{*/}
        <Route path="/Min2016S7" element={<Min2016S7/>}/>{/*}Ruta para /Min2016{*/}
        <Route path="/Min2016S9" element={<Min2016S9/>}/>{/*}Ruta para /Min2016{*/}

        <Route path="/Industrial2016" element={<Industrial2016/>}/>{/*}Ruta para /Industrial2016{*/}
        <Route path="/Industrial2016S3" element={<Industrial2016S3/>}/>{/*}Ruta para /Industrial2016{*/}
        <Route path="/Industrial2016S5" element={<Industrial2016S5/>}/>{/*}Ruta para /Industrial2016{*/}
        <Route path="/Industrial2016S7" element={<Industrial2016S7/>}/>{/*}Ruta para /Industrial2016{*/}
        <Route path="/Industrial2016S9" element={<Industrial2016S9/>}/>{/*}Ruta para /Industrial2016{*/}

        <Route path="/Energia2017" element={<Energia2017/>}/>{/*}Ruta para /Energia2017{*/}
        <Route path="/Energia2017S3" element={<Energia2017S3/>}/>{/*}Ruta para /Energia2017{*/}
        <Route path="/Energia2017S5" element={<Energia2017S5/>}/>{/*}Ruta para /Energia2017{*/}
        <Route path="/Energia2017S7" element={<Energia2017S7/>}/>{/*}Ruta para /Energia2017{*/}
        <Route path="/Energia2017S9" element={<Energia2017S9/>}/>{/*}Ruta para /Energia2017{*/}

        <Route path="/Bio2017" element={<Bio2017/>}/>{/*}Ruta para /Bio2017{*/}
        <Route path="/Bio2017S3" element={<Bio2017S3/>}/>{/*}Ruta para /Bio2017{*/}
        <Route path="/Bio2017S5" element={<Bio2017S5/>}/>{/*}Ruta para /Bio2017{*/}
        <Route path="/Bio2017S7" element={<Bio2017S7/>}/>{/*}Ruta para /Bio2017{*/}
        <Route path="/Bio2017S9" element={<Bio2017S9/>}/>{/*}Ruta para /Bio2017{*/}

        <Route path="/Meca2017" element={<Meca2017/>}/>{/*}Ruta para /Meca2017{*/}
        <Route path="/Meca2017S3" element={<Meca2017S3/>}/>{/*}Ruta para /Meca2017{*/}
        <Route path="/Meca2017S5" element={<Meca2017S5/>}/>{/*}Ruta para /Meca2017{*/}
        <Route path="/Meca2017S7" element={<Meca2017S7/>}/>{/*}Ruta para /Meca2017{*/}
        <Route path="/Meca2017S9" element={<Meca2017S9/>}/>{/*}Ruta para /Meca2017{*/}

        <Route path="/Obras2017" element={<Obras2017/>}/>{/*}Ruta para /Obras2017{*/}
        <Route path="/Obras2017S3" element={<Obras2017S3/>}/>{/*}Ruta para /Obras2017{*/}
        <Route path="/Obras2017S5" element={<Obras2017S5/>}/>{/*}Ruta para /Obras2017{*/}
        <Route path="/Obras2017S7" element={<Obras2017S7/>}/>{/*}Ruta para /Obras2017{*/}
        <Route path="/Obras2017S9" element={<Obras2017S9/>}/>{/*}Ruta para /Obras2017{*/}

        <Route path="/Info2017" element={<Info2017/>}/>{/*}Ruta para /Info2017{*/}
        <Route path="/Info2017S3" element={<Info2017S3/>}/>{/*}Ruta para /Info2017{*/}
        <Route path="/Info2017S5" element={<Info2017S5/>}/>{/*}Ruta para /Info2017{*/}
        <Route path="/Info2017S7" element={<Info2017S7/>}/>{/*}Ruta para /Info2017{*/}
        <Route path="/Info2017S9" element={<Info2017S9/>}/>{/*}Ruta para /Info2017{*/}

        <Route path="/Min2017" element={<Min2017/>}/>{/*}Ruta para /Min2017{*/}
        <Route path="/Min2017S3" element={<Min2017S3/>}/>{/*}Ruta para /Min2017{*/}
        <Route path="/Min2017S5" element={<Min2017S5/>}/>{/*}Ruta para /Min2017{*/}
        <Route path="/Min2017S7" element={<Min2017S7/>}/>{/*}Ruta para /Min2017{*/}
        <Route path="/Min2017S9" element={<Min2017S9/>}/>{/*}Ruta para /Min2017{*/}

        <Route path="/Industrial2017" element={<Industrial2017/>}/>{/*}Ruta para /Industrial2017{*/}
        <Route path="/Industrial2017S3" element={<Industrial2017S3/>}/>{/*}Ruta para /Industrial2017{*/}
        <Route path="/Industrial2017S5" element={<Industrial2017S5/>}/>{/*}Ruta para /Industrial2017{*/}
        <Route path="/Industrial2017S7" element={<Industrial2017S7/>}/>{/*}Ruta para /Industrial2017{*/}
        <Route path="/Industrial2017S9" element={<Industrial2017S9/>}/>{/*}Ruta para /Industrial2017{*/}

        <Route path="/Energia2018" element={<Energia2018/>}/>{/*}Ruta para /Energia2018{*/}
        <Route path="/Energia2018S3" element={<Energia2018S3/>}/>{/*}Ruta para /Energia2018{*/}
        <Route path="/Energia2018S5" element={<Energia2018S5/>}/>{/*}Ruta para /Energia2018{*/}
        <Route path="/Energia2018S7" element={<Energia2018S7/>}/>{/*}Ruta para /Energia2018{*/}
        <Route path="/Energia2018S9" element={<Energia2018S9/>}/>{/*}Ruta para /Energia2018{*/}

        <Route path="/Bio2018" element={<Bio2018/>}/>{/*}Ruta para /Bio2018{*/}
        <Route path="/Bio2018S3" element={<Bio2018S3/>}/>{/*}Ruta para /Bio2018{*/}
        <Route path="/Bio2018S5" element={<Bio2018S5/>}/>{/*}Ruta para /Bio2018{*/}
        <Route path="/Bio2018S7" element={<Bio2018S7/>}/>{/*}Ruta para /Bio2018{*/}
        <Route path="/Bio2018S9" element={<Bio2018S9/>}/>{/*}Ruta para /Bio2018{*/}

        <Route path="/Meca2018" element={<Meca2018/>}/>{/*}Ruta para /Meca2018{*/}
        <Route path="/Meca2018S3" element={<Meca2018S3/>}/>{/*}Ruta para /Meca2018{*/}
        <Route path="/Meca2018S5" element={<Meca2018S5/>}/>{/*}Ruta para /Meca2018{*/}
        <Route path="/Meca2018S7" element={<Meca2018S7/>}/>{/*}Ruta para /Meca2018{*/}
        <Route path="/Meca2018S9" element={<Meca2018S9/>}/>{/*}Ruta para /Meca2018{*/}

        <Route path="/Obras2018" element={<Obras2018/>}/>{/*}Ruta para /Obras2018{*/}
        <Route path="/Obras2018S3" element={<Obras2018S3/>}/>{/*}Ruta para /Obras2018{*/}
        <Route path="/Obras2018S5" element={<Obras2018S5/>}/>{/*}Ruta para /Obras2018{*/}
        <Route path="/Obras2018S7" element={<Obras2018S7/>}/>{/*}Ruta para /Obras2018{*/}
        <Route path="/Obras2018S9" element={<Obras2018S9/>}/>{/*}Ruta para /Obras2018{*/}

        <Route path="/Info2018" element={<Info2018/>}/>{/*}Ruta para /Info2018{*/}
        <Route path="/Info2018S3" element={<Info2018S3/>}/>{/*}Ruta para /Info2018{*/}
        <Route path="/Info2018S5" element={<Info2018S5/>}/>{/*}Ruta para /Info2018{*/}
        <Route path="/Info2018S7" element={<Info2018S7/>}/>{/*}Ruta para /Info2018{*/}
        <Route path="/Info2018S9" element={<Info2018S9/>}/>{/*}Ruta para /Info2018{*/}

        <Route path="/Min2018" element={<Min2018/>}/>{/*}Ruta para /Min2018{*/}
        <Route path="/Min2018S3" element={<Min2018S3/>}/>{/*}Ruta para /Min2018{*/}
        <Route path="/Min2018S5" element={<Min2018S5/>}/>{/*}Ruta para /Min2018{*/}
        <Route path="/Min2018S7" element={<Min2018S7/>}/>{/*}Ruta para /Min2018{*/}
        <Route path="/Min2018S9" element={<Min2018S9/>}/>{/*}Ruta para /Min2018{*/}

        <Route path="/Industrial2018" element={<Industrial2018/>}/>{/*}Ruta para /Industrial2018{*/}
        <Route path="/Industrial2018S3" element={<Industrial2018S3/>}/>{/*}Ruta para /Industrial2018{*/}
        <Route path="/Industrial2018S5" element={<Industrial2018S5/>}/>{/*}Ruta para /Industrial2018{*/}
        <Route path="/Industrial2018S7" element={<Industrial2018S7/>}/>{/*}Ruta para /Industrial2018{*/}
        <Route path="/Industrial2018S9" element={<Industrial2018S9/>}/>{/*}Ruta para /Industrial2018{*/}

        <Route path="/Energia2019" element={<Energia2019/>}/>{/*}Ruta para /Energia2019{*/}
        <Route path="/Energia2019S3" element={<Energia2019S3/>}/>{/*}Ruta para /Energia2019{*/}
        <Route path="/Energia2019S5" element={<Energia2019S5/>}/>{/*}Ruta para /Energia2019{*/}
        <Route path="/Energia2019S7" element={<Energia2019S7/>}/>{/*}Ruta para /Energia2019{*/}
        <Route path="/Energia2019S9" element={<Energia2019S9/>}/>{/*}Ruta para /Energia2019{*/}

        <Route path="/Bio2019" element={<Bio2019/>}/>{/*}Ruta para /Bio2019{*/}
        <Route path="/Bio2019S3" element={<Bio2019S3/>}/>{/*}Ruta para /Bio2019{*/}
        <Route path="/Bio2019S5" element={<Bio2019S5/>}/>{/*}Ruta para /Bio2019{*/}
        <Route path="/Bio2019S7" element={<Bio2019S7/>}/>{/*}Ruta para /Bio2019{*/}
        <Route path="/Bio2019S9" element={<Bio2019S9/>}/>{/*}Ruta para /Bio2019{*/}

        <Route path="/Meca2019" element={<Meca2019/>}/>{/*}Ruta para /Meca2019{*/}
        <Route path="/Meca2019S3" element={<Meca2019S3/>}/>{/*}Ruta para /Meca2019{*/}
        <Route path="/Meca2019S5" element={<Meca2019S5/>}/>{/*}Ruta para /Meca2019{*/}
        <Route path="/Meca2019S7" element={<Meca2019S7/>}/>{/*}Ruta para /Meca2019{*/}
        <Route path="/Meca2019S9" element={<Meca2019S9/>}/>{/*}Ruta para /Meca2019{*/}

        <Route path="/Obras2019" element={<Obras2019/>}/>{/*}Ruta para /Obras2019{*/}
        <Route path="/Obras2019S3" element={<Obras2019S3/>}/>{/*}Ruta para /Obras2019{*/}
        <Route path="/Obras2019S5" element={<Obras2019S5/>}/>{/*}Ruta para /Obras2019{*/}
        <Route path="/Obras2019S7" element={<Obras2019S7/>}/>{/*}Ruta para /Obras2019{*/}
        <Route path="/Obras2019S9" element={<Obras2019S9/>}/>{/*}Ruta para /Obras2019{*/}

        <Route path="/Info2019" element={<Info2019/>}/>{/*}Ruta para /Info2019{*/}
        <Route path="/Info2019S3" element={<Info2019S3/>}/>{/*}Ruta para /Info2019{*/}
        <Route path="/Info2019S5" element={<Info2019S5/>}/>{/*}Ruta para /Info2019{*/}
        <Route path="/Info2019S7" element={<Info2019S7/>}/>{/*}Ruta para /Info2019{*/}
        <Route path="/Info2019S9" element={<Info2019S9/>}/>{/*}Ruta para /Info2019{*/}

        <Route path="/Min2019" element={<Min2019/>}/>{/*}Ruta para /Min2019{*/}
        <Route path="/Min2019S3" element={<Min2019S3/>}/>{/*}Ruta para /Min2019{*/}
        <Route path="/Min2019S5" element={<Min2019S5/>}/>{/*}Ruta para /Min2019{*/}
        <Route path="/Min2019S7" element={<Min2019S7/>}/>{/*}Ruta para /Min2019{*/}
        <Route path="/Min2019S9" element={<Min2019S9/>}/>{/*}Ruta para /Min2019{*/}

        <Route path="/Industrial2019" element={<Industrial2019/>}/>{/*}Ruta para /Industrial2019{*/}
        <Route path="/Industrial2019S3" element={<Industrial2019S3/>}/>{/*}Ruta para /Industrial2019{*/}
        <Route path="/Industrial2019S5" element={<Industrial2019S5/>}/>{/*}Ruta para /Industrial2019{*/}
        <Route path="/Industrial2019S7" element={<Industrial2019S7/>}/>{/*}Ruta para /Industrial2019{*/}
        <Route path="/Industrial2019S9" element={<Industrial2019S9/>}/>{/*}Ruta para /Industrial2019{*/}

        <Route path="/Energia2020" element={<Energia2020/>}/>{/*}Ruta para /Energia2020{*/}
        <Route path="/Energia2020S3" element={<Energia2020S3/>}/>{/*}Ruta para /Energia2020{*/}
        <Route path="/Energia2020S5" element={<Energia2020S5/>}/>{/*}Ruta para /Energia2020{*/}
        <Route path="/Energia2020S7" element={<Energia2020S7/>}/>{/*}Ruta para /Energia2020{*/}
        <Route path="/Energia2020S9" element={<Energia2020S9/>}/>{/*}Ruta para /Energia2020{*/}

        <Route path="/Bio2020" element={<Bio2020/>}/>{/*}Ruta para /Bio2020{*/}
        <Route path="/Bio2020S3" element={<Bio2020S3/>}/>{/*}Ruta para /Bio2020{*/}
        <Route path="/Bio2020S5" element={<Bio2020S5/>}/>{/*}Ruta para /Bio2020{*/}
        <Route path="/Bio2020S7" element={<Bio2020S7/>}/>{/*}Ruta para /Bio2020{*/}
        <Route path="/Bio2020S9" element={<Bio2020S9/>}/>{/*}Ruta para /Bio2020{*/}

        <Route path="/Meca2020" element={<Meca2020/>}/>{/*}Ruta para /Meca2020{*/}
        <Route path="/Meca2020S3" element={<Meca2020S3/>}/>{/*}Ruta para /Meca2020{*/}
        <Route path="/Meca2020S5" element={<Meca2020S5/>}/>{/*}Ruta para /Meca2020{*/}
        <Route path="/Meca2020S7" element={<Meca2020S7/>}/>{/*}Ruta para /Meca2020{*/}
        <Route path="/Meca2020S9" element={<Meca2020S9/>}/>{/*}Ruta para /Meca2020{*/}

        <Route path="/Obras2020" element={<Obras2020/>}/>{/*}Ruta para /Obras2020{*/}
        <Route path="/Obras2020S3" element={<Obras2020S3/>}/>{/*}Ruta para /Obras2020{*/}
        <Route path="/Obras2020S5" element={<Obras2020S5/>}/>{/*}Ruta para /Obras2020{*/}
        <Route path="/Obras2020S7" element={<Obras2020S7/>}/>{/*}Ruta para /Obras2020{*/}
        <Route path="/Obras2020S9" element={<Obras2020S9/>}/>{/*}Ruta para /Obras2020{*/}

        <Route path="/Info2020" element={<Info2020/>}/>{/*}Ruta para /Info2020{*/}
        <Route path="/Info2020S3" element={<Info2020S3/>}/>{/*}Ruta para /Info2020{*/}
        <Route path="/Info2020S5" element={<Info2020S5/>}/>{/*}Ruta para /Info2020{*/}
        <Route path="/Info2020S7" element={<Info2020S7/>}/>{/*}Ruta para /Info2020{*/}
        <Route path="/Info2020S9" element={<Info2020S9/>}/>{/*}Ruta para /Info2020{*/}

        <Route path="/Min2020" element={<Min2020/>}/>{/*}Ruta para /Min2020{*/}
        <Route path="/Min2020S3" element={<Min2020S3/>}/>{/*}Ruta para /Min2020{*/}
        <Route path="/Min2020S5" element={<Min2020S5/>}/>{/*}Ruta para /Min2020{*/}
        <Route path="/Min2020S7" element={<Min2020S7/>}/>{/*}Ruta para /Min2020{*/}
        <Route path="/Min2020S9" element={<Min2020S9/>}/>{/*}Ruta para /Min2020{*/}

        <Route path="/Industrial2020" element={<Industrial2020/>}/>{/*}Ruta para /Industrial2020{*/}
        <Route path="/Industrial2020S3" element={<Industrial2020S3/>}/>{/*}Ruta para /Industrial2020{*/}
        <Route path="/Industrial2020S5" element={<Industrial2020S5/>}/>{/*}Ruta para /Industrial2020{*/}
        <Route path="/Industrial2020S7" element={<Industrial2020S7/>}/>{/*}Ruta para /Industrial2020{*/}
        <Route path="/Industrial2020S9" element={<Industrial2020S9/>}/>{/*}Ruta para /Industrial2020{*/}

        <Route path="/Energia2021" element={<Energia2021/>}/>{/*}Ruta para /Energia2021{*/}
        <Route path="/Energia2021S3" element={<Energia2021S3/>}/>{/*}Ruta para /Energia2021{*/}
        <Route path="/Energia2021S5" element={<Energia2021S5/>}/>{/*}Ruta para /Energia2021{*/}
        <Route path="/Energia2021S7" element={<Energia2021S7/>}/>{/*}Ruta para /Energia2021{*/}
        <Route path="/Energia2021S9" element={<Energia2021S9/>}/>{/*}Ruta para /Energia2021{*/}

        <Route path="/Bio2021" element={<Bio2021/>}/>{/*}Ruta para /Bio2021{*/}
        <Route path="/Bio2021S3" element={<Bio2021S3/>}/>{/*}Ruta para /Bio2021{*/}
        <Route path="/Bio2021S5" element={<Bio2021S5/>}/>{/*}Ruta para /Bio2021{*/}
        <Route path="/Bio2021S7" element={<Bio2021S7/>}/>{/*}Ruta para /Bio2021{*/}
        <Route path="/Bio2021S9" element={<Bio2021S9/>}/>{/*}Ruta para /Bio2021{*/}

        <Route path="/Meca2021" element={<Meca2021/>}/>{/*}Ruta para /Meca2021{*/}
        <Route path="/Meca2021S3" element={<Meca2021S3/>}/>{/*}Ruta para /Meca2021{*/}
        <Route path="/Meca2021S5" element={<Meca2021S5/>}/>{/*}Ruta para /Meca2021{*/}
        <Route path="/Meca2021S7" element={<Meca2021S7/>}/>{/*}Ruta para /Meca2021{*/}
        <Route path="/Meca2021S9" element={<Meca2021S9/>}/>{/*}Ruta para /Meca2021{*/}

        <Route path="/Obras2021" element={<Obras2021/>}/>{/*}Ruta para /Obras2021{*/}
        <Route path="/Obras2021S3" element={<Obras2021S3/>}/>{/*}Ruta para /Obras2021{*/}
        <Route path="/Obras2021S5" element={<Obras2021S5/>}/>{/*}Ruta para /Obras2021{*/}
        <Route path="/Obras2021S7" element={<Obras2021S7/>}/>{/*}Ruta para /Obras2021{*/}
        <Route path="/Obras2021S9" element={<Obras2021S9/>}/>{/*}Ruta para /Obras2021{*/}

        <Route path="/Info2021" element={<Info2021/>}/>{/*}Ruta para /Info2021{*/}
        <Route path="/Info2021S3" element={<Info2021S3/>}/>{/*}Ruta para /Info2021{*/}
        <Route path="/Info2021S5" element={<Info2021S5/>}/>{/*}Ruta para /Info2021{*/}
        <Route path="/Info2021S7" element={<Info2021S7/>}/>{/*}Ruta para /Info2021{*/}
        <Route path="/Info2021S9" element={<Info2021S9/>}/>{/*}Ruta para /Info2021{*/}

        <Route path="/Min2021" element={<Min2021/>}/>{/*}Ruta para /Min2021{*/}
        <Route path="/Min2021S3" element={<Min2021S3/>}/>{/*}Ruta para /Min2021{*/}
        <Route path="/Min2021S5" element={<Min2021S5/>}/>{/*}Ruta para /Min2021{*/}
        <Route path="/Min2021S7" element={<Min2021S7/>}/>{/*}Ruta para /Min2021{*/}
        <Route path="/Min2021S9" element={<Min2021S9/>}/>{/*}Ruta para /Min2021{*/}

        <Route path="/Industrial2021" element={<Industrial2021/>}/>{/*}Ruta para /Industrial2021{*/}
        <Route path="/Industrial2021S3" element={<Industrial2021S3/>}/>{/*}Ruta para /Industrial2021{*/}
        <Route path="/Industrial2021S5" element={<Industrial2021S5/>}/>{/*}Ruta para /Industrial2021{*/}
        <Route path="/Industrial2021S7" element={<Industrial2021S7/>}/>{/*}Ruta para /Industrial2021{*/}
        <Route path="/Industrial2021S9" element={<Industrial2021S9/>}/>{/*}Ruta para /Industrial2021{*/}

        <Route path="/Energia2022" element={<Energia2022/>}/>{/*}Ruta para /Energia2022{*/}
        <Route path="/Energia2022S3" element={<Energia2022S3/>}/>{/*}Ruta para /Energia2022{*/}
        <Route path="/Energia2022S5" element={<Energia2022S5/>}/>{/*}Ruta para /Energia2022{*/}
        <Route path="/Energia2022S7" element={<Energia2022S7/>}/>{/*}Ruta para /Energia2022{*/}
        <Route path="/Energia2022S9" element={<Energia2022S9/>}/>{/*}Ruta para /Energia2022{*/}

        <Route path="/Bio2022" element={<Bio2022/>}/>{/*}Ruta para /Bio2022{*/}
        <Route path="/Bio2022S3" element={<Bio2022S3/>}/>{/*}Ruta para /Bio2022{*/}
        <Route path="/Bio2022S5" element={<Bio2022S5/>}/>{/*}Ruta para /Bio2022{*/}
        <Route path="/Bio2022S7" element={<Bio2022S7/>}/>{/*}Ruta para /Bio2022{*/}
        <Route path="/Bio2022S9" element={<Bio2022S9/>}/>{/*}Ruta para /Bio2022{*/}

        <Route path="/Meca2022" element={<Meca2022/>}/>{/*}Ruta para /Meca2022{*/}
        <Route path="/Meca2022S3" element={<Meca2022S3/>}/>{/*}Ruta para /Meca2022{*/}
        <Route path="/Meca2022S5" element={<Meca2022S5/>}/>{/*}Ruta para /Meca2022{*/}
        <Route path="/Meca2022S7" element={<Meca2022S7/>}/>{/*}Ruta para /Meca2022{*/}
        <Route path="/Meca2022S9" element={<Meca2022S9/>}/>{/*}Ruta para /Meca2022{*/}

        <Route path="/Obras2022" element={<Obras2022/>}/>{/*}Ruta para /Obras2022{*/}
        <Route path="/Obras2022S3" element={<Obras2022S3/>}/>{/*}Ruta para /Obras2022{*/}
        <Route path="/Obras2022S5" element={<Obras2022S5/>}/>{/*}Ruta para /Obras2022{*/}
        <Route path="/Obras2022S7" element={<Obras2022S7/>}/>{/*}Ruta para /Obras2022{*/}
        <Route path="/Obras2022S9" element={<Obras2022S9/>}/>{/*}Ruta para /Obras2022{*/}

        <Route path="/Info2022" element={<Info2022/>}/>{/*}Ruta para /Info2022{*/}
        <Route path="/Info2022S3" element={<Info2022S3/>}/>{/*}Ruta para /Info2022{*/}
        <Route path="/Info2022S5" element={<Info2022S5/>}/>{/*}Ruta para /Info2022{*/}
        <Route path="/Info2022S7" element={<Info2022S7/>}/>{/*}Ruta para /Info2022{*/}
        <Route path="/Info2022S9" element={<Info2022S9/>}/>{/*}Ruta para /Info2022{*/}

        <Route path="/Min2022" element={<Min2022/>}/>{/*}Ruta para /Min2022{*/}
        <Route path="/Min2022S3" element={<Min2022S3/>}/>{/*}Ruta para /Min2022{*/}
        <Route path="/Min2022S5" element={<Min2022S5/>}/>{/*}Ruta para /Min2022{*/}
        <Route path="/Min2022S7" element={<Min2022S7/>}/>{/*}Ruta para /Min2022{*/}
        <Route path="/Min2022S9" element={<Min2022S9/>}/>{/*}Ruta para /Min2022{*/}

        <Route path="/Industrial2022" element={<Industrial2022/>}/>{/*}Ruta para /Industrial2022{*/}
        <Route path="/Industrial2022S3" element={<Industrial2022S3/>}/>{/*}Ruta para /Industrial2022{*/}
        <Route path="/Industrial2022S5" element={<Industrial2022S5/>}/>{/*}Ruta para /Industrial2022{*/}
        <Route path="/Industrial2022S7" element={<Industrial2022S7/>}/>{/*}Ruta para /Industrial2022{*/}
        <Route path="/Industrial2022S9" element={<Industrial2022S9/>}/>{/*}Ruta para /Industrial2022{*/}

        <Route path="/Energia2023" element={<Energia2023/>}/>{/*}Ruta para /Energia2023{*/}
        <Route path="/Energia2023S3" element={<Energia2023S3/>}/>{/*}Ruta para /Energia2023{*/}
        <Route path="/Energia2023S5" element={<Energia2023S5/>}/>{/*}Ruta para /Energia2023{*/}
        <Route path="/Energia2023S7" element={<Energia2023S7/>}/>{/*}Ruta para /Energia2023{*/}
        <Route path="/Energia2023S9" element={<Energia2023S9/>}/>{/*}Ruta para /Energia2023{*/}

        <Route path="/Bio2023" element={<Bio2023/>}/>{/*}Ruta para /Bio2023{*/}
        <Route path="/Bio2023S3" element={<Bio2023S3/>}/>{/*}Ruta para /Bio2023{*/}
        <Route path="/Bio2023S5" element={<Bio2023S5/>}/>{/*}Ruta para /Bio2023{*/}
        <Route path="/Bio2023S7" element={<Bio2023S7/>}/>{/*}Ruta para /Bio2023{*/}
        <Route path="/Bio2023S9" element={<Bio2023S9/>}/>{/*}Ruta para /Bio2023{*/}

        <Route path="/Meca2023" element={<Meca2023/>}/>{/*}Ruta para /Meca2023{*/}
        <Route path="/Meca2023S3" element={<Meca2023S3/>}/>{/*}Ruta para /Meca2023{*/}
        <Route path="/Meca2023S5" element={<Meca2023S5/>}/>{/*}Ruta para /Meca2023{*/}
        <Route path="/Meca2023S7" element={<Meca2023S7/>}/>{/*}Ruta para /Meca2023{*/}
        <Route path="/Meca2023S9" element={<Meca2023S9/>}/>{/*}Ruta para /Meca2023{*/}

        <Route path="/Obras2023" element={<Obras2023/>}/>{/*}Ruta para /Obras2023{*/}
        <Route path="/Obras2023S3" element={<Obras2023S3/>}/>{/*}Ruta para /Obras2023{*/}
        <Route path="/Obras2023S5" element={<Obras2023S5/>}/>{/*}Ruta para /Obras2023{*/}
        <Route path="/Obras2023S7" element={<Obras2023S7/>}/>{/*}Ruta para /Obras2023{*/}
        <Route path="/Obras2023S9" element={<Obras2023S9/>}/>{/*}Ruta para /Obras2023{*/}

        <Route path="/Info2023" element={<Info2023/>}/>{/*}Ruta para /Info2023{*/}
        <Route path="/Info2023S3" element={<Info2023S3/>}/>{/*}Ruta para /Info2023{*/}
        <Route path="/Info2023S5" element={<Info2023S5/>}/>{/*}Ruta para /Info2023{*/}
        <Route path="/Info2023S7" element={<Info2023S7/>}/>{/*}Ruta para /Info2023{*/}
        <Route path="/Info2023S9" element={<Info2023S9/>}/>{/*}Ruta para /Info2023{*/}

        <Route path="/Min2023" element={<Min2023/>}/>{/*}Ruta para /Min2023{*/}
        <Route path="/Min2023S3" element={<Min2023S3/>}/>{/*}Ruta para /Min2023{*/}
        <Route path="/Min2023S5" element={<Min2023S5/>}/>{/*}Ruta para /Min2023{*/}
        <Route path="/Min2023S7" element={<Min2023S7/>}/>{/*}Ruta para /Min2023{*/}
        <Route path="/Min2023S9" element={<Min2023S9/>}/>{/*}Ruta para /Min2023{*/}

        <Route path="/Industrial2023" element={<Industrial2023/>}/>{/*}Ruta para /Industrial2023{*/}
        <Route path="/Industrial2023S3" element={<Industrial2023S3/>}/>{/*}Ruta para /Industrial2023{*/}
        <Route path="/Industrial2023S5" element={<Industrial2023S5/>}/>{/*}Ruta para /Industrial2023{*/}
        <Route path="/Industrial2023S7" element={<Industrial2023S7/>}/>{/*}Ruta para /Industrial2023{*/}
        <Route path="/Industrial2023S9" element={<Industrial2023S9/>}/>{/*}Ruta para /Industrial2023{*/}
        

        <Route path="/Malla2024Q" element={<Malla2024Q/>}/>{/*}Ruta para /Malla2023{*/}
        <Route path="/Malla2023Q" element={<Malla2023Q/>}/>{/*}Ruta para /Malla2023{*/}
        <Route path="/Malla2022Q" element={<Malla2022Q/>}/>{/*}Ruta para /Malla2022{*/}
        <Route path="/Malla2021Q" element={<Malla2021Q/>}/>{/*}Ruta para /Malla2021{*/}
        <Route path="/Malla2020Q" element={<Malla2020Q/>}/>{/*}Ruta para /Malla2020{*/}

        <Route path="/Malla2023Pr" element={<Malla2023Pr/>}/>{/*}Ruta para /Malla2023{*/}
        <Route path="/Malla2022Pr" element={<Malla2022Pr/>}/>{/*}Ruta para /Malla2022{*/}
        <Route path="/Malla2021Pr" element={<Malla2021Pr/>}/>{/*}Ruta para /Malla2021{*/}
        <Route path="/Malla2020Pr" element={<Malla2020Pr/>}/>{/*}Ruta para /Malla2020{*/}
        <Route path="/Malla2019Pr" element={<Malla2019Pr/>}/>{/*}Ruta para /Malla2019{*/}
        <Route path="/Malla2018Pr" element={<Malla2018Pr/>}/>{/*}Ruta para /Malla2018{*/}
        <Route path="/Malla2017Pr" element={<Malla2017Pr/>}/>{/*}Ruta para /Malla2017{*/}
        <Route path="/Malla2016Pr" element={<Malla2016Pr/>}/>{/*}Ruta para /Malla2016{*/}

        <Route path="/ReglamentoGeneral" element={<ReglamentoGeneral />}/>{/*}Ruta para /ReglamentoQuintoAño{*/}
        <Route path="/Condiciones" element={<Condiciones />}/>{/*}Ruta para /Condiciones{*/}

        <Route path="/CoordinacionSC" element={<Coordinacion_SC/>} />{/*}Ruta para /Coordinacion_SC{*/}
        <Route path="/Mencion" element={<Mencion/>} />{/*}Ruta para /Coordinacion_SC{*/}
        <Route path="/ReglamentoPregrado" element={<ReglamentoPregrado />}/>{/*}Ruta para /ReglamentoPregrado{*/}
        <Route path="/Normativas" element={<Normativa />}/>{/*}Ruta para /Normativa{*/}
        <Route path="/CalendarioPregrado" element={<CalendarioPregrado />}/>{/*}Ruta para /CalendarioPregrado{*/}
        <Route path="/Calendario" element={<Calendario />}/>{/*}Ruta para /Calendario{*/}
        <Route path="/MinorPregrado" element={<MinorPregrado />}/>{/*}Ruta para /MinorPregrado{*/}
        <Route path="/TallerPregrado" element={<TallerPregrado />}/>{/*}Ruta para /TallerPregrado{*/}
        <Route path="/CoordinacionPregrado" element={<CoordinacionPregrado />}/>{/*}Ruta para /CoordinacionPregrado{*/}
        <Route path="/CharlasPregrado" element={<CharlasPregrado />}/>{/*}Ruta para /CharlasPregrado{*/}
        <Route path="/PreguntasPregado" element={<PreguntasPregrado />}/>{/*}Ruta para /PreguntasPregado{*/}
        <Route path="/Minors" element={<Minors />}/>{/*}Ruta para /Minors{*/}

        <Route path="/ProcedimientosPracticas" element={<ProcedimientosPracticas />}/>{/*}Ruta para /PreguntasPregado{*/}
        <Route path="/ReglamentoPracticas" element={<ReglamentoPracticas />}/>{/*}Ruta para /ReglamentoPracticas{*/}
        <Route path="/PreguntasPracticas" element={<PreguntasPracticas />}/>{/*}Ruta para /PreguntasPracticas{*/}
        <Route path="/ComisionAcreditaciones" element={<ComisionAcreditaciones />}/>{/*}Ruta para /ComisionAcreditaciones{*/}
        <Route path="/AcreditacionAcreditaciones" element={<AcreditacionAcreditaciones />}/>{/*}Ruta para /AcreditacionAcreditaciones{*/}
        <Route path="/ColegioAcreditaciones" element={<ColegioAcreditaciones />}/>{/*}Ruta para /ColegioAcreditaciones{*/}
        <Route path="/CoordinacionInternacionalizacion" element={<CoordinacionInternacionalizacion />}/>{/*}Ruta para /ColegioAcreditaciones{*/}
        <Route path="/PreguntasInternacionalizacion" element={<PreguntasInternacionalizacion />}/>{/*}Ruta para /ColegioAcreditaciones{*/}
        <Route path="/PreguntasAsuntos" element={<PreguntasAsuntos />}/>{/*}Ruta para /PreguntasAsuntos{*/}
        <Route path="/InformacionTitulacion" element={<InformacionTitulacion />}/>{/*}Ruta para /InformacionTitulacion{*/}
        <Route path="/EmprendimientoTitulacio" element={<EmprendimientoTitulacion />}/>{/*}Ruta para /EmprendimientoTitulacion{*/}
        <Route path="/MagisterTitulacion" element={<MagisterTitulacion />}/>{/*}Ruta para /MagisterTitulacion{*/}
        <Route path="/MagisterTitulacionInternacional" element={<MagisterTitulacionInternacional />}/>{/*}Ruta para /MagisterTitulacionInternacional{*/}
        <Route path="/PasantiaTitulacion" element={<PasantiaTitulacion />}/>{/*}Ruta para /PasantiaTitulacion {*/}
        <Route path="/PreguntasTitulacion" element={<PreguntasTitulacion />}/>{/*}Ruta para /PasantiaTitulacion {*/}


      </Routes>
      
        <Footer/>{/*}Renderizado componente Footer{*/}
    </div>
  );
};

export default App
