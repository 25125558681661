//componente normativa
import React from "react";

import slider from "../../resources/titulacion_egreso.jpg"

import { useTranslation } from "react-i18next";

const Normativa = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>
    {t("quinto.condiciones.t")}
    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="card ms-4 me-4 border-0">
            <div className="row g-0 ">
                <div className="col-md-6">
                    <img src={slider} className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-5">
                    <div className="card-body position-relative">
                        {/*}Textos{*/}
                        <h1 className="card-title text-center fs-2">{t("quinto.condiciones.t1")}</h1>
                        <div className="bg-uai position-absolute start-50 translate-middle ms-3 custom-line-l-size" />
                        <div className="p-3" />

                        <span className="card-text text-start fs-6">{t("quinto.condiciones.t2")}</span>
                        <ul>
                            <li>{t("quinto.condiciones.t3")}</li>
                            <li>{t("quinto.condiciones.t4")}</li>
                            <li>{t("quinto.condiciones.t5")}</li>
                            <li>{t("quinto.condiciones.t6")}</li>
                        </ul>
                        <h1 className="card-title text-center fs-2">{t("quinto.condiciones.t7")}</h1>
                        <div className="bg-uai position-absolute start-50 translate-middle ms-3 custom-line-l-size" />
                        <div className="p-3" />

                        <ul>
                            <li>{t("quinto.condiciones.t8")}</li>
                            <li>{t("quinto.condiciones.t9")}</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
   {/*}Renderizado desde pantallas lg{*/}
   <div className="d-xl-none">
        <div className="card w-100 border-0">
            <div className="row g-0 ">
                <div className="col-md-6">
                    <img src="https://ingenieria.uai.cl/assets/uploads/2019/08/slider-1-1.jpg" className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-5">
                    <div className="card-body position-relative">
                        {/*}Textos{*/}
                        <h1 className="card-title text-center fs-2">Normativa</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <p className="card-text text-start fs-6">A continuación podrás descargar el reglamento de Código de Honor y revisar los distintos videos informativos.</p>
                                {/*}Hipervinculos{*/}
                                <div className="p-2"/>
                                <a className="btn text-light bg-uai p-2" href="https://alumnosfic.uai.cl/wp-content/uploads/2021/05/Aprueba-Código-de-Honor-2021.pdf">Reglamento Código de Honor</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=sLMS5gMN94c">Código de Honor | Universidad Adolfo Ibáñez</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=uJ8dGKy9340">Copia y Plagio | Universidad Adolfo Ibáñez</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=BiCFQjp0Bx4">Protocolo de Acoso | Universidad Adolfo Ibáñez</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
    </>
    )
}



export default Normativa;