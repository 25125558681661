//componente Home del sitio, encargado de contener todos los elementos correspondientes a la pagina de inicio
import React from "react";

import pregrado_logo from "../Home/santiago.jpg";
import uai from "../../resources/uai.jpg"
import uai1_1 from "../../resources/uai1_1.jpeg"
import uai2 from "../../resources/uai2.jpg"
import alumnos from "../../resources/alumnos.jpg"
import about from "../../resources/about.jpeg"
import about1 from "../../resources/about1.jpeg"
import coordinacion from "../../resources/coordinacion_1.jpeg"
import work from "../../resources/work1.jpeg"
import uai_2 from "../../resources/uai_2.jpeg"
import taller from "../../resources/taller.jpg"
import titulacion from "../../resources/titulacion.jpg"
import inter from "../../resources/inter.jpeg"
import acreditacion from "../../resources/acreditacion1.jpeg"
import bachi from "../../resources/bachillerato1.jpg"
import bachi2 from "../../resources/bachillerato2.jpeg"
import question from "../../resources/question.jpg"
import work2 from "../../resources/work2.jpg"
import rules from "../../resources/rules.png"
import pasantia from "../../resources/pasantia1.jpg"
import clases from "../../resources/class.jpg"
import inter2 from "../../resources/inter2.jpeg"
import acreditacion1 from "../../resources/acreditacion1.jpg"
import internacional1 from "../../resources/internacional1.jpg"
import ing1 from "../../resources/ing1.jpg"

import { useTranslation } from "react-i18next";


const Home = () =>{
    const [t, i18n]= useTranslation("global");
    return(        
    <>
        <div className="d-none d-md-block">
            {/*}PREGRADO{*/}
            <div className="position-relative">
                <h1 className="fs-3 text-center mt-3">{t("home.t1")}</h1>
                <div className="custom-line-size bg-uai mt-2 position-absolute start-50 top-50 translate-middle"/>
                <div className="p-3"/>
            </div>

            <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={uai1_1}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/ReglamentoPregrado">{t("home.t2")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={coordinacion}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/CoordinacionPregrado">{t("home.t3")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div>
        
        <div className="card shadow-lg w-auto mb-2 ms-4 me-4 position-relaitve">
            <img src={about}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/PreguntasPregado">{t("home.t4")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
        </div>

        
        {/*}QUINTO AÑO{*/}
        {/*}
        <div className="position-relative">
                <h1 className="fs-3 text-center mt-5">Quinto Año</h1>
                <div className="custom-line-size bg-uai mt-2 position-absolute start-50 top-50 translate-middle"/>
                <div className="p-3"/>
            </div>
        
            <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={alumnos}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <h1 className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0">Ingreso</h1>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={uai2}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <h1 className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0">Postulación MIF</h1>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div>
        <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={alumnos}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <h1 className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0">Coordinación</h1>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={uai2}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <h1 className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0">Pregruntas Frecuentes</h1>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div>
    {*/}
        
        {/*}PRACTICAS{*/}
        <div className="position-relative">
                <h1 className="fs-3 text-center mt-5">{t("home.t5")}</h1>
                <div className="custom-line-size bg-uai mt-2 position-absolute start-50 top-50 translate-middle"/>
                <div className="p-3"/>
        </div>

        <div className="card shadow-lg w-auto mb-2 ms-4 me-4 position-relaitve">
            <img src={question}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/PreguntasPracticas">{t("home.t6")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
        </div>       
        <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={work2}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/ProcedimientosPracticas">{t("home.t7")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={rules}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/ReglamentoPracticas">{t("home.t8")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div> 

        
        {/*}TITULACION{*/}
        <div className="position-relative">
                <h1 className="fs-3 text-center mt-5">{t("home.t9")}</h1>
                <div className="custom-line-size bg-uai mt-2 position-absolute start-50 top-50 translate-middle"/>
                <div className="p-3"/>
        </div>

        <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={uai2}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/EmprendimientoTitulacio">{t("home.t10")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={pasantia}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/PasantiaTitulacion">{t("home.t11")}</a>
                    <h1 className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0"></h1>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div>
        
        <div className="card shadow-lg w-auto mb-2 ms-4 me-4 position-relaitve">
            <img src={clases}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/MagisterTitulacion">{t("home.t12")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
        </div>

        
        {/*}INTERNACIONALIZACION{*/}
        <div className="position-relative">
                <h1 className="fs-3 text-center mt-5">{t("home.t13")}</h1>
                <div className="custom-line-size bg-uai mt-2 position-absolute start-50 top-50 translate-middle"/>
                <div className="p-3"/>
        </div>


        <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={inter2}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/CoordinacionInternacionalizacion">{t("home.t14")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={about1}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/MagisterTitulacion">{t("home.t15")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div>

        
        {/*}ACREDITACIONES{*/}
        <div className="position-relative">
                <h1 className="fs-3 text-center mt-5">{t("home.t16")}</h1>
                <div className="custom-line-size bg-uai mt-2 position-absolute start-50 top-50 translate-middle"/>
                <div className="p-3"/>
        </div>

        <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={internacional1}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/AcreditacionAcreditaciones">{t("home.t17")}</a>
                    
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={ing1}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/ColegioAcreditaciones">{t("home.t18")}</a>
                    
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div>
        
        <div className="card shadow-lg w-auto mb-2 ms-4 me-4 position-relaitve">
            <img src={acreditacion1}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/ComisionAcreditaciones">{t("home.t19")}</a>
                    
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
        </div>

        
        {/*}ASUNTOS ESTUDIANTILES{*/}
        <div className="position-relative">
                <h1 className="fs-3 text-center mt-5">{t("home.t20")}</h1>
                <div className="custom-line-size bg-uai mt-2 position-absolute start-50 top-50 translate-middle"/>
                <div className="p-3"/>
        </div>

        <div className="d-flex">    
            <div className="card shadow-lg w-50 mb-2 me-1 ms-4 position-relaitve">
            <img src={bachi2}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="https://alumno.uai.cl/santiago/asuntos-estudiantiles/dae/vida-universitaria/">{t("home.t21")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>

            <div className="card shadow-lg w-50 mb-2 me-4 ms-1 position-relaitve">
            <img src={alumnos}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="https://alumno.uai.cl/vina-del-mar/dae/vida-universitaria/">{t("home.t22")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
            </div>
        </div>
        <div className="card shadow-lg w-auto mb-2 ms-4 me-4 position-relaitve">
            <img src={question}className="card-img rounded-start"/>
            <div className="">    
                <div className="card-img-overlay position-absolute custom-co-image">
                    <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-2 text-decoration-none" href="/PreguntasAsuntos">{t("home.t23")}</a>
                    <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                </div>
            </div>
        </div>
    </div>
{/*}---------------------------------------------------------------{*/}

    <div className="d-md-none">
        <div className="p-2"/>
        {/*}PREGRADO{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={uai_2}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/CoordinacionPregrado">{t("home.t1")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}QUINTO AÑO{*/}
                {/*}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={taller}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">Quinto Año</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
                {*/}
        {/*}PRACTICAS{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={work}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/ProcedimientosPracticas">{t("home.t5")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}TITULACION{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={titulacion}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t9")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}INTERNACIONALIZACION{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={inter}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t13")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}ACREDITACIONES{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={acreditacion}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t16")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}ASUNTOS ESTUDIANTILES{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={bachi}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t20")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>


    </div>
    
</>

    )
}

export default Home