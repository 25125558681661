//Componente Top del sitio, encargado de contener los hipervinculos "Estudiantes", "ALumni", "Academicos", "Bibliotecas", "UAI en 360"
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'; // Asumiendo que estás utilizando React Router para la navegación

import { NavDropdown, Nav, Navbar, Button, Container, Row, Col, NavLink } from "react-bootstrap";//importacion biblioteca react-bootstrap
import Spain from "../../resources/Flag_of_Spain.svg"
import Usa from "../../resources/Flag_of_the_United_States.svg"
import Style from "../../styles/malla.css"
const Top = () =>{
    const [t, i18n]= useTranslation("global");
    return(
        <>
        <div className="d-none d-xl-block">
            <div className="d-none d-md-block p-1 bg-uai"></div>
            <div className="p-1 bg-black">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <a className="d-none d-md-block text-light ms-5 mt-1 fs-6 text-decoration-none" href="https://alumno.uai.cl/">{t("top.Estudiantes")}</a>{/*}Hipervinculo Estudiantes{*/}
                        <a className="d-none d-md-block text-light m-1 ms-4 text-decoration-none" href="https://www.uai.cl/alumni/">{t("top.Alumni")}</a>{/*}Hipervinculo Alumni{*/}
                        <a className="d-none d-md-block text-light m-1 ms-4 text-decoration-none" href="https://www.uai.cl/academicos-e-investigacion/investigacion/">{t("top.Academicos")}</a>{/*}Hipervinculo Academicos{*/}
                        <a className="d-none d-md-block text-light m-1 ms-4 text-decoration-none" href="https://www.uai.cl/rrii/">{t("top.Internacional")}</a>{/*}Hipervinculo Internacional{*/}
                        <a className="d-none d-md-block text-light m-1 ms-4 text-decoration-none" href="https://bibliotecas.uai.cl/">{t("top.Bibliotecas")}</a>{/*}Hipervinculo Bibliotecas{*/}
                        <a className="d-none d-md-block text-light m-1 ms-4 text-decoration-none" href="https://admision.uai.cl/uai-en-360/">{t("top.UAI")}</a>{/*}Hipervinculo UAI 360{*/}
                    </div>
                    <div className="ml-auto d-flex align-items-center" style={{ position: 'relative', zIndex: '1000' }}>
                        <NavDropdown
                        className="text-light"
                        id="nav-dropdown-3"
                        title={t("top.idioma")}
                        menuVariant="light"
                        style={{ marginRight: '20px' }}
                        >
                            <NavDropdown.Item>
                                <button
                                onClick={() => i18n.changeLanguage("es")}
                                className="custom-button-idioma"
                                >
                                <div className="d-flex align-items-center">
                                    <img
                                    src={Spain}
                                    alt="spain"
                                    style={{ width: '20px', marginRight: '5px' }}
                                    />
                                    ES
                                </div>
                                </button>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <button
                                onClick={() => i18n.changeLanguage("en")}
                                className="custom-button-idioma"
                                >
                                <div className="d-flex align-items-center">
                                    <img
                                    src={Usa}
                                    alt="usa"
                                    style={{ width: '20px', marginRight: '5px' }}
                                    />
                                    EN
                                </div>
                                </button>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
            </div>
            
            <div className="d-none d-xl-block p-3 bg-black">
                <div className="d-flex position-relative">
                    <div className="">
                        <Link to="/" style={{ textDecoration: 'none' }}><h1 className="text-light fs-3 mt-3 mb-2 ms-4">{t("top.Alumnos")}</h1></Link>

                        <div className="bg-uai mb-4 ms-4 custom-line-size"/>
                    </div>
                        {/*}<img className="m-3 img-header position-absolute top-25 custom-logo-position" src="https://www.uai.cl/assets/themes/uai-central/img/logo.svg" alt="Universidad Adolfo Ibáñez"></img>{*/}{/*}logo bugueado{*/}
                </div>
            </div>
        </div>
        </>
    )
}

export default Top//exportar componente
