import React from "react";
import tid from "../../resources/TID.png"
import taller from "../../resources/taller.jpg"
import { useTranslation } from "react-i18next";




const TallerPregrado = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>
    
    {/*}Renderizado hasta pantallas lg{*/}
    {/*}
    <div className="d-none d-md-block">
        <div className="card ms-4 me-4 border-0">
            <div className="row g-0 ">
                <div className="col-md-6">
                    <img src={tid} className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-5">
                    <div className="card-body position-relative pb-5">
                        <h1 className="card-title text-center fs-2">{t("pregrado.taller.t1")}</h1>
                        <div className="bg-uai position-absolute start-50 translate-middle ms-3 custom-line-l-size"/>
                        <div className="p-3"/>
                        <ul>
                            <li className="card-text text-start">{t("pregrado.taller.t2")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t3")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t4")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t5")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t6")}</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            
        </div>
    
    </div>
   
    <div className="d-md-none">
    <div className="card shadow-lg w-100">
        <div className="row g-0 ">
            <div className="col-md-6">
                <img src={tid} className="img-fluid rounded-start" alt="..."/>
            </div>
            <div className="col-md-5">
                <div className="card-body position-relative pb-5">
  
                    <h1 className="card-title text-center">{t("pregrado.taller.t1")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-3"/>
                        <ul>
                        <li className="card-text text-start">{t("pregrado.taller.t2")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t3")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t4")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t5")}</li>
                            <li className="card-text text-start">{t("pregrado.taller.t6")}</li>
                        </ul>
                            
                </div>

            </div>
        </div>
    </div> 
    </div>
    {*/}
    <div className="d-none d-xl-block fs-5 ">
        <div >        

                
        </div>
        

            
        

            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <div className="card position-relative ms-4 me-4">
                    <img src={taller}className="card-img "/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4 ">{t("pregrado.taller.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
                </div>
                <div className="my-5"/>
                    <p >
                        {t("pregrado.taller.t2")}   

                    </p>
                    <p>
                        {t("pregrado.taller.t3")}
                        
                    </p>
                
            </div>
            <div className="bg-grey ">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative pb-5"> 
                    <div className="p-3"/>
                        <h1 className="text-center fs-2">{t("pregrado.taller.t4")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p>
                                {t("pregrado.taller.t5")} <a href="www.webcursos.uai.cl">{t("pregrado.taller.t6")}</a> {t("pregrado.taller.t7")} <a href="https://forms.monday.com/forms/7bbc86c9fc53a61c5dbb1ddb161f0319?r=use1">{t("pregrado.taller.t8")}</a> {t("pregrado.taller.t9")}
                            </p>
                            <div className="p-3"/>
                            <p>{t("pregrado.taller.t10")}</p>
                            <ul>
                                <li className="fs-6"><p>{t("pregrado.taller.t11")}</p></li>
                                <li className="fs-6"><p>{t("pregrado.taller.t12")}</p></li>
                                <li className="fs-6"><p>{t("pregrado.taller.t13")}</p></li>
                                <li className="fs-6"><p>{t("pregrado.taller.t14")}</p></li>
                                <li className="fs-6"><p>{t("pregrado.taller.t15")}</p></li> 
                            </ul>
                            <p className="p-3">{t("pregrado.taller.t16")}</p>


                    </div>

                





                    <div className="position-relative pb-5">
                        <div className="p-3"/>
                        <h1 className="text-center fs-2">{t("pregrado.taller.t17")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                    <p >{t("pregrado.taller.t18")}</p>
                                    <p>{t("pregrado.taller.t19")}</p>



                    </div>





                    <div className="position-relative pb-5">
                        <div className="p-3"/>
                        <h1 className="text-center fs-2">{t("pregrado.taller.t20")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>

                            <ol>
                                <li className="fs-6" >
                                    <strong>{t("pregrado.taller.t21")} </strong>
                                    
                                    <ul>
                                        <li className="fs-6" >{t("pregrado.taller.t22")}</li>
                                        <li className="fs-6" >{t("pregrado.taller.t23")}</li>
                                        <li className="fs-6" >{t("pregrado.taller.t24")}</li>
                                    </ul>
                                </li>
                                <div className="p-3"/>
                                <li className="fs-6" >
                                    <strong>{t("pregrado.taller.t25")}</strong>
                                    
                                    <ul>
                                        <li className="fs-6" >{t("pregrado.taller.t26")}</li>
                                        <li className="fs-6" >{t("pregrado.taller.t27")}</li>

                                    </ul>
                                </li>
                            </ol>
                            <p>
                                {t("pregrado.taller.t28")}
                            </p>
                            <ul>
                                <li className="fs-6" >{t("pregrado.taller.t29")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t30")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t31")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t32")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t33")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t34")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t35")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t36")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t37")}</li>

                            </ul>
                            <p>
                                {t("pregrado.taller.t38")}
                            </p>
                            <div className="p-3"/>

                    </div>


                </div>



            </div>
            
        
        <div className="p-2"/>
        
    </div>
    
    {/*}Renderizado desde pantallas lg{*/}
    <div className="d-xl-none">
        <div className="d-none d-md-block">
        <div className="">        
                    <div className="card position-relative pb-5">
                        <img src={taller}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("pregrado.taller.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>

            <div className="p-3"/>
            <div className="col-xl-6 offset-xl-3 ms-2">
            <div className="p-3"/>
                <ul>
                    <li className="card-text text-start">{t("pregrado.taller.t2")}</li>
                    <li className="card-text text-start">{t("pregrado.taller.t3")}</li>

                </ul> 
            <div className="p-2"/>
            <div className="col-md-12 offset-md-3">
                <img src={tid}className="w-50 h-50"/> 
            </div>
                
                
            </div>
    </div>

        <div className="d-md-none">
            <div className="card border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={taller} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative pb-5">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("pregrado.taller.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <ul>
                                <li className="card-text text-start">{t("pregrado.taller.t2")}</li>
                                <li className="card-text text-start">{t("pregrado.taller.t3")}</li>

                            </ul> 
                            <div className="p-2"/>
                            <div className="bg-grey">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative pb-5"> 
                    <div className="p-3"/>
                        <h1 className="text-center fs-2">{t("pregrado.taller.t4")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p>
                                {t("pregrado.taller.t5")} <a href="www.webcursos.uai.cl">{t("pregrado.taller.t6")}</a> {t("pregrado.taller.t7")} <a href="https://forms.monday.com/forms/7bbc86c9fc53a61c5dbb1ddb161f0319?r=use1">{t("pregrado.taller.t8")}</a> {t("pregrado.taller.t9")}
                            </p>
                            <div className="p-3"/>
                            <p>{t("pregrado.taller.t10")}</p>
                            <ul>
                                <li className="fs-6">{t("pregrado.taller.t11")}</li>
                                <li className="fs-6">{t("pregrado.taller.t12")}</li>
                                <li className="fs-6">{t("pregrado.taller.t13")}</li>
                                <li className="fs-6">{t("pregrado.taller.t14")}</li>
                                <li className="fs-6">{t("pregrado.taller.t15")}</li> 
                            </ul>
                            <p className="p-3">{t("pregrado.taller.t16")}</p>


                    </div>



                    <div className="position-relative pb-5">
                        <div className="p-3"/>
                        <h1 className="text-center fs-2">{t("pregrado.taller.t17")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>


                                <ul>
                                    <li className="fs-6"><p>{t("pregrado.taller.t18")}</p></li>

                                    <li className="fs-6"><p>{t("pregrado.taller.t19")}</p></li>
                                </ul>

                    </div>





                <div className="position-relative pb-5">
                    <div className="p-3"/>
                    <h1 className="text-center fs-2">{t("pregrado.taller.t20")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>

                            <ol>
                                <li className="fs-6" >
                                    <strong>{t("pregrado.taller.t21")} </strong>
                                    
                                    <ul>
                                        <li className="fs-6" >{t("pregrado.taller.t22")}</li>
                                        <li className="fs-6" >{t("pregrado.taller.t23")}</li>
                                        <li className="fs-6" >{t("pregrado.taller.t24")}</li>
                                    </ul>
                                </li>
                                <div className="p-3"/>
                                <li className="fs-6" >
                                    <strong>{t("pregrado.taller.t25")}</strong>
                                    
                                    <ul>
                                        <li className="fs-6" >{t("pregrado.taller.t26")}</li>
                                        <li className="fs-6" >{t("pregrado.taller.t27")}</li>

                                    </ul>
                                </li>
                            </ol>
                            <p>
                                {t("pregrado.taller.t28")}
                            </p>
                            <ul>
                                <li className="fs-6" >{t("pregrado.taller.t29")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t30")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t31")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t32")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t33")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t34")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t35")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t36")}</li>
                                <li className="fs-6" >{t("pregrado.taller.t37")}</li>

                            </ul>
                            <p>
                                {t("pregrado.taller.t38")}
                            </p>
                            <div className="p-3"/>

                </div>














                </div>
            </div>
                            <img src={tid}className="w-75 h-75"/>   
            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    
    </>
    )
}



export default TallerPregrado;