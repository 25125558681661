import React from "react";
import titulacion from "../../resources/titulacion.jpg"
import Marquee from "react-fast-marquee";

import { useTranslation } from "react-i18next";


const InformacionTitulacion = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>

    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="col-md-6 offset-md-3">        
                <div className="card position-relative ms-4 me-4">
                    <img src={titulacion}className="card-img"/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.informacion.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
                </div>
        </div>

        <div className="p-3"/>

  
        <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.informacion.t2")}</li> 
                    <div className="p-2"/>

                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>    
            <div className="bg-grey">
                <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t5")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.informacion.t6")} </li> 
                                <li className="fs-6">{t("titulacion.informacion.t7")}</li>
                                <li className="fs-6">{t("titulacion.informacion.t8")}</li>
                                
                                <p className="fs-6 mt-2">{t("titulacion.informacion.t10")}</p>

                                <p>{t("titulacion.informacion.video1")} <a href="https://www.youtube.com/@IngenieriayCienciasUAI/featured" target="_blank" rel="noreferrer">{t("titulacion.informacion.video2")}</a>{t("titulacion.informacion.video3")}</p>

                                

                                
                            </ul>

                        </div>
                        <div className="p-3"/>


                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t11")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.informacion.t12")}</li> 
                            </ul>

                        </div>
                        <div className="p-3"/>

                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t13")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.informacion.t14")}</li> 
                            </ul>

                        </div>
                        <div className="p-3"/>


                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t15")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.informacion.t16")}

</li> 
                            </ul>

                        </div>
                        <div className="p-3"/>
                
                
                
                
                    <div className="p-3"/>
                    <p className="text-start fs-6"><strong>{t("titulacion.informacion.t9")}</strong></p>
                    <div className="p-2"/>
                
                </div>
    
            </div>
    </div>
    
    {/*}Renderizado desde pantallas lg{*/}
    <div className="d-xl-none">
        <div className="d-none d-md-block">
        <div className="">        
                    <div className="card position-relative">
                        <img src={titulacion}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.informacion.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>

            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.informacion.t2")}</li> 
                    <div className="p-2"/>

                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>    
            <div className="bg-grey">
                <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t5")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.informacion.t6")} </li> 
                                <li className="fs-6">{t("titulacion.informacion.t7")}</li>
                                <li className="fs-6">{t("titulacion.informacion.t8")}</li>
                                <p className="fs-6 mt-2">{t("titulacion.informacion.t10")}</p>
                                <p>{t("titulacion.informacion.video1")} <a href="https://www.youtube.com/@IngenieriayCienciasUAI/featured" target="_blank" rel="noreferrer">{t("titulacion.informacion.video2")}</a>{t("titulacion.informacion.video3")}</p>
                            </ul>

                        </div>
                        <div className="p-3"/>


                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t11")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                            <li className="fs-6">{t("titulacion.informacion.t12")}</li> 
                            </ul>

                        </div>
                        <div className="p-3"/>


                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t13")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                            <li className="fs-6">{t("titulacion.informacion.t14")}</li> 
                            </ul>

                        </div>
                        <div className="p-3"/>


                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.informacion.t15")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                            <div className="p-3"/>
                            <ul>
                            <li className="fs-6">{t("titulacion.informacion.t16")}</li> 
                            </ul>

                        </div>
                        <div className="p-3"/>
                
                
                
                
                    <div className="p-3"/>
                    <p className="text-start fs-6"><strong>{t("titulacion.informacion.t9")}</strong></p>
                    <div className="p-2"/>
                
                </div>
    
            </div>
        </div>

        <div className="d-md-none">
            <div className="card border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={titulacion} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("titulacion.informacion.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.informacion.t2")} </li> 
                                <div className="p-2"/>

                            </ul>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                    
                            <div className="bg-grey">

                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.informacion.t5")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6">{t("titulacion.informacion.t6")} </li> 
                                        <li className="fs-6">{t("titulacion.informacion.t7")}</li>
                                        <li className="fs-6">{t("titulacion.informacion.t8")}</li>
                                        <p className="fs-6 mt-2">{t("titulacion.informacion.t10")}</p>
                                        <p>{t("titulacion.informacion.video1")} <a href="https://www.youtube.com/@IngenieriayCienciasUAI/featured" target="_blank" rel="noreferrer">{t("titulacion.informacion.video2")}</a>{t("titulacion.informacion.video3")}</p>
                                    </ul>
  
                                    <div className="p-3"/>


                                    <p className="text-start fs-6"><strong>{t("titulacion.informacion.t9")}</strong></p>
                                    <div className="p-2"/>
                                </div>




                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.informacion.t11")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6">{t("titulacion.informacion.t12")}</li> 
                                    </ul>
  
                                    <div className="p-3"/>

                                </div>







                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.informacion.t13")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6">{t("titulacion.informacion.t14")}</li> 
                                    </ul>
  
                                    <div className="p-3"/>

                                </div>

                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.informacion.t15")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6">{t("titulacion.informacion.t16")}</li> 
                                    </ul>
  
                                    <div className="p-3"/>

                                </div>







                            </div>
                            
                            
                            
            </div>
        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}



export default InformacionTitulacion;