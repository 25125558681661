import React from "react";
import { NavDropdown} from "react-bootstrap";//importacion biblioteca react-bootstrap
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";



function AñosQ({año}){
    const [t, i18n]= useTranslation("global");
    const date = new Date();
    const añoActual = date.getFullYear();

    
    const mallasQ = {
        QMalla2020: "Malla2024Q",
        QMalla2021: "Malla2023Q",
        QMalla2022: "Malla2022Q",
        QMalla2023: "Malla2021Q",
        QMalla2024: "Malla2020Q",
    }
    return(
        <div className="d-flex justify-content-center align-items-center">
        <NavDropdown
              className="fw-bolder"
              id="nav-dropdown-3 "
              title={`${t("mallas.t9")} ${año} ` }
              menuVariant="light"
              style={{ marginTop: '20px', marginBottom: '20px'}}>
                {Object.keys(mallasQ).map((nombreMalla, año_malla) =>(
                    <NavLink to={`/${mallasQ[nombreMalla]}`} style={{ textDecoration: 'none' }}>
                        <NavDropdown.Item href={`#action/${año_malla}`}>{añoActual-año_malla}</NavDropdown.Item>
                    </NavLink>
                    ))}
        </NavDropdown>
        </div>
        
    );

}

export default AñosQ;