import React, {useState} from "react";
import {Button, Collapse} from "react-bootstrap";
import about from "../../resources/about.jpeg"
import { useTranslation } from "react-i18next";
import Procedimiento from "../Practicas/pdf/Procedimiento_en_Certificacion_MS_Excel.pdf";
import instructivo from "../Practicas/pdf/Instructivo_para_pago_de_Certificacion_Profesional_PPO101_2023.pdf"
import guia_presentacion from "../Practicas/pdf/Guia_presentacion_de_bitacora.pdf"
import material from "../Practicas/pdf/Material_de_preparacion _Certificacion_MS_Excel.pdf"

const PreguntasPracticas = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open14, setOpen14] = useState(false);
    const [open15, setOpen15] = useState(false);
    return(
    <>
    <div className="d-none d-xl-block">
        <div className="">        
                    <div className="card position-relative ms-xl-4 me-xl-4">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
        <div className="d-flex">
            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t3")}</li>
   
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t5")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t6")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t7")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t8")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t9")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t10")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t11")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t12")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t13")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t14")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t15")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t16")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t17")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t46")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t47")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t18")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t19")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.t21")}</a> {t("practicas.preguntas.t22")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t23")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t24")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t25")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t48")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.t49")}</a> {t("practicas.preguntas.t50")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen6(!open6)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open6}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t26")}</p>
                    </Button>
                    <Collapse in={open6}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t27")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.t28")}</a> {t("practicas.preguntas.t29")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen7(!open7)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open7}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t30")}</p>
                    </Button>
                    <Collapse in={open7}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t31")}<a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.preguntas.t34")}</a></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen8(!open8)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open8}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t32")}</p>
                    </Button>
                    <Collapse in={open8}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t33")}<a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.preguntas.t34")}</a>. {t("practicas.preguntas.t35")} </li>
                            </ul>
                            <p className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t36")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen9(!open9)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open9}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t37")}</p>
                    </Button>
                    <Collapse in={open9}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t38")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.preguntas.t39")}</a> {t("practicas.preguntas.t40")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen10(!open10)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open10}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t41")}</p>
                    </Button>
                    <Collapse in={open10}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t42")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen11(!open11)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open11}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t43")}</p>
                    </Button>
                    <Collapse in={open11}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t44")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.t45")}</a>.</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen12(!open12)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open12}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t52")}</p>
                    </Button>
                    <Collapse in={open12}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t53")}</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen13(!open13)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open13}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t54")}</p>
                    </Button>
                    <Collapse in={open13}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t55")} <a className="text-decoration-none color-uai" href={Procedimiento}>{t("practicas.preguntas.t56")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen14(!open14)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open14}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t57")}</p>
                    </Button>
                    <Collapse in={open14}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t58")} <a className="text-decoration-none color-uai" href={instructivo}>{t("practicas.preguntas.t59")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen15(!open15)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open15}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t60")}</p>
                    </Button>
                    <Collapse in={open15}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={material}>{t("practicas.preguntas.t61")}</a></li>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={guia_presentacion}>{t("practicas.preguntas.t62")}</a></li>
                            
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    </div>
                </div>
            </div>
        </div>

        <div className="d-xl-none">
            <div className="p-3 mt-2"/>
            <div className="">        
                    <div className="card position-relative">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
            <div className="d-flex">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t3")}</li>

                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t5")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t6")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t7")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t8")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t9")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t10")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t11")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t12")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t13")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t14")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t15")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t16")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t17")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t46")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t47")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t18")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t19")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.t21")}</a> {t("practicas.preguntas.t22")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t23")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t24")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t25")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen6(!open6)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open6}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t26")}</p>
                    </Button>
                    <Collapse in={open6}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t27")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.t28")}</a> {t("practicas.preguntas.t29")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen7(!open7)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open7}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t30")}</p>
                    </Button>
                    <Collapse in={open7}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t31")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen8(!open8)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open8}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t32")}</p>
                    </Button>
                    <Collapse in={open8}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t33")} <a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.preguntas.t34")}</a>. {t("practicas.preguntas.t35")} </li>
                            </ul>
                            <p className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t36")}</p>   
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen9(!open9)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open9}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t37")}</p>
                    </Button>
                    <Collapse in={open9}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t38")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.preguntas.t39")}</a> {t("practicas.preguntas.t40")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen10(!open10)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open10}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t41")}</p>
                    </Button>
                    <Collapse in={open10}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t42")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen11(!open11)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open11}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t43")}</p>
                    </Button>
                    <Collapse in={open11}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t44")} <a className="text-decoration-none color-uai" href="practicaslaborales.fic@uai.cl">{t("practicas.preguntas.t45")}</a>.</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen12(!open12)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open12}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t52")}</p>
                    </Button>
                    <Collapse in={open12}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t53")}</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen13(!open13)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open13}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t54")}</p>
                    </Button>
                    <Collapse in={open13}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t55")} <a className="text-decoration-none color-uai" href={Procedimiento}>{t("practicas.preguntas.t56")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen14(!open14)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open14}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t57")}</p>
                    </Button>
                    <Collapse in={open14}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.t58")} <a className="text-decoration-none color-uai" href={instructivo}>{t("practicas.preguntas.t59")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen15(!open15)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open15}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.t60")}</p>
                    </Button>
                    <Collapse in={open15}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={material}>{t("practicas.preguntas.t61")}</a></li>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={guia_presentacion}>{t("practicas.preguntas.t62")}</a></li>
                            
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    
                    </div>  
                </div>
            </div>  
        
        </div>
        
    </>
    )
}



export default PreguntasPracticas ;