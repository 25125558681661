import React from "react";

import work from "../../resources/work.jpg"

import guiainformepractica from "../Practicas/pdf/GuiaInformePractica.pdf"



import { useTranslation } from "react-i18next";
const ProcedimientosPracticas = () =>{
    const [t, i18n]= useTranslation("global");
    return(
        <>
        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className=""> 
                <div className="col-md-6 offset-md-3">       
                    <div className="card position-relative ms-4 me-4 ">
                        <img src={work}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.procedimientos.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
                </div>
            </div>
    
            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                
                <p className="text-start fs-6">{t("practicas.procedimientos.t2")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.t3")}</strong>{t("practicas.procedimientos.t4")}</p>
                <div className="p-2"/>
            </div>
            <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <h1 className="text-center fs-3">{t("practicas.procedimientos.t7")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("practicas.procedimientos.t8")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t9")} <a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.procedimientos.t10")}</a>{t("practicas.procedimientos.t11")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t12")} {t("practicas.procedimientos.t13")} {t("practicas.procedimientos.t14")} {t("practicas.procedimientos.t15")} {t("practicas.procedimientos.t16")} {t("practicas.procedimientos.t17")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t18")}</a></li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t21")} <strong>{t("practicas.procedimientos.t22")}</strong>{t("practicas.procedimientos.t23")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t24")}</a> {t("practicas.procedimientos.t25")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t26")} <strong>{t("practicas.procedimientos.t27")}</strong> {t("practicas.procedimientos.t28")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t29")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.procedimientos.t30")}</a> {t("practicas.procedimientos.t31")} <strong>{t("practicas.procedimientos.t32")}</strong>.</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t33")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t34")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t35")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t36")}</a></li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t37")} <a className="text-decoration-none color-uai" href={guiainformepractica}>{t("practicas.procedimientos.t38")}</a>.</li>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
            </div>
        
        </div>
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
            <div className="">        
                        <div className="card position-relative">
                            <img src={work}className="card-img"/>
                            <div className="card-img-overlay position-absolute custom-co-image">
                                <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.procedimientos.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                            </div>
                        </div>
                </div>
                <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <p className="text-start fs-6">{t("practicas.procedimientos.t2")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.t3")}</strong>{t("practicas.procedimientos.t4")}</p>
                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.t5")}</strong>{t("practicas.procedimientos.t6")}</p>
                <div className="p-2"/>
            </div>
                <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <h1 className="text-center fs-3">{t("practicas.procedimientos.t7")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("practicas.procedimientos.t8")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t9")} <a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.procedimientos.t10")}</a>{t("practicas.procedimientos.t11")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t12")} {t("practicas.procedimientos.t13")} {t("practicas.procedimientos.t14")} <strong>{t("practicas.procedimientos.t15")}</strong> {t("practicas.procedimientos.t16")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t17")}</a> {t("practicas.procedimientos.t18")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t21")} <strong>{t("practicas.procedimientos.t22")}</strong>{t("practicas.procedimientos.t23")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t24")}</a> {t("practicas.procedimientos.t25")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t26")} <strong>{t("practicas.procedimientos.t27")}</strong> {t("practicas.procedimientos.t28")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t29")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.procedimientos.t30")}</a> {t("practicas.procedimientos.t31")} <strong>{t("practicas.procedimientos.t32")}</strong>.</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t33")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t34")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t35")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t36")}</a></li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t37")} <a className="text-decoration-none color-uai" href={guiainformepractica}>{t("practicas.procedimientos.t38")}</a>.</li>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
            </div>
                
                
            </div>
    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={work} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("practicas.procedimientos.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("practicas.procedimientos.t2")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.t3")}</strong>{t("practicas.procedimientos.t4")}</p>
                                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.t5")}</strong>{t("practicas.procedimientos.t6")}</p>
                                <div className="p-2"/>  
                            </div>
                            <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <h1 className="text-center fs-3">{t("practicas.procedimientos.t7")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("practicas.procedimientos.t8")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t9")} <a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.procedimientos.t10")}</a>{t("practicas.procedimientos.t11")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t12")} {t("practicas.procedimientos.t13")} {t("practicas.procedimientos.t14")} <strong>{t("practicas.procedimientos.t15")}</strong> {t("practicas.procedimientos.t16")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t17")}</a> {t("practicas.procedimientos.t18")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t21")} <strong>{t("practicas.procedimientos.t22")}</strong>{t("practicas.procedimientos.t23")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t24")}</a> {t("practicas.procedimientos.t25")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t26")} <strong>{t("practicas.procedimientos.t27")}</strong> {t("practicas.procedimientos.t28")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t29")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.procedimientos.t30")}</a> {t("practicas.procedimientos.t31")} <strong>{t("practicas.procedimientos.t32")}</strong>.</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t33")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t34")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t35")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.t36")}</a></li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.t37")} <a className="text-decoration-none color-uai" href={guiainformepractica}>{t("practicas.procedimientos.t38")}</a>.</li>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
                </div>
                            
                            
            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </>
        )

}

export default ProcedimientosPracticas