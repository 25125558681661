import React from "react";

import internacional from "../../resources/internacional.jpg"
import { useTranslation } from "react-i18next";
const AcreditacionAcreditaciones = () =>{
    const [t, i18n]= useTranslation("global");
    return(
        <>
        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className="col-md-6 offset-md-3">        
                    <div className="card position-relative ms-4 me-4">
                        <img src={internacional}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("acreditacion.acreditacion_acreditaciones.t1")}</h1>{/*}Titulo{*/}
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
    
            
            <div className="bg-grey">
                <div className="p-3"/>
                <div className="col-md-6 offset-md-3">
                    <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t2")} <a className="text-decoration-none color-uai" href="http://www.abet.org/">{t("acreditacion.acreditacion_acreditaciones.t3")}</a>{t("acreditacion.acreditacion_acreditaciones.t4")}</p>
                    <div className="p-2"/>
                    <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t5")}</p>
                    <div className="p-2"/>
                    <a className="text-decoration-none color-uai text-start fs-6" href="http://www.abet.org/accreditation/why-abet-accreditation-matters/">{t("acreditacion.acreditacion_acreditaciones.t6")}</a>
                    <div className="p-2"/>
                    <a className="text-decoration-none color-uai text-start fs-6" href="http://main.abet.org/aps/Accreditedprogramsearch.aspx">{t("acreditacion.acreditacion_acreditaciones.t7")}</a>
                    <div className="p-2"/>
                    <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t8")}<a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/noticias/ingenieria-civil-industrial-recibe-acreditacion-abet/">{t("acreditacion.acreditacion_acreditaciones.t9")}</a></p>
                    <div className="p-3"/>
                        <div class="ratio ratio-4x3 shadow-lg">
                            <iframe src="https://e.issuu.com/embed.html?identifier=gez15babhekj&embedType=script#9956473/39969564" allowfullscreen></iframe>
                        </div>
                </div>
                <div className="p-3"/>
            </div>
            
        
        </div>
        
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
            <div className="">        
                        <div className="card position-relative">
                            <img src={internacional}className="card-img"/>
                            <div className="card-img-overlay position-absolute custom-co-image">
                                <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("top.Estudiantes")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                            </div>
                        </div>
                </div>
                <div className="bg-grey">
                    <div className="p-3"/>
                    <div className="col-md-6 offset-md-3">
                        <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t2")} <a className="text-decoration-none color-uai" href="http://www.abet.org/">{t("acreditacion.acreditacion_acreditaciones.t3")}</a>{t("acreditacion.acreditacion_acreditaciones.t4")}</p>
                        <div className="p-2"/>
                        <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t1")}</p>
                        <div className="p-2"/>
                        <a className="text-decoration-none color-uai text-start fs-6" href="http://www.abet.org/accreditation/why-abet-accreditation-matters/">{t("acreditacion.acreditacion_acreditaciones.t6")}</a>
                        <div className="p-2"/>
                        <a className="text-decoration-none color-uai text-start fs-6" href="http://main.abet.org/aps/Accreditedprogramsearch.aspx">{t("acreditacion.acreditacion_acreditaciones.t7")}</a>
                        <div className="p-2"/>
                        <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t8")}<a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/noticias/ingenieria-civil-industrial-recibe-acreditacion-abet/">{t("acreditacion.acreditacion_acreditaciones.t9")}</a></p>
                        <div className="p-3"/>
                        <div class="ratio ratio-4x3 shadow-lg">
                            <iframe src="https://e.issuu.com/embed.html?identifier=gez15babhekj&embedType=script#9956473/39969564" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="p-3"/>
                </div>
                
                
            </div>
    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={internacional} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("top.Estudiantes")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-2"/>
                            </div>
                            <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                    <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t2")} <a className="text-decoration-none color-uai" href="http://www.abet.org/">{t("acreditacion.acreditacion_acreditaciones.t3")}</a>{t("acreditacion.acreditacion_acreditaciones.t4")}</p>
                        <div className="p-2"/>
                        <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t1")}</p>
                        <div className="p-2"/>
                        <a className="text-decoration-none color-uai text-start fs-6" href="http://www.abet.org/accreditation/why-abet-accreditation-matters/">{t("acreditacion.acreditacion_acreditaciones.t6")}</a>
                        <div className="p-2"/>
                        <a className="text-decoration-none color-uai text-start fs-6" href="http://main.abet.org/aps/Accreditedprogramsearch.aspx">{t("acreditacion.acreditacion_acreditaciones.t7")}</a>
                        <div className="p-2"/>
                        <p className="text-start fs-6">{t("acreditacion.acreditacion_acreditaciones.t8")}<a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/noticias/ingenieria-civil-industrial-recibe-acreditacion-abet/">{t("acreditacion.acreditacion_acreditaciones.t9")}</a></p>
                        <div className="p-3"/>
                        <div class="ratio ratio-4x3 shadow-lg">
                            <iframe src="https://e.issuu.com/embed.html?identifier=gez15babhekj&embedType=script#9956473/39969564" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="p-4"/> 
                </div>
                </div>
                            
                            
            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
}

export default AcreditacionAcreditaciones