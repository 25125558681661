import React from "react";

import uai1_1 from "../../resources/uai1_2.jpeg"

import { useTranslation } from "react-i18next";
const ReglamentoPracticas = () =>{
    const [t, i18n]= useTranslation("global");
    return(
        
        <>
        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className="col-md-6 offset-md-3">        
                    <div className="card position-relative ms-4 me-4">
                        <img src={uai1_1}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.reglamento.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
    
            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <p className="text-start fs-6">{t("practicas.reglamento.t2")}</p>
            </div>
            <div className="d-none d-xl-block"> 
                <div className="p-4 position-relative text-center">
                    <a className="btn text-light bg-uai" href="https://alumnosficuai.blob.core.windows.net/public/Reglamento%20Actividades%20Pra%CC%81cticas.pdf">Reglamentos prácticas 2023</a>
                </div>
            </div>
        </div>
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
                <div className="">        
                            <div className="card position-relative">
                                <img src={uai1_1}className="card-img"/>
                                <div className="card-img-overlay position-absolute custom-co-image">
                                    <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.reglamento.t1")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                                </div>
                            </div>
                    </div>
                    <div className="p-3"/>
                <div className="col-md-6 offset-md-3">
                    <p className="text-start fs-6">{t("practicas.reglamento.t2")}</p>
                </div>
                <div className="d-none d-md-block"> 
                    <div className="p-4 position-relative text-center">
                        <a className="btn text-light bg-uai" href="https://alumnosficuai.blob.core.windows.net/public/Reglamento%20Actividades%20Pra%CC%81cticas.pdf">Reglamentos prácticas 2023</a>
                    </div>
                </div>
                    
                
            </div>
    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={uai1_1} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("practicas.reglamento.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("practicas.reglamento.t2")}</p>
                            </div>
                            <div className="bg-grey">
                    
                            <div className="p-4"/>
                            <div className="col-md-6 offset-md-3">
                                <a className="btn text-light bg-uai" href="https://alumnosficuai.blob.core.windows.net/public/Reglamento%20Actividades%20Pra%CC%81cticas.pdf">Reglamentos prácticas 2023</a>
                            </div>
                            </div>
                            
                            
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
        </>
        )

}

export default ReglamentoPracticas