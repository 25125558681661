import React, {useState} from "react";

import {Button, Collapse} from "react-bootstrap";
import uai from "../../resources/uai.jpg"
import about from "../../resources/about.jpeg"

import { useTranslation } from "react-i18next";
const PreguntasPregrado = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open14, setOpen14] = useState(false);
    const [open15, setOpen15] = useState(false);
    return(
    <>
    
    <div className="d-none d-xl-block">
        <div className="">        
                    <div className="card position-relative ms-xl-4 me-xl-4">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
        <div className="d-flex">
            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("titulacion.preguntas.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.preguntas.t3")}</p>
                                <ul className="col-md-8 offset-md-2 fs-6">
                                    <li>
                                    {t("titulacion.preguntas.t4")}
                                    </li>
                                    <li>
                                    {t("titulacion.preguntas.t5")}
                                    </li>
                                    <li>
                                    {t("titulacion.preguntas.t6")}
                                    </li>
                                    <li>
                                    {t("titulacion.preguntas.t7")}
                                    </li>
                                    <li>
                                    {t("titulacion.preguntas.t8")}
                                    </li>

                                </ul>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.preguntas.t9")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("titulacion.preguntas.t10")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.preguntas.t11")}</p>

                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.preguntas.t12")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("titulacion.preguntas.t13")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.preguntas.t14")}</p>

                            <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.preguntas.t15")}</p> 

                            <ul className="col-md-8 offset-md-2 fs-6 ">
                                <li>
                                {t("titulacion.preguntas.t16")}
                                </li>
                                <li>
                                {t("titulacion.preguntas.t17")}
                                </li>
                                <li>
                                {t("titulacion.preguntas.t18")}
                                </li>

                            </ul>


                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("titulacion.preguntas.t19")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-4"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.preguntas.t20")}</p> 
                            <div className="p-4"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    

                    </div>
                </div>
            </div>
        </div>

        <div className="d-xl-none">
            <div className="p-3 mt-2"/>
            <div className="">        
                    <div className="card position-relative">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
            <div className="d-flex">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">
                        <div className="p-3"/>
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("titulacion.preguntas.t2")}</p>
                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                    <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("titulacion.preguntas.t3")}</p>
                                    <ul className="col-md-8 offset-md-2 fs-6 ms-2" >
                                        <li>
                                        {t("titulacion.preguntas.t4")}
                                        </li>
                                        <li>
                                        {t("titulacion.preguntas.t5")}
                                        </li>
                                        <li>
                                        {t("titulacion.preguntas.t6")}
                                        </li>
                                        <li>
                                        {t("titulacion.preguntas.t7")}
                                        </li>
                                        <li>
                                        {t("titulacion.preguntas.t8")}
                                        </li>
                                    </ul>
                                    <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("titulacion.preguntas.t9")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open2}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("titulacion.preguntas.t10")}</p>
                        </Button>
                        <Collapse in={open2}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("titulacion.preguntas.t11")}</p>
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("titulacion.preguntas.t12")}</p>
                                <div className="p-2"/>

                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen3(!open3)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open3}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("titulacion.preguntas.t13")}</p>
                        </Button>
                        <Collapse in={open3}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2 ">{t("titulacion.preguntas.t14")}</p>
                                

                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("titulacion.preguntas.t15")}</p> 

                                <ul className="col-md-8 offset-md-2 fs-6 ms-2">
                                    <li>
                                    {t("titulacion.preguntas.t16")}
                                    </li>
                                    <li>
                                    {t("titulacion.preguntas.t17")}
                                    </li>
                                    <li>
                                    {t("titulacion.preguntas.t18")}
                                    </li>


                                </ul>





                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("titulacion.preguntas.t19")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-4"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2 ">{t("titulacion.preguntas.t20")}</p>
                                <div className="p-4"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        

                    </div>  
                </div>
            </div>  
        
        </div>
        
    </>
    )
}



export default PreguntasPregrado;