import React, {useState} from "react";

import {Button, Collapse} from "react-bootstrap";
import uai from "../../resources/uai.jpg"
import about from "../../resources/about.jpeg"

import { useTranslation } from "react-i18next";
const PreguntasPregrado = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open14, setOpen14] = useState(false);
    const [open15, setOpen15] = useState(false);
    return(
    <>
    
    <div className="d-none d-xl-block">
        <div className="">        
                    <div className="card position-relative ms-xl-4 me-xl-4">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("pregrado.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
        <div className="d-flex">
            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t2")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t3")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t4")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t5")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t6")} <a className="text-decoration-none color-uai" href="#">{t("pregrado.preguntas.t7")}</a>{t("pregrado.preguntas.t8")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t9")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t10")} <a className="text-decoration-none color-uai" href="#">{t("pregrado.preguntas.t11")}</a> {t("pregrado.preguntas.t12")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t13")}</p> 
                            <p className="col-md-8 offset-md-2 fs-6 fw-bold">{t("pregrado.preguntas.t14")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t15")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-4"/>
                            <a className="offset-md-0 ms-4 fs-6 text-decoration-none color-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2021/02/RAMOS_INGLÉS_FIC-2.pdf">{t("pregrado.preguntas.t16")}</a>
                            <div className="p-4"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t17")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t18")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    
                    <Button
                        onClick={() => setOpen9(!open9)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open9}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t35")}</p>
                    </Button>
                    <Collapse in={open9}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t36")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t37")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t38")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t39")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t40")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t41")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t42")}</p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen10(!open10)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open10}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">
                        {t("pregrado.preguntas.t43")}
                    </p>
                    </Button>
                    <Collapse in={open10}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t44")}<strong>{t("pregrado.preguntas.t45")}</strong>{t("pregrado.preguntas.t96")}</p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t97")}<strong>{t("pregrado.preguntas.t98")}</strong>{t("pregrado.preguntas.t99")}<a href="https://forms.office.com/r/xAjHi3w7PD">{t("pregrado.preguntas.t100")}</a></p>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t101")}</p>

                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen11(!open11)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open11}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t46")}</p>
                    </Button>
                    <Collapse in={open11}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t47")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t48")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t49")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t50")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t51")}</li>
                            </ul>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t52")}</p>
                            <a className="col-md-8 offset-md-2 fs-6 text-decoration-none color-uai" href="https://alumno.uai.cl/finanzas-estudiantiles/">{t("pregrado.preguntas.t53")}</a>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen12(!open12)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open12}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t54")}</p>
                    </Button>
                    <Collapse in={open12}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t55")} <a className="text-decoration-none color-uai" href="https://admision.uai.cl/becas-y-creditos">{t("pregrado.preguntas.t56")}</a> {t("pregrado.preguntas.t57")} <a className="text-decoration-none color-uai" href="mailto:becas@uai.cl">{t("pregrado.preguntas.t58")}</a></p>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen13(!open13)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open13}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t59")}</p>
                    </Button>
                    <Collapse in={open13}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://webc.uai.cl/login/index.php">{t("pregrado.preguntas.t60")}</a>{t("pregrado.preguntas.t61")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t62")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://intranet.uai.cl/login.aspx?ReturnUrl=%2f">{t("pregrado.preguntas.t63")}</a>{t("pregrado.preguntas.t64")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t65")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen14(!open14)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open14}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t66")}</p>
                    </Button>
                    <Collapse in={open14}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil/">{t("pregrado.preguntas.t67")}</a> {t("pregrado.preguntas.t68")} <a className="text-decoration-none color-uai" href="mailto:ricardo.moffat@uai.cl">{t("pregrado.preguntas.t69")}</a></li>

                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-en-bioingenieria/">{t("pregrado.preguntas.t70")}</a> {t("pregrado.preguntas.t71")} <a className="text-decoration-none color-uai" href="mailto:tledger@uai.cl">{t("pregrado.preguntas.t72")}</a></li>
                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://ingenieria.uai.cl/carreras/">{t("pregrado.preguntas.t73")}</a> {t("pregrado.preguntas.t74")} <a className="text-decoration-none color-uai" href="mailto:luis.gutierrez.l@uai.cl">{t("pregrado.preguntas.t75")}</a></li>

                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-en-mineria/">{t("pregrado.preguntas.t76")}</a> {t("pregrado.preguntas.t77")} <a className="text-decoration-none color-uai" href="mailto:miguel.herrera@uai.cl">{t("pregrado.preguntas.t78")}</a></li>

                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-industrial/">{t("pregrado.preguntas.t79")}</a> {t("pregrado.preguntas.t80")} <a className="text-decoration-none color-uai" href="mailto:luis.aburto@uai.cl">{t("pregrado.preguntas.t81")}</a></li>

                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-informatica/">{t("pregrado.preguntas.t83")}</a> {t("pregrado.preguntas.t84")} <a className="text-decoration-none color-uai" href="mailto:danilo.borquez@uai.cl">{t("pregrado.preguntas.t85")}</a></li>
                                
                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-mecanica/">{t("pregrado.preguntas.t86")}</a> {t("pregrado.preguntas.t87")} <a className="text-decoration-none color-uai" href="mailto:paula.rojas.s@uai.cl">{t("pregrado.preguntas.t88")}</a></li>

                                <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-en-energia/">{t("pregrado.preguntas.t102")}</a> {t("pregrado.preguntas.t103")} <a className="text-decoration-none color-uai" href="mailto:luis.gutierrez.l@uai.cl">{t("pregrado.preguntas.t104")}</a></li>

                                <li className="col-md-8 offset-md-2 fs-6 "><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-en-computer-science/">{t("pregrado.preguntas.t105")}</a> {t("pregrado.preguntas.t106")} <a className="text-decoration-none color-uai" href="mailto:danilo.borquez@uai.cl">{t("pregrado.preguntas.t107")}</a></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen15(!open15)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open15}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("pregrado.preguntas.t89")}</p>
                    </Button>
                    <Collapse in={open15}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t90")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t91")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t92")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t93")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t94")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("pregrado.preguntas.t95")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    </div>
                </div>
            </div>
        </div>

        <div className="d-xl-none">
            <div className="p-3 mt-2"/>
            <div className="">        
                    <div className="card position-relative">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("pregrado.preguntas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
            <div className="d-flex">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">
                        <div className="p-3"/>
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t2")}</p>
                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t3")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t4")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open2}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t5")}</p>
                        </Button>
                        <Collapse in={open2}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t6")} <a className="text-decoration-none color-uai" href="#">{t("pregrado.preguntas.t7")}</a>{t("pregrado.preguntas.t8")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen3(!open3)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open3}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t9")}</p>
                        </Button>
                        <Collapse in={open3}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t10")} <a className="text-decoration-none color-uai" href="#">{t("pregrado.preguntas.t11")}</a> {t("pregrado.preguntas.t12")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t13")}</p> 
                                <p className="col-md-8 offset-md-2 fs-6 fw-bold ms-2">{t("pregrado.preguntas.t14")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t15")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-4"/>
                                <a className="offset-md-0 ms-2 fs-6 text-decoration-none color-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2021/02/RAMOS_INGLÉS_FIC-2.pdf">{t("pregrado.preguntas.t16")}</a>
                                <div className="p-4"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen5(!open5)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open5}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t17")}</p>
                        </Button>
                        <Collapse in={open5}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t18")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                       
                        <Button
                            onClick={() => setOpen9(!open9)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open9}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t35")}</p>
                        </Button>
                        <Collapse in={open9}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t36")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t37")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t38")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t39")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t40")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t50")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t42")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen10(!open10)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open10}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">
                            {t("pregrado.preguntas.t43")}
                        </p>
                        </Button>
                        <Collapse in={open10}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t44")}<strong>{t("pregrado.preguntas.t45")}</strong>{t("pregrado.preguntas.t96")}</p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t97")}<strong>{t("pregrado.preguntas.t98")}</strong>{t("pregrado.preguntas.t99")}<a href="https://forms.office.com/r/xAjHi3w7PD">{t("pregrado.preguntas.t100")}</a></p>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t101")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen11(!open11)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open11}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t46")}</p>
                        </Button>
                        <Collapse in={open11}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t47")}</p>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t48")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t49")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t50")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t51")}</li>
                                </ul>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t52")}</p>
                                <a className="col-md-8 offset-md-2 fs-6 text-decoration-none color-uai ms-2" href="https://alumno.uai.cl/finanzas-estudiantiles/">{t("pregrado.preguntas.t53")}</a>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen12(!open12)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open12}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t54")}</p>
                        </Button>
                        <Collapse in={open12}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t55")} <a className="text-decoration-none color-uai" href="https://admision.uai.cl/becas-y-creditos">{t("pregrado.preguntas.t56")}</a> {t("pregrado.preguntas.t57")} <a className="text-decoration-none color-uai" href="mailto:becas@uai.cl">{t("pregrado.preguntas.t58")}</a></p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen13(!open13)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open13}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t59")}</p>
                        </Button>
                        <Collapse in={open13}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://webc.uai.cl/login/index.php">{t("pregrado.preguntas.t60")}</a>{t("pregrado.preguntas.t61")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t62")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://intranet.uai.cl/login.aspx?ReturnUrl=%2f">{t("pregrado.preguntas.t63")}</a>{t("pregrado.preguntas.t64")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t65")}</li>
                                </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen14(!open14)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open14}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t66")}</p>
                        </Button>
                        <Collapse in={open14}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil/">{t("pregrado.preguntas.t67")}</a> {t("pregrado.preguntas.t68")} <a className="text-decoration-none color-uai" href="mailto:ricardo.moffat@uai.cl">{t("pregrado.preguntas.t69")}</a></li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-en-bioingenieria/">{t("pregrado.preguntas.t70")}</a> {t("pregrado.preguntas.t71")} <a className="text-decoration-none color-uai" href="mailto:tledger@uai.cl">{t("pregrado.preguntas.t72")}</a></li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://ingenieria.uai.cl/carreras/">{t("pregrado.preguntas.t73")}</a> {t("pregrado.preguntas.t74")} <a className="text-decoration-none color-uai" href="mailto:luis.gutierrez.l@uai.cl">{t("pregrado.preguntas.t75")}</a></li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-en-mineria/">{t("pregrado.preguntas.t76")}</a> {t("pregrado.preguntas.t77")} <a className="text-decoration-none color-uai" href="mailto:miguel.herrera@uai.cl">{t("pregrado.preguntas.t78")}</a></li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-industrial/">{t("pregrado.preguntas.t79")}</a> {t("pregrado.preguntas.t80")} <a className="text-decoration-none color-uai" href="mailto:luis.aburto@uai.cl">{t("pregrado.preguntas.t81")}</a></li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-informatica/">{t("pregrado.preguntas.t83")}</a> {t("pregrado.preguntas.t84")} <a className="text-decoration-none color-uai" href="mailto:danilo.borquez@uai.cl">{t("pregrado.preguntas.t85")}</a></li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-mecanica/">{t("pregrado.preguntas.t86")}</a> {t("pregrado.preguntas.t87")} <a className="text-decoration-none color-uai" href="mailto:paula.rojas.s@uai.cl">{t("pregrado.preguntas.t88")}</a></li>

                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-civil-en-energia/">{t("pregrado.preguntas.t102")}</a> {t("pregrado.preguntas.t103")} <a className="text-decoration-none color-uai" href="mailto:luis.gutierrez.l@uai.cl">{t("pregrado.preguntas.t104")}</a></li>

                                    <li className="col-md-8 offset-md-2 fs-6 ms-2"><a className="text-decoration-none color-uai" href="https://admision.uai.cl/carreras/ingenieria-en-computer-science/">{t("pregrado.preguntas.t105")}</a> {t("pregrado.preguntas.t106")} <a className="text-decoration-none color-uai" href="mailto:danilo.borquez@uai.cl">{t("pregrado.preguntas.t107")}</a></li>
                                </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen15(!open15)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open15}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                        >
                        <p className="fs-5">{t("pregrado.preguntas.t89")}</p>
                        </Button>
                        <Collapse in={open15}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t90")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t91")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t92")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t93")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t94")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6 ms-2">{t("pregrado.preguntas.t95")}</li>
                                </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                    </div>  
                </div>
            </div>  
        
        </div>
        
    </>
    )
}



export default PreguntasPregrado;