import React, {useState} from "react";
import {Button, Collapse} from "react-bootstrap";
import emprendimiento from "../../resources/emprendimiento.jpg"

import { useTranslation } from "react-i18next";


const EmprendimientoTitulacion = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    return(
    <>
    
    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="col-md-6 offset-md-3">        
                <div className="card position-relative ms-4 me-4">
                    <img src={emprendimiento}className="card-img"/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.emprendimiento.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
                </div>
        </div>
        <div className="p-3"/>
        
        <div className="col-md-6 offset-md-3">
                <div className="p-2"/>
                <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t2")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t3")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t4")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t5")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t6")}</p>
                <div className="p-2"/>
                <ul>
                    <li className="fs-6">{t("titulacion.emprendimiento.t7")}</li> 
                    <li className="fs-6">{t("titulacion.emprendimiento.t8")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t9")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t10")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t11")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t12")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t13")}</li>
                    <li className="fs-6"> {t("titulacion.emprendimiento.t14")}</li>
                </ul>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t15")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t16")}</p>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div> 

            <div className="bg-grey">
                <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t17")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t18")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t19")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t21")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t22")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        </div>
                        <div className="p-3"/>
                </div>
            </div>

            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                        <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t23")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t24")}</p>
                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t25")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open2}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t26")}</p>
                        </Button>
                        <Collapse in={open2}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t27")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen3(!open3)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open3}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t28")}</p>
                        </Button>
                        <Collapse in={open3}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t29")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t30")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t31")}</p>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t32")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t33")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t34")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t35")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t36")}</li>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t37")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen5(!open5)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open5}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t38")}</p>
                        </Button>
                        <Collapse in={open5}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t39")}</p>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t40")}</p> 
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t41")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen6(!open6)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open6}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t42")}</p>
                        </Button>
                        <Collapse in={open6}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t43")}</p>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t44")}</p>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t45")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t46")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t47")}</li>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen7(!open7)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open7}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5"> {t("titulacion.emprendimiento.t48")}</p>
                        </Button>
                        <Collapse in={open7}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t49")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-3"/>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                </div>

            </div>
    </div>


{/*}-----------------------------------------------------{*/}


    {/*}Renderizado desde pantallas lg{*/}
    <div className="d-xl-none">
        <div className="d-none d-md-block">
        <div className="">        
                    <div className="card position-relative">
                        <img src={emprendimiento}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.emprendimiento.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>

            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <div className="p-2"/>
                <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t2")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t3")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t4")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t5")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t6")}</p>
                <div className="p-2"/>
                <ul>
                    <li className="fs-6">{t("titulacion.emprendimiento.t7")}</li> 
                    <li className="fs-6">{t("titulacion.emprendimiento.t8")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t9")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t10")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t11")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t12")}</li>
                    <li className="fs-6">{t("titulacion.emprendimiento.t13")}</li>
                    <li className="fs-6"> {t("titulacion.emprendimiento.t14")}</li>
                </ul>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t15")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.emprendimiento.t16")}</p>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div> 

            <div className="bg-grey">
                <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t17")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t18")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t19")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t20")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t22")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        </div>
                        <div className="p-3"/>
                </div>
            </div>

            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                        <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t23")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t24")}</p>
                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t25")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open2}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t26")}</p>
                        </Button>
                        <Collapse in={open2}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t27")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen3(!open3)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open3}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t28")}</p>
                        </Button>
                        <Collapse in={open3}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t29")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t30")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t31")}</p>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t32")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t33")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t34")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t35")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t36")}</li>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t37")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen5(!open5)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open5}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t38")}</p>
                        </Button>
                        <Collapse in={open5}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t39")}</p>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t40")}</p> 
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t41")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen6(!open6)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open6}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t42")}</p>
                        </Button>
                        <Collapse in={open6}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t43")}</p>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t44")}</p>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t45")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t46")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t47")}</li>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen7(!open7)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open7}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5"> {t("titulacion.emprendimiento.t48")}</p>
                        </Button>
                        <Collapse in={open7}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t49")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-3"/>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                </div>

            </div>
        </div>

        <div className="d-md-none">
            <div className="card border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={emprendimiento} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("titulacion.emprendimiento.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <div className="p-2"/>
                            <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t2")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t3")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t4")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t5")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t6")}</p>
                            <div className="p-2"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.emprendimiento.t7")}</li> 
                                <li className="fs-6">{t("titulacion.emprendimiento.t8")}</li>
                                <li className="fs-6">{t("titulacion.emprendimiento.t9")}</li>
                                <li className="fs-6">{t("titulacion.emprendimiento.t10")}</li>
                                <li className="fs-6">{t("titulacion.emprendimiento.t11")}</li>
                                <li className="fs-6">{t("titulacion.emprendimiento.t12")}</li>
                                <li className="fs-6">{t("titulacion.emprendimiento.t13")}</li>
                                <li className="fs-6"> {t("titulacion.emprendimiento.t14")}</li>
                            </ul>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t15")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.emprendimiento.t16")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                    
                            <div className="bg-grey">
                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t17")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                    <p className="text-start fs-6">{t("titulacion.emprendimiento.t18")}</p>
                                    <div className="p-2"/>
                                    <p className="text-start fs-6">{t("titulacion.emprendimiento.t19")}</p>
                                    <div className="p-2"/>
                                    <p className="text-start fs-6">{t("titulacion.emprendimiento.t20")}</p>
                                    <div className="p-2"/>
                                    <p className="text-start fs-6">{t("titulacion.emprendimiento.t22")}</p>
                                    <div className="p-3"/>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                </div>
                            </div>
                            <div className="p-3"/> 
                            <h1 className="text-center fs-3">{t("titulacion.emprendimiento.t23")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <Button
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                className="bg-uai btn btn-outline-light w-100 p-1"
                                >
                                <p className="fs-5">{t("titulacion.emprendimiento.t24")}</p>
                            </Button>
                            <Collapse in={open}>
                                <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                    <div className="p-2"/>
                                    <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t25")}</p>
                                    <div className="p-2"/>
                                </div>
                            </Collapse>
                            <div className="p-2"/>
                            <Button
                                onClick={() => setOpen2(!open2)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open2}
                                className="bg-uai btn btn-outline-light w-100 p-1"
                                >
                                <p className="fs-5">{t("titulacion.emprendimiento.t26")}</p>
                            </Button>
                            <Collapse in={open2}>
                                <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                    <div className="p-2"/>
                                    <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t27")}</p>
                                    <div className="p-2"/>
                                </div>
                            </Collapse>
                            <div className="p-2"/>
                            <Button
                                onClick={() => setOpen3(!open3)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open3}
                                className="bg-uai btn btn-outline-light w-100 p-1"
                                >
                                <p className="fs-5">{t("titulacion.emprendimiento.t28")}</p>
                            </Button>
                            <Collapse in={open3}>
                                <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                    <div className="p-2"/>
                                    <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t29")}</p>
                                    <div className="p-2"/>
                                </div>
                            </Collapse>
                            <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t30")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t31")}</p>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t32")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t33")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t34")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t35")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t36")}</li>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t37")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen5(!open5)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open5}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t38")}</p>
                        </Button>
                        <Collapse in={open5}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t39")}</p>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t40")}</p> 
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t41")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen6(!open6)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open6}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.emprendimiento.t42")}</p>
                        </Button>
                        <Collapse in={open6}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t43")}</p>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t44")}</p>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t45")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t46")}</li>
                                <li className="col-md-8 offset-md-2 fs-6"> {t("titulacion.emprendimiento.t47")}</li>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen7(!open7)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open7}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5"> {t("titulacion.emprendimiento.t48")}</p>
                        </Button>
                        <Collapse in={open7}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.emprendimiento.t49")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>             
                            </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}



export default EmprendimientoTitulacion;