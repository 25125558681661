import React from "react";
import { useTranslation } from "react-i18next";
import imagen1 from "./penalolen-05-850x600-c-default.jpg";
import imagen2 from "./vina_del_mar-e1602078115600.jpg";
import { Link } from "react-router-dom";

const Calendario = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className=" container">
      <h1 className="text-center m">{t("ambos.calendario.t1")}</h1>
      <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
      <div className="row mt-5 ">
        <div className="col-2">
          
        </div>
        <div className="col-3 ">
          <div className="card text-center w-100 m-0">
            <Link to="https://mailing20s.s3.amazonaws.com/templtates/1688ea1e-11cb-48e9-aed5-51d6e87dca94.html" className="link-offset-2 link-underline link-underline-opacity-0">
              <div>
            
                <img src={imagen1} class="card-img-top img-fluid object-fit-cover" alt="..." style={{ width: "280px", height: "200px" }}>
                </img>
                <div class="card-body  ">
                  <h5 class="card-title text-dark ">{t("ambos.calendario.t3")}</h5>

                </div>
              </div>
            </Link>
            {/* Contenido de la segunda columna */}
          </div>
        </div>
        <div className="col-2">

        </div>
        <div className="col-3 ">
          <div className="card text-center w-100 m-0" >

            <Link to="https://mailing20s.s3.amazonaws.com/templtates/43fad17c-7f62-40de-b418-ce57983b30ec.html" className="link-offset-2 link-underline link-underline-opacity-0">
              <div>
              
                <img src={imagen2} class="card-img-top img-fluid object-fit-cover" alt="..." style={{ width: "280px", height: "200px" }}>
                </img>
                <div class="card-body">
                  <h5 class="card-title text-dark">{t("ambos.calendario.t4")}</h5>
                
                  
                </div>
              </div>
            </Link>
            {/* Contenido de la cuarta columna */}
          </div>
        </div>
        <div className="col-2">

        </div>
      </div>


    </div>
  );
};

export default Calendario;
