import React from "react";
import { NavDropdown} from "react-bootstrap";//importacion biblioteca react-bootstrap
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


function AñosPr({año}){
    const [t, i18n]= useTranslation("global");
    const date = new Date();
    const añoActual = date.getFullYear();

    const mallasPr = {
        PrMalla2016: "Malla2023Pr",
        PrMalla2017: "Malla2022Pr",
        PrMalla2018: "Malla2021Pr",
        PrMalla2019: "Malla2020Pr",
        PrMalla2020: "Malla2019Pr",
        PrMalla2021: "Malla2018Pr",
        PrMalla2022: "Malla2017Pr",
        PrMalla2023: "Malla2016Pr"
    }
    

    return(
        <div className="d-flex justify-content-center align-items-center">
        <NavDropdown
              className="fw-bolder"
              id="nav-dropdown-3 "
              title={`${t("mallas.t9")} `+ año }
              menuVariant="light"
              style={{ marginTop: '20px', marginBottom: '20px'}}>
                {Object.keys(mallasPr).map((nombreMalla, año_malla) =>(
                    <NavLink to={`/${mallasPr[nombreMalla]}`} style={{ textDecoration: 'none' }}>
                        <NavDropdown.Item href={`#action/${año_malla}`}>{añoActual-año_malla-1}</NavDropdown.Item>
                    </NavLink>
                    ))}
        </NavDropdown>
    </div>
        
    );

}
export default AñosPr;