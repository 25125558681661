//componente normativa
import React from "react";

import slider from "../../resources/slider.jpeg"

import { useTranslation } from "react-i18next";

const Normativa = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>
    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="card ms-4 me-4 border-0">
            <div className="row g-0 ">
                <div className="col-md-6">
                    <img src={slider} className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-5">
                    <div className="card-body position-relative">
                        {/*}Textos{*/}
                        <h1 className="card-title text-center fs-2">{t("ambos.calendario.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <p className="card-text text-start fs-6">{t("ambos.normativa.t2")}</p>
                                {/*}Hipervinculos{*/}
                                <div className="p-2"/>
                                <a className="btn text-light bg-uai p-2" href="https://alumnosfic.uai.cl/wp-content/uploads/2021/05/Aprueba-Código-de-Honor-2021.pdf">{t("ambos.normativa.t3")}</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=sLMS5gMN94c">{t("ambos.normativa.t4")}</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=uJ8dGKy9340">{t("ambos.normativa.t5")}</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=BiCFQjp0Bx4">{t("ambos.normativa.t6")}</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
   {/*}Renderizado desde pantallas lg{*/}
   <div className="d-xl-none">
        <div className="card w-100 border-0">
            <div className="row g-0 ">
                <div className="col-md-6">
                    <img src="https://ingenieria.uai.cl/assets/uploads/2019/08/slider-1-1.jpg" className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-5">
                    <div className="card-body position-relative">
                        {/*}Textos{*/}
                        <h1 className="card-title text-center fs-2">{t("ambos.normativa.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <p className="card-text text-start fs-6">{t("ambos.normativa.t2")}</p>
                                {/*}Hipervinculos{*/}
                                <div className="p-2"/>
                                <a className="btn text-light bg-uai p-2" href="https://alumnosfic.uai.cl/wp-content/uploads/2021/05/Aprueba-Código-de-Honor-2021.pdf">{t("ambos.normativa.t3")}</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=sLMS5gMN94c">{t("ambos.normativa.t4")}</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=uJ8dGKy9340">{t("ambos.normativa.t5")}</a>
                                <div className="p-1"/>
                                <a className="btn text-light bg-uai" href="https://www.youtube.com/watch?v=BiCFQjp0Bx4">{t("ambos.normativa.t6")}</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
    </>
    )
}



export default Normativa;