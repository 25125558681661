import React from "react";
import portada from "../../resources/portada.jpg"
import descarga from "../../resources/descarga.jpg"

import { useTranslation } from "react-i18next";

const CharlasPregrado = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>

    <div className="d-none d-xl-block">
        <div className="">        
                <div className="card position-relative ms-4 me-4">
                    <img src={portada}className="card-img"/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("pregrado.charlas.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
                </div>
        </div>

        <div className="p-3"/>
        <div className="col-md-6 offset-md-3">
            <p className="text-start fs-6">{t("pregrado.charlas.t2")}</p>
            <div className="p-2"/>
        </div>
        <div className="bg-grey">
            <div className="col-md-8 offset-md-2">
            <div className="p-4"/>
                <div className="position-relative">     
                    <h1 className="text-center fs-3">{t("pregrado.charlas.t3")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <div class="ratio ratio-16x9 shadow-lg">
                        <iframe src="https://www.youtube.com/embed/45oRHnG5Jas?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="p-3"/>
                <div className="position-relative">     
                    <h1 className="text-center fs-3">{t("pregrado.charlas.t4")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <div class="ratio ratio-16x9 shadow-lg">
                        <iframe src="https://www.youtube.com/embed/2B5Hji2E224?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="p-3"/>
                <div className="position-relative">     
                    <h1 className="text-center fs-3">{t("pregrado.charlas.t5")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <div class="ratio ratio-16x9 shadow-lg">
                        <iframe src="https://www.youtube.com/embed/dBzFFVjbpaM?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div className="p-4"/>
        </div>
        <div>
            <div className="col-md-8 offset-md-2">
                <div className="p-2"/>
                <div className="position-relative">
                    <h1 className="text-center">{t("pregrado.charlas.t6")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t7")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <div class="ratio ratio-16x9 shadow-lg">
                        <iframe src="https://www.youtube.com/embed/IODUGEsSODE" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="p-3"/>
                <div className="position-relative">     
                    <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t8")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <div class="ratio ratio-16x9 shadow-lg">
                        <iframe src="https://www.youtube.com/embed/-2lDhz7hlRg" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="p-3"/>
                <div className="position-relative">     
                    <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t9")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <div class="ratio ratio-16x9 shadow-lg">
                        <iframe src="https://www.youtube.com/embed/MqTV0u66wO0" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="p-3"/>
                <div className="position-relative">     
                    <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t10")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-4"/>
                    <div class="ratio ratio-16x9 shadow-lg">
                        <iframe src="https://www.youtube.com/embed/ddnmJ2ffeQ4" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div className="p-4"/>
        <div className="bg-grey">
        <div className="p-3"/>
            <div className="card ms-4 me-4 bg-grey border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={descarga} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("pregrado.charlas.t11")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            
                                    {/*}Hipervinculos{*/}
                                    <div className="p-2"/>
                                    <a className="btn text-light bg-uai p-2" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Especialidades-y-Doble-Titulación.pdf">{t("pregrado.charlas.t12")}</a>
                                    <div className="p-1"/>
                                    <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Investigación-en-Pregrado.pdf">{t("pregrado.charlas.t13")}</a>
                                    <div className="p-1"/>
                                    <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Oportunidades-Internacionales.pdf">{t("pregrado.charlas.t14")}</a>
                                    <div className="p-1"/>
                                    <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Programa-de-Mentor%C3%ADa-Mujeres-en-Ingenier%C3%ADa-FIC.pdf">{t("pregrado.charlas.t15")}</a>
                        </div>

                    </div>
                </div>
                <div className="p-4"/>
            </div>
        </div>
    </div>
    
    <div className="d-xl-none">
        <div className="d-none d-md-block">
            <div className="">        
                        <div className="card position-relative">
                            <img src={portada}className="card-img"/>
                            <div className="card-img-overlay position-absolute custom-co-image">
                                <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("pregrado.charlas.t1")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                            </div>
                        </div>
                </div>

                <div className="p-3"/>
                <div className="col-md-6 offset-md-3">
                    <p className="text-start fs-6">{t("pregrado.charlas.t2")}</p>
                    <div className="p-2"/>
                </div>
                <div className="bg-grey">
                    <div className="col-md-8 offset-md-2">
                    <div className="p-4"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3">{t("pregrado.charlas.t3")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/45oRHnG5Jas?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3">{t("pregrado.charlas.t4")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/2B5Hji2E224?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3">{t("pregrado.charlas.t5")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/dBzFFVjbpaM?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="p-4"/>
                </div>
                <div>
                    <div className="col-md-8 offset-md-2">
                        <div className="p-2"/>
                        <div className="position-relative">
                            <h1 className="text-center">{t("pregrado.charlas.t6")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-4"/>
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t7")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/IODUGEsSODE" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t8")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/-2lDhz7hlRg" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t9")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/MqTV0u66wO0" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t10")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/ddnmJ2ffeQ4" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4"/>
                <div className="bg-grey">
                <div className="p-3"/>
                    <div className="card bg-grey border-0">
                        <div className="row g-0 ">
                            <div className="col-md-6">
                                <img src={descarga} className="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div className="col-md-5">
                                <div className="card-body position-relative">
                                    {/*}Textos{*/}
                                    <h1 className="card-title text-center">{t("pregrado.charlas.t11")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                    
                                            {/*}Hipervinculos{*/}
                                            <div className="p-2"/>
                                            <a className="btn text-light bg-uai p-2" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Especialidades-y-Doble-Titulación.pdf">{t("pregrado.charlas.t12")}</a>
                                            <div className="p-1"/>
                                            <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Investigación-en-Pregrado.pdf">{t("pregrado.charlas.t13")}</a>
                                            <div className="p-1"/>
                                            <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Oportunidades-Internacionales.pdf">{t("pregrado.charlas.t14")}</a>
                                            <div className="p-1"/>
                                            <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Programa-de-Mentor%C3%ADa-Mujeres-en-Ingenier%C3%ADa-FIC.pdf">{t("pregrado.charlas.t15")}</a>
                                    </div>

                                </div>
                            </div>
                            <div className="p-4"/>
                        </div>
                    </div>
            </div>
            <div className="d-lg-none">
            <div className="card w-100 border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={portada} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("pregrado.charlas.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <div className="p-3"/>
                <div className="col-md-6 offset-md-3">
                    <p className="text-start fs-6">{t("pregrado.charlas.t2")}</p>
                    <div className="p-2"/>
                </div>
                <div className="bg-grey">
                    <div className="col-md-8 offset-md-2">
                    <div className="p-4"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3">{t("pregrado.charlas.t3")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/45oRHnG5Jas?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3">{t("pregrado.charlas.t4")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/2B5Hji2E224?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3">{t("pregrado.charlas.t5")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/dBzFFVjbpaM?list=PLLiNBY434b1tVcZJ2WTXjvFEOxzx6Km1Y" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="p-4"/>
                </div>
                <div>
                    <div className="col-md-8 offset-md-2">
                        <div className="p-2"/>
                        <div className="position-relative">
                            <h1 className="text-center">{t("pregrado.charlas.t6")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-4"/>
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t7")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/IODUGEsSODE" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t8")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/-2lDhz7hlRg" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t9")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/MqTV0u66wO0" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="p-3"/>
                        <div className="position-relative">     
                            <h1 className="text-center fs-3 color-uai">{t("pregrado.charlas.t10")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-2"/>
                            <div class="ratio ratio-16x9 shadow-lg">
                                <iframe src="https://www.youtube.com/embed/ddnmJ2ffeQ4" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4"/>
                <div className="bg-grey">
                <div className="p-3"/>
                    <div className="card bg-grey border-0">
                        <div className="row g-0 ">
                            <div className="col-md-6">
                                <img src={descarga} className="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div className="col-md-5">
                                <div className="card-body position-relative">
                                    {/*}Textos{*/}
                                    <h1 className="card-title text-center">{t("pregrado.charlas.t11")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                    
                                            {/*}Hipervinculos{*/}
                                            <div className="p-2"/>
                                            <a className="btn text-light bg-uai p-2" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Especialidades-y-Doble-Titulación.pdf">{t("pregrado.charlas.t12")}</a>
                                            <div className="p-1"/>
                                            <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Investigación-en-Pregrado.pdf">{t("pregrado.charlas.t13")}</a>
                                            <div className="p-1"/>
                                            <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Oportunidades-Internacionales.pdf">{t("pregrado.charlas.t14")}</a>
                                            <div className="p-1"/>
                                            <a className="btn text-light bg-uai" href="https://alumnosfic.uai.cl/wp-content/uploads/2020/07/Programa-de-Mentor%C3%ADa-Mujeres-en-Ingenier%C3%ADa-FIC.pdf">{t("pregrado.charlas.t15")}</a>
                                    </div>

                                </div>
                            </div>
                            <div className="p-4"/>
                        </div>
                    </div>
                    </div>
                        </div>

                    </div>
                </div>
            </div>
    </div>

    </>
    )
}



export default CharlasPregrado;