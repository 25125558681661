import React from "react";

//Imagenes
import uai1 from "../../resources/uai1.jpg"
import uai1_1 from "../../resources/uai1_1.jpeg"



import { useTranslation } from "react-i18next";

const ReglamentoPregrado = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>
    {/*}Renderizado hasta pantallas lg{*/}
    <div className="d-none d-md-block">
        <div className="card ms-4 me-4 border-0">
            <div className="row g-0 ">
                <div className="col-md-6">
                    <img src={uai1} className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-5">
                    <div className="card-body position-relative">
                        {/*}Textos{*/}
                        <h1 className="card-title text-center fs-2">{t("pregrado.reglamento.t1")}</h1>
                        <div className="bg-uai position-absolute start-50 translate-middle ms-3 custom-line-l-size"/>
                        <div className="p-3"/>
                        <p className="card-text text-start fs-6">{t("pregrado.reglamento.t2")}</p>
                        <ul>
                            <li className="card-text text-start">{t("pregrado.reglamento.t3")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t4")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t5")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t6")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t7")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t8")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t9")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t10")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t11")}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="p-3"/>
            <div className="position-relative">
            <h1 className="card-title text-center fs-2">{t("pregrado.reglamento.t12")}</h1>
            <div className="bg-uai position-absolute start-50 translate-middle custom-line-l-size"/>
            <div className="p-3"/>
            {/*}Renderizado hasta pantallas xl{*/}
            <div className="d-none d-xl-block"> 
                <div className="p-4 position-relative">
                            <div className="row position-absolute start-50 translate-middle ms-1">
                                {/*}Hipervinculos{*/}
                                <div className="d-flex">
                                    <div className="p-1"/>
                                    <a className="btn text-light bg-uai" href="https://alumno.uai.cl/assets/uploads/2022/01/reglamento_2022.pdf">2022</a>
                                    <div className="p-2"/>
                                    <div className="p-2"/>
                                    <a className="btn text-light bg-uai" href="https://alumno.uai.cl/assets/uploads/sites/2/2021/03/reglamento_2021.pdf">2021</a>
                                    <div className="p-2"/>
                                </div>
                            </div>
                        </div>
                        <div className="p-2"/>
                        
                        <div className="p-3"/>
                        
                        <div className="p-3"/>
                        
                </div>
            </div>
            {/*}Renderizado desde pantallas lg{*/}
            <div className="d-xl-none">
                        <div className="position-relative">
                            {/*}Hipervinculos{*/}
                            <div className=""/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="https://alumno.uai.cl/assets/uploads/2022/01/reglamento_2022.pdf">Ingreso 2022</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="https://alumno.uai.cl/assets/uploads/sites/2/2021/03/reglamento_2021.pdf"> Ingreso 2021</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2021/05/FOLLETO-REGLAMENTOS-2020.pdf"> Ingreso 2020</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2021/05/REGLAMENTOS-2019.pdf"> Ingreso 2019</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2018/08/reglamento_2018.pdf"> Ingreso 2018</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-2017.pdf"> Ingreso 2017</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamentos-Pregrado-2016.pdf"> Ingreso 2016</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2015.pdf"> Ingreso 2015</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2014.pdf"> Ingreso 2014</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2013.pdf"> Ingreso 2013</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2012.pdf"> Ingreso 2012</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2011.pdf"> Ingreso 2011</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2010.pdf"> Ingreso 2010</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2009.pdf"> Ingreso 2009</a>
                            <div className="p-4"/>
                            <a className="btn text-light bg-uai position-absolute start-50 translate-middle w-25" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2007-2008.pdf"> Ingreso 2007 - 2008</a>
                            <div className="p-4"/>




                        </div>
                    </div> 
        </div>
    
    </div>
    {/*}Renderizado desde pantallas md{*/}
    <div className="d-md-none">
    <div className="card shadow-lg w-100">
        <div className="row g-0 ">
            <div className="col-md-6">
                <img src={uai1_1} className="img-fluid rounded-start" alt="..."/>
            </div>
            <div className="col-md-5">
                <div className="card-body position-relative">
                    {/*}Textos{*/}
                    <h1 className="card-title text-center fs-2">{t("pregrado.reglamento.t1")}</h1>
                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    <div className="p-3"/>
                    <p className="card-text text-start fs-6">{t("pregrado.reglamento.t2")}</p>
                        <ul>
                            <li className="card-text text-start">{t("pregrado.reglamento.t3")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t4")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t5")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t6")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t7")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t8")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t9")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t10")}</li>
                            <li className="card-text text-start">{t("pregrado.reglamento.t11")}</li>
                        </ul>
                            <div className="p-2"/>
                            <a className="btn text-light bg-uai" href="https://alumno.uai.cl/assets/uploads/2022/01/reglamento_2022.pdf">Ingreso 2022</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="https://alumno.uai.cl/assets/uploads/sites/2/2021/03/reglamento_2021.pdf"> Ingreso 2021</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2021/05/FOLLETO-REGLAMENTOS-2020.pdf"> Ingreso 2020</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2021/05/REGLAMENTOS-2019.pdf"> Ingreso 2019</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2018/08/reglamento_2018.pdf"> Ingreso 2018</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-2017.pdf"> Ingreso 2017</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamentos-Pregrado-2016.pdf"> Ingreso 2016</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2015.pdf"> Ingreso 2015</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2014.pdf"> Ingreso 2014</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2013.pdf"> Ingreso 2013</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2012.pdf"> Ingreso 2012</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2011.pdf"> Ingreso 2011</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2010.pdf"> Ingreso 2010</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2009.pdf"> Ingreso 2009</a>
                            <div className="p-1"/>
                            <a className="btn text-light bg-uai" href="http://alumnosfic.uai.cl/wp-content/uploads/2016/03/Reglamento-Académico-Ingreso-2007-2008.pdf"> Ingreso 2007 - 2008</a>
                            <div className="p-1"/>
                </div>

            </div>
        </div>
    </div> 
    </div>

    

    </>
    )
}



export default ReglamentoPregrado;