//Componente Footer del sitio, encargado contener apartado redes sociales e informacion de la institucion
import React from "react";
import facebook_logo from "../../resources/facebook_logo.png";//importacion logo facebook
import twitter_logo from "../../resources/twitter_logo.png";//importacion logo twitter
import lin_logo from "../../resources/lin_logo.png";//importacion logo linkeind

import { useTranslation } from "react-i18next";
const Footer = () => {
    const [t, i18n]= useTranslation("global");
    return(
        <>
        <div className="Container">
            {/*}Footer para pantallas de tamaño hasta lg{*/}
            <div className="d-none d-lg-block">    
                <div className="position-relative">
                    <h1 className="fs-3 text-center mt-5">{t("footer.t1")}</h1>
                    <div className="custom-line-size bg-uai mb-4 mt-2 position-absolute start-50 top-100 translate-middle"/>
                </div>
                <div className="d-flex mt-5">
                    <div className="col-4 bg-facebook position-relative p-5">{/*}propiedades del apartado de facebook{*/}
                        <div>
                            <img src={facebook_logo} alt="" className="custom-size-logo ms-3 mt-2  position-absolute top-0 start-0 "></img>{/*}logo facebook{*/}
                        </div>
                        <div className="text-light fs-2 position-absolute top-50 start-50 translate-middle logo-top">{/*}propiedades para texto{*/}
                            {t("footer.t2")}
                        </div>
                        <div className="p-5"/>
                        <div className="position-absolute top-100 start-50 translate-middle ">{/*}propiedades boton{*/}
                            <a href="https://www.facebook.com/uai.ingenieria" className="btn btn-outline-light button-bottom">{t("footer.t3")}</a>
                        </div>
                        <div className="p-4"/>
                    </div>
                    <div className="col-4 bg-twitter position-relative p-5">{/*}propiedades del apartado de twitter{*/}
                        <div>
                            <img src={twitter_logo} alt="" className="custom-size-logo ms-3 mt-2  position-absolute top-0 start-0 "></img>{/*}logo twitter{*/}
                        </div>
                        <div className="text-light fs-2 position-absolute top-50 start-50 translate-middle logo-top">{/*}propiedades para texto{*/}
                            {t("footer.t4")}
                        </div>
                        <div className="p-5"/>
                        <div className="position-absolute top-100 start-50 translate-middle ">{/*}propiedades boton{*/}
                            <a href="https://twitter.com/ingenieriauai" className="btn btn-outline-light button-bottom">{t("footer.t5")}</a>
                        </div>
                        <div className="p-4"/>
                    </div>
                    <div className="col-4 bg-lin position-relative p-5">{/*}propiedades del apartado de linkeid{*/}
                        <div>
                            <img src={lin_logo} alt="" className="custom-size-logo ms-3 mt-2  position-absolute top-0 start-0 "></img>{/*}logo linkedin{*/}
                        </div>
                        <div className="text-light fs-2 position-absolute top-50 start-50 translate-middle logo-top">{/*}propiedades para texto{*/}
                            {t("footer.t6")}
                        </div>
                        <div className="p-5"/>
                        <div className="position-absolute top-100 start-50 translate-middle ">{/*}propiedades boton{*/}
                            <a href="https://www.linkedin.com/groups/7474484" className="btn btn-outline-light button-bottom">{t("footer.t7")}</a>
                        </div>
                        <div className="p-4"/>
                    </div>
                </div>
                    <div className="col-12 bg-dark p-3 position-relative">{/*}propiedades texto direcciones{*/}
                        <p className="text-light">{t("footer.t8")}</p>
                        <p className="text-light">{t("footer.t9")}</p>
                        <p className="text-light">{t("footer.t10")}</p>
                        <p className="text-light">{t("footer.t11")}</p>
                        <p className="text-light">{t("footer.t12")}</p>
                        <p className="text-light">{t("footer.t13")}</p>
                        <img src="https://www.uai.cl/assets/uploads/2022/03/acreditacion_2022_6_anos.png" className="position-absolute end-0 top-50 translate-middle img-botton" alt=""></img>{/*}imagen acreditacion de institucion{*/}
                    </div>

                
            </div>
            {/*}Footer para pantallas desde tamaño md hasta sm{*/}
            <div className="d-lg-none">
            <div className="position-relative">
                    <h1 className="fs-3 text-center mt-5">{t("footer.t1")}</h1>
                    <div className="custom-line-size bg-uai mb-4 mt-2 position-absolute start-50 top-100 translate-middle"/>
                </div>
                <div className="p-3"/>
                <div className="row">
                    <div className="col-4 position-relative bg-facebook p-5">{/*}propiedades del apartado de facebook{*/}
                        <img src={facebook_logo} alt="" className="custom-size-small-logo position-absolute top-50 start-50 translate-middle" href="https://www.facebook.com/uai.ingenieria"></img>{/*}logo facebook{*/}
                    </div>
                    <div className="col-4 position-relative bg-twitter p-5">{/*}propiedades del apartado de twitter{*/}
                        <img src={twitter_logo} alt="" className="custom-size-small-logo position-absolute top-50 start-50 translate-middle"></img>{/*}logo twitter{*/}
                    </div>
                    <div className="col-4 position-relative bg-lin p-5">{/*}propiedades del apartado de linkedin{*/}
                        <img src={lin_logo} alt="" className="custom-size-small-logo position-absolute top-50 start-50 translate-middle"></img>{/*}logo linkedin{*/}
                    </div>
                    
                    <div className="col-12 bg-dark position-relative">{/*}propiedades texto{*/}
                        <div>
                            <p className="text-light ms-3">{t("footer.t8")}<br/>{t("footer.t9")}<br/>{t("footer.t10")}<br/>{t("footer.t11")}<br/>{t("footer.t12")}<br/>{t("footer.t13")}</p>
                            <div className="p-4"/>
                        </div>
                    
                    <div>
                        <img src="https://www.uai.cl/assets/uploads/2022/03/acreditacion_2022_6_anos.png" className="position-absolute start-50 translate-middle" alt=""></img>{/*}imagen acreditacion de institucion{*/}
                    </div>
                    <div className="p-5"/>
                </div>
                </div>

            </div>
        </div>
    </>
    )


}
export default Footer